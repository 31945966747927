import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Badge, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCube, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Package, FileText } from 'react-feather';
import { connect } from 'react-redux';
import classnames from 'classnames';
import AllShipments from './Allshipment';
import PickupPending from './PickupPending';
import { useHistory } from 'react-router-dom';
import { nFormatter, errorHandling } from '../../utlis';
import { delivered_order_status, overall_pending_order_status, rto_order_status, cancel_order_status, baseString } from '../../config';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import axios from 'axios';

const UploadOrders = (props) => {
  let orderTab = (new URLSearchParams(window.location.search)).get("s");
  let searchKeyword = (new URLSearchParams(window.location.search)).get("search_keyword");
  let ShipmentStartDate = (new URLSearchParams(window.location.search)).get("start_date");
  let ShipmentEndDate = (new URLSearchParams(window.location.search)).get("end_date");
  let status = (new URLSearchParams(window.location.search)).get("status");
  let status_type = (new URLSearchParams(window.location.search)).get("status_type");
  let zoneId = (new URLSearchParams(window.location.search)).get("zoneid");
  let merchantId = (new URLSearchParams(window.location.search)).get("mid");
  let courierId = (new URLSearchParams(window.location.search)).get("cid");
  let courierIds = (new URLSearchParams(window.location.search)).get("cids");
  courierIds = checkUndeNullBlank(courierIds) ? courierIds.split(",") : [];
  let Shipmenttotal = (new URLSearchParams(window.location.search)).get("total");
  let [totalStatusCount, setTotalStatusCount] = useState();

  const history = useHistory();
  const [activeTab, setActiveTab] = useState('all');
  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
    history.push(`/shipments?s=${tab}`)
  }
  useEffect(() => {
    if (orderTab === null && searchKeyword === null && ShipmentStartDate === null && ShipmentEndDate === null && Shipmenttotal === null) {
      history.push(`/shipments?s=${activeTab}`)
    }
    if (orderTab === 'all') {
      setActiveTab('all')
    } else if (orderTab === 'label-manifest') {
      setActiveTab('label-manifest')
    } else if (searchKeyword !== null) {
      setActiveTab('all')
    } else {
      setActiveTab('all')
    }
    totalStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword, orderTab])


  let totalStatus = () => {
    let data = {};
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }

    axios({
      method: "post",
      url: `${process.env.REACT_APP_TOTAL_STATUS_SUMMARY}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data.error !== true) {
        setTotalStatusCount(res.data.data);
      }
    }).catch((err) => {
      errorHandling(err, "");
    });
  }

  let totalDelivered = 0;
  let totalPending = 0;
  let totalRTO = 0;
  let totalCancel = 0;

  checkData(totalStatusCount) && totalStatusCount.map((pTtem, index) => {
    overall_pending_order_status.forEach((cItem) => {
      if (pTtem.order_status_id === cItem) {
        totalPending += pTtem.total;
      }
    })
    delivered_order_status.forEach((cItem) => {
      if (pTtem.order_status_id === cItem) {
        totalDelivered += pTtem.total;
      }
    })
    rto_order_status.forEach((cItem) => {
      if (pTtem.order_status_id === cItem) {
        totalRTO += pTtem.total;
      }
    })
    cancel_order_status.forEach((cItem) => {
      if (pTtem.order_status_id === cItem) {
        totalCancel += pTtem.total;
      }
    })
    return true;
  })

  const CardData = [
    { icon: faArrowUp, text: "Total Delivered", value: totalDelivered, color: "success" },
    { icon: faCube, text: "Total Pending", value: totalPending, color: "warning" },
    { icon: faArrowDown, text: "Total RTO", value: totalRTO, color: "primary" },
    { icon: faTimes, text: "Total Cancel", value: totalCancel, color: "danger" },
  ];

  return (
    <div className="app-content content overflow-hidden">
      <div className="p-0 my-3 shipmentCon content-wrapper border-0">
        <Row className="m-0">
          <Col sm={12} xl={6} className="p-0">
            <div className="">
              <Nav tabs className="text-center flex-nowrap">
                <NavItem className="d-flex">
                  <NavLink className={classnames({ active: activeTab === 'all' })} onClick={() => { toggleTab('all'); }}>
                    <div className="d-flex flex-wrap justify-content-center align-item-center font-weight-normal">
                      <span className="mr-2"><Package /></span>
                      <span className="text-nowrap">All Shipments</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem className="d-flex">
                  <NavLink className={classnames({ active: activeTab === 'label-manifest' })} onClick={() => { toggleTab('label-manifest'); }}>
                    <div className="d-flex flex-wrap justify-content-center align-item-center font-weight-normal">
                      <span className="mr-2"><FileText /></span>
                      <span>Download Address Labels/Manifests</span>
                    </div>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
          {props.user[0].user_type === "Merchant" &&
            <Col sm={12} xl={6} className="p-0">
              <div className="some-shipment-detail bg-white border-bottom">
                <Row className="card-shipment-list justify-content-center m-0">
                  {CardData.map((cardData, index) =>
                    <Col sm={6} md={3} lg={2} xl={3} className="border-right">
                      <div className="media p-1" id={`tooltips${index}`}>
                        <div className='media-aside mr-2 align-self-start'>
                          <div className="card-icon"><Badge color={cardData.color} pill className="p-0 d-flex justify-content-center align-items-center mx-auto"><FontAwesomeIcon icon={cardData.icon} /></Badge></div>
                        </div>
                        <div className='media-body my-auto position-relative'>
                          <h4 className="card-val">
                            {nFormatter(cardData.value, 1)}
                          </h4>
                          <p className="text-muted text-nowrap font-weight-500 title-card">{cardData.text}</p>
                        </div>
                      </div>
                      <UncontrolledTooltip placement="right" target={`tooltips${index}`}>
                        {cardData.value}
                      </UncontrolledTooltip>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>}
        </Row>
        <TabContent activeTab={activeTab} className="pb-0">
          <TabPane tabId="all">
            {activeTab === "all" &&
              <AllShipments user={props.user} oneCalendar={props.oneCalendar} searchKeyword={searchKeyword} ShipmentStartDate={ShipmentStartDate} ShipmentEndDate={ShipmentEndDate} Shipmenttotal={Shipmenttotal} status={status} status_type={status_type} zoneId={zoneId} merchantId={merchantId} courierId={courierId} courierIds={courierIds} downloadNotification={props.downloadNotification} />
            }
          </TabPane>
          <TabPane tabId="label-manifest">
            {activeTab === "label-manifest" &&
              <PickupPending user={props.user} oneCalendar={props.oneCalendar} downloadNotification={props.downloadNotification} />
            }
          </TabPane>
        </TabContent>
      </div>

    </div>
  );
}
function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(UploadOrders);
