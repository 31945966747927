import React from 'react';
import Cards from './Cards';
import OrderSummary from './OrderSummary';
import RemittanceOverview from './RemittanceOverview';
import PerformanceOverview from './PerformanceOverview';
import {Row, Col} from 'reactstrap';
import ShipmentData from './ShipmentData';
import CourierOverview from './CourierOverview';
import ShipmentStatus from './ShipmentStatus';
// import CodPrepaid from './CodPrepaid';
import FreightOverview from './FreightOverview';
import CityState from './CityState';
import StateMap from './StateMap';

const Overview = (props) => {

    return (
        <div className="overview">
            <div className="mb-4"><Cards user={props.user} /></div>
            <div className="mb-4"><OrderSummary user={props.user} oneCalendar={props.oneCalendar} /></div>
            <div className="mb-4 d-none"><ShipmentData user={props.user} /></div>
            <Row>
                <Col md={12} lg={6} className="mb-4 d-lg-flex"><FreightOverview user={props.user} /></Col>
                <Col md={12} lg={6} className="mb-4 d-lg-flex"><RemittanceOverview user={props.user} /></Col>
            </Row>
            <div className="mb-4"><CourierOverview user={props.user} oneCalendar={props.oneCalendar} /></div>
            <div className="mb-4"><PerformanceOverview user={props.user} oneCalendar={props.oneCalendar} /></div>
            <Row>
                <Col md={12} lg={6} className="mb-4"><ShipmentStatus user={props.user} oneCalendar={props.oneCalendar} /></Col>
                <Col md={12} lg={6} className="mb-4 d-flex"><CityState user={props.user} /></Col>
            </Row>
            <div className="mb-4 d-none"><StateMap user={props.user} oneCalendar={props.oneCalendar} /></div>
        </div>
    );
}

export default Overview;