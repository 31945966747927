import React from 'react';
import {Modal, ModalHeader, ModalBody, Form, Row, Col, FormGroup, Label, CardSubtitle } from 'reactstrap';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ViewEmailTemplate = (props) => {
  

  return (
          <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg" scrollable={true}>
            <ModalHeader toggle={props.toggle}>View Email Content</ModalHeader>
            <ModalBody>
              <Form>
                <Row className="mx-0 justify-content-centent">
                  <Col sm={12}>
                      <FormGroup>
                        <Row>
                          <Col lg={2}>
                            <CardSubtitle tag="h6" className="mb-2 mt-0">Title</CardSubtitle>
                          </Col>
                          <Col lg={10}>
                            <Label>Remittance Initiated</Label>
                          </Col>
                        </Row>
                      </FormGroup>
                  </Col>
                  <Col sm={12}>
                      <FormGroup>
                      <Row>
                          <Col lg={2}>
                          <CardSubtitle tag="h6" className="mb-2 mt-0">Handler</CardSubtitle>
                          </Col>
                          <Col lg={10}>
                          <Label>remittance_initiated</Label>
                          </Col>
                        </Row>
                      </FormGroup>
                  </Col>
                  <Col sm={12}>
                      <FormGroup>
                      <Row>
                          <Col lg={2}>
                          <CardSubtitle tag="h6" className="mb-2 mt-0">Subject</CardSubtitle>
                          </Col>
                          <Col lg={10}>
                          <Label>	Remittance Initiated</Label>
                          </Col>
                        </Row>
                      </FormGroup>
                  </Col>
                  <Col sm={12}>
                      <FormGroup>
                      <Row>
                          <Col lg={2}>
                          <CardSubtitle tag="h6" className="mb-2 mt-0">Email Body</CardSubtitle>
                          </Col>
                          <Col lg={10}>
                          <div className="border p-2">	Remittance Initiated <br/> hh  shsugs hs<br/> gsgshj</div>
                          </Col>
                        </Row>
                      </FormGroup>
                  </Col>
                  <Col sm={12}>
                      <FormGroup>
                      <Row>
                          <Col lg={2}>
                          <CardSubtitle tag="h6" className="mb-2 mt-0">Status</CardSubtitle>
                          </Col>
                          <Col lg={10}>
                          <Label>1</Label>
                          </Col>
                        </Row>
                      </FormGroup>
                  </Col>
                  <Col sm={12}>
                      <FormGroup>
                      <Row>
                          <Col lg={2}>
                          <CardSubtitle tag="h6" className="mb-2 mt-0">Created</CardSubtitle>
                          </Col>
                          <Col lg={10}>
                          <Label>12/16/20, 12:00 AM</Label>
                          </Col>
                        </Row>
                      </FormGroup>
                  </Col>
                  <Col sm={12}>
                      <FormGroup>
                      <Row>
                          <Col lg={2}>
                          <CardSubtitle tag="h6" className="mb-2 mt-0">Updated</CardSubtitle>
                          </Col>
                          <Col lg={10}>
                          <Label>12/16/20, 12:00 AM</Label>
                          </Col>
                        </Row>
                      </FormGroup>
                  </Col>
                </Row>
              </Form>  
            </ModalBody>
        </Modal>
  );
}

export default ViewEmailTemplate;