import React, { useEffect, useState } from 'react';
import { Row, Col, Label, Form, FormGroup, Button, CardTitle } from 'reactstrap';
import Swal from 'sweetalert2'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StateList } from '../../manageMasters/StateList';
import { checkData } from '../../../ObjectExist';
import { errorHandling } from '../../../utlis';
import { baseString } from '../../../config';

const BillingDetail = (props) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const { register: addMerchant_address, handleSubmit: merchantAddressSubmit, formState: { errors: errorMerchantAdrs }, reset: merchantAdrsReset } = useForm();
  const viewBillingDetails = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_ADDRESSADD}/view`,
      data: { "merchant_id": props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '' },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        let myResponse = response.data[0];
        if (checkData(myResponse)) {
          setFormDisabled(true);
        }
        merchantAdrsReset(myResponse);
        props.setBillingDetails(myResponse);
      })
      .catch(function (err) {
        errorHandling(err, "")
      });
  }
  useEffect(() => {
    viewBillingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantAdrsReset])
  const onAddMerchantAddress = (data) => {
    props.setLoader(true);
    let formData = new FormData();
    formData.append("merchant_id", props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '');
    formData.append("address", data.address);
    formData.append("pincode", data.pincode);
    formData.append("city", data.city);
    formData.append("state_id", data.state_id);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_ADDRESSADD}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      props.setLoader(false);
      if (res.data.error === false) {
        viewBillingDetails();
        Swal.fire({
          title: 'Success',
          text: res.data.message,
          icon: 'success',
        })
      }
    })
      .catch((err) => {
        props.setLoader(false);
        errorHandling(err, "")
      });
  };

  return (
    <Form onSubmit={merchantAddressSubmit(onAddMerchantAddress)}>
      <div className="sub-page-header">
        <CardTitle tag="h4" className="sub-page-title">Billing Detail</CardTitle>
      </div>
      <Row>
        <Col sm={6}>
          <FormGroup>
            <Label for="bill_adrs">Billing Address</Label>
            <input disabled={formDisabled} {...addMerchant_address("address", { required: 'Please Enter Address' })} className="form-control-sm form-control" placeholder="Address" id="address" />
            {errorMerchantAdrs.address && <span className="text-danger d-block error">{errorMerchantAdrs.address.message}</span>}
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label for="city">City</Label>
            <input disabled={formDisabled} {...addMerchant_address("city", {
              required: 'Please Enter City',
              pattern: { value: /^[a-z ,.'-]+$/i, message: 'Please Enter Valid City' }
            })} className="form-control-sm form-control" placeholder="City" id="city" />
            {errorMerchantAdrs.city && <span className="text-danger d-block error">{errorMerchantAdrs.city.message}</span>}
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label for="state">Select State</Label>
            <div className="position-relative">
              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
              <select disabled={formDisabled} {...addMerchant_address("state_id", { required: 'Please Select State' })} className="form-control-sm form-control" id="state">
                <option value="">Select State</option>
                {StateList.map((item, index) => (
                  <option value={item.state_id}>{item.state_name}</option>
                ))
                }
              </select>
              {errorMerchantAdrs.state_id && <span className="text-danger d-block error">{errorMerchantAdrs.state_id.message}</span>}
            </div>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label for="pincode">Pincode</Label>
            <input disabled={formDisabled} {...addMerchant_address("pincode", {
              required: 'Please Enter Pincode',
              minLength: { value: 6, message: "at least 6 digits" },
              maxLength: { value: 6, message: "maximum 6 digits" },
              pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Pincode' }
            })} className="form-control-sm form-control" placeholder="Pincode" id="pincode" />
            {errorMerchantAdrs.pincode && <span className="text-danger d-block error">{errorMerchantAdrs.pincode.message}</span>}
          </FormGroup>
        </Col>
        {!formDisabled &&
          <Col sm={12} className="mt-2 text-right">
            <Button className="btn-sm ctm-btn" color="primary"> Save changes</Button>
          </Col>
        }
      </Row>
    </Form>
  );
}

export default BillingDetail;
