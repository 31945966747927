import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, Form, FormGroup, Button, Label, CardHeader } from 'reactstrap';
import ShipmentsSummary from './ShipmentsSummary';
import { useForm } from 'react-hook-form';

const AllCourier = (props) => {
    const [courierModal, setCourierModal] = useState(false);
    const [courierName, setCourierName] = useState('');

    const toggleOverview = (name) => {
        setCourierModal(!courierModal);
        setCourierName(name)
    }
    const toggleOverviewClose = () => {
        setCourierModal(false);
        setCourierName('')
    }
    const { register: courieradd, handleSubmit: addCourier, formState: { errors }, reset: resetForm } = useForm();
    const onAddCourier = (data) => {
        console.log('data', data);
    }
    return (
        <div className="Couriers">
            <Modal isOpen={courierModal} toggle={toggleOverviewClose} scrollable={true} >
                <ModalHeader toggle={toggleOverviewClose}>Add a New Courier</ModalHeader>
                <ModalBody>
                    <Form onSubmit={addCourier(onAddCourier)}>
                        <Row className="m-0 pb-1">
                            <Col sm={12} className="m-auto pt-1 p-0">
                                <FormGroup>
                                    <Label>DISPLAY NAME:</Label>
                                    <input type="text" {...courieradd("display_name", { required: 'Please Enter Display Name' })} className="form-control-sm form-control" placeholder='Enter Display Name' />
                                    {errors.display_name && <span className="text-danger d-block error">{errors.display_name.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={12} className="text-right">
                                <Button className="btn ctm-btn btn-sm" color="primary">Save</Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
            <Card className="my-3 channelOrder shadow content-wrapper border-0">
                <CardHeader>
                    <CardTitle className="page-title">All Couriers</CardTitle>
                </CardHeader>
                <Row className="m-0">
                    <Col md={2} className="my-3">
                        <Card className="border-0 shadow cursor-pointer h-100" onClick={() => toggleOverview('fedex')}>
                            <CardBody>
                                <div className="bg-rgba-white text-center pb-1 ml-0">
                                    <img className="img-fluid" src="./couriers/fedex.png" alt="Fedex" width="75" />
                                </div>
                                <CardTitle tag="h6" className="text-center border-top pt-1">Fedex</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={2} className="my-3">
                        <Card className="border-0 shadow cursor-pointer h-100" onClick={() => toggleOverview('bluedart')}>
                            <CardBody>
                                <div className="bg-rgba-white text-center pb-1 ml-0">
                                    <img className="img-fluid" src="./couriers/bluedart.png" alt="bluedart" width="75" />
                                </div>
                                <CardTitle tag="h6" className="text-center border-top pt-1">BlueDart</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={2} className="my-3">
                        <Card className="border-0 shadow cursor-pointer h-100" onClick={() => toggleOverview('dtdc')}>
                            <CardBody>
                                <div className="bg-rgba-white text-center pb-1 ml-0">
                                    <img className="img-fluid" src="./couriers/dtdc.png" alt="dtdc" width="75" />
                                </div>
                                <CardTitle tag="h6" className="text-center border-top pt-1">DTDC</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={2} className="my-3">
                        <Card className="border-0 shadow cursor-pointer h-100" onClick={() => toggleOverview('xpressbees')}>
                            <CardBody>
                                <div className="bg-rgba-white text-center pb-1 ml-0">
                                    <img className="img-fluid" src="./couriers/expressbees.png" alt="xpressbees" width="75" />
                                </div>
                                <CardTitle tag="h6" className="text-center border-top pt-1">XpressBees</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={2} className="my-3">
                        <Card className="border-0 shadow cursor-pointer h-100" onClick={() => toggleOverview('delhiveryExpress')}>
                            <CardBody>
                                <div className="bg-rgba-white text-center pb-1 ml-0">
                                    <img className="img-fluid" src="./couriers/delhiveryExpress.png" alt="delhiveryExpress" width="75" />
                                </div>
                                <CardTitle tag="h6" className="text-center border-top pt-1">DELHIVERY EXPRESS</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={2} className="my-3">
                        <Card className="border-0 shadow cursor-pointer h-100" onClick={() => toggleOverview('delhiveryStandard')}>
                            <CardBody>
                                <div className="bg-rgba-white text-center pb-1 ml-0">
                                    <img className="img-fluid" src="./couriers/delhiveryExpress.png" alt="delhiveryExpress" width="75" />
                                </div>
                                <CardTitle tag="h6" className="text-center border-top pt-1">DELHIVERY STANDARD</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={2} className="my-3">
                        <Card className="border-0 shadow cursor-pointer h-100" onClick={() => toggleOverview('ecomExpress')}>
                            <CardBody>
                                <div className="bg-rgba-white text-center pb-1 ml-0">
                                    <img className="img-fluid" src="./couriers/ecomExpress.png" alt="ecomExpress" width="75" />
                                </div>
                                <CardTitle tag="h6" className="text-center border-top pt-1">ECOM EXPRESS</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={2} className="my-3">
                        <Card className="border-0 shadow cursor-pointer h-100" onClick={() => toggleOverview('ecomExpressRos')}>
                            <CardBody>
                                <div className="bg-rgba-white text-center pb-1 ml-0">
                                    <img className="img-fluid" src="./couriers/ecomExpressRos.png" alt="ecomExpressRos" width="75" />
                                </div>
                                <CardTitle tag="h6" className="text-center border-top pt-1">ECOM EXPRESS ROS</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={2} className="my-3">
                        <Card className="border-0 shadow cursor-pointer h-100" onClick={() => toggleOverview('shadowFax')}>
                            <CardBody>
                                <div className="bg-rgba-white text-center pb-1 ml-0">
                                    <img className="img-fluid" src="./couriers/shadowFax.png" alt="shadowFax" width="75" />
                                </div>
                                <CardTitle tag="h6" className="text-center border-top pt-1">Shadowfax</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={2} className="my-3">
                        <Card className="border-0 shadow cursor-pointer h-100" onClick={() => toggleOverview('pickrr')}>
                            <CardBody>
                                <div className="bg-rgba-white text-center pb-1 ml-0">
                                    <img className="img-fluid" src="./couriers/pickrr.png" alt="pickrr" width="75" />
                                </div>
                                <CardTitle tag="h6" className="text-center border-top pt-1">Pickrr</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={2} className="my-3">
                        <Card className="border-0 shadow cursor-pointer h-100" onClick={() => toggleOverview('logistify')}>
                            <CardBody>
                                <div className="bg-rgba-white text-center pb-1 ml-0">
                                    <img className="img-fluid" src="./couriers/logistify.jpg" alt="logistify" width="75" />
                                </div>
                                <CardTitle tag="h6" className="text-center border-top pt-1">Logistify</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={2} className="my-3">
                        <Card className="border-0 shadow cursor-pointer h-100" onClick={() => toggleOverview('nimbus')}>
                            <CardBody>
                                <div className="bg-rgba-white text-center pb-1 ml-0">
                                    <img className="img-fluid" src="./couriers/nimbus.png" alt="nimbus" width="75" />
                                </div>
                                <CardTitle tag="h6" className="text-center border-top pt-1">Nimbus</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={2} className="my-3">
                        <Card className="border-0 shadow cursor-pointer h-100" onClick={() => toggleOverview('shiprocket')}>
                            <CardBody>
                                <div className="bg-rgba-white text-center pb-1 ml-0">
                                    <img className="img-fluid" src="./couriers/shiprocket.png" alt="shiprocket" width="75" />
                                </div>
                                <CardTitle tag="h6" className="text-center border-top pt-1">Shiprocket</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={2} className="my-3">
                        <Card className="border-0 shadow cursor-pointer h-100" onClick={() => toggleOverview('shyplite')}>
                            <CardBody>
                                <div className="bg-rgba-white text-center pb-1 ml-0">
                                    <img className="img-fluid" src="./couriers/shyplite.png" alt="shyplite" width="75" />
                                </div>
                                <CardTitle tag="h6" className="text-center border-top pt-1">Shyplite</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Card>
        </div>
    );
}

export default AllCourier;