import React from 'react';
import { Form, FormGroup, CustomInput, Row, Col,Button, CardTitle} from 'reactstrap';

const Notification = (props) => {
    return (
        <Form>
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">Notification</CardTitle>
            </div>
            <Row>
                <Col sm={12}><h6 className="text-muted mb-3 font-weight-500">Switches</h6></Col>
                <Col sm={12}>
                    <FormGroup>
                        <CustomInput type="switch" id="exampleCustomSwitch" className="switch-md" name="customSwitch" label="Turn on this custom switch" />
                    </FormGroup>
                </Col>
                <Col sm={12}>
                    <FormGroup>
                        <CustomInput type="switch" id="exampleCustomSwitch2" className="switch-md" name="customSwitch" label="Or this one" />
                    </FormGroup>
                </Col>
                <Col sm={12}>
                    <FormGroup>
                        <CustomInput type="switch" id="exampleCustomSwitch3" className="switch-md" label="But not this disabled one" />
                    </FormGroup>
                </Col>
                <Col sm={12}><h6 className="text-muted my-3 font-weight-500">Switches</h6></Col>
                <Col sm={12}>
                    <FormGroup>
                        <CustomInput type="switch" id="exampleCustomSwitch4" className="switch-md" name="customSwitch" label="Turn on this custom switch" />
                    </FormGroup>
                </Col>
                <Col sm={12}>
                    <FormGroup>
                        <CustomInput type="switch" id="exampleCustomSwitch5" className="switch-md" name="customSwitch" label="Or this one" />
                    </FormGroup>
                </Col>
                <Col sm={12}>
                    <FormGroup>
                        <CustomInput type="switch" id="exampleCustomSwitch6" className="switch-md" label="But not this disabled one" />
                    </FormGroup>
                </Col>
                <Col sm={12} className="mt-2 text-right">                    
                    <Button className="ctm-btn btn-sm" color="primary"> Save changes</Button>
                </Col>   
            </Row>
        </Form>
    );
}

export default Notification;