import React, { useState, useEffect } from 'react';
import { Row, Form, CardTitle, Card, CardBody, Table, CardImg, UncontrolledCollapse, CustomInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Swal from 'sweetalert2'
import config, { baseString } from '../../../config';
import LoaderDefault from '../../LoaderDefault';
import NodataFound from '../../NodataFound';
import { errorHandling } from '../../../utlis';

const MyCouriers = (props) => {
  const [courierPricing, setCourierPricing] = useState([]);
  const [courierList, setCourierList] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [loader, setLoader] = useState(false);
  const getCourierStatus = (courier_id, e) => {
    setLoader(true)
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_COURIER_STATUS,
      data: { "merchant_id": props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '', "status_id": e.target.checked ? e.target.value : '0', "courier_id": courier_id },
      headers: { "token": window.btoa(baseString) }
    }).then(function (response) {
      if (response.data.error === false) {
        Swal.fire({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
        })
      }
      setLoader(false)
      couurierListing()
    }).catch(function (err) {
      setLoader(false)
      errorHandling(err, "");
    })

  };
  const couurierListing = () => {
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_COURIER_LIST,
      data: { "merchant_id": props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '' },
      headers: { "token": window.btoa(baseString) }
    }).then(function (response) {
      if (response.data.error === false) {
        setCourierList(response.data.data)
        courierPriceing();
      } else {
        Swal.fire({ title: 'Error', text: response.data.msg ? response.data.msg : 'Something went wrong', icon: 'error' });
      }
    }).catch(function (err) {
      errorHandling(err, "reload");
    })
  }
  const courierPriceing = () => {
    setLoadertable(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_COURIER_PRICING,
      data: { "merchant_id": props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '' },
      headers: { "token": window.btoa(baseString) }
    }).then(function (response) {
      if (response.data.fatal !== true) {
        setCourierPricing(response.data)
        setLoadertable(false);
      }
    }).catch(function (err) {
      setLoadertable(false);
      errorHandling(err, "");
    })
  }
  useEffect(() => {
    couurierListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Form>
      <div className="sub-page-header">
        <CardTitle tag="h4" className="sub-page-title">My Couriers</CardTitle>
      </div>
      <Row>
        {courierList.courierList !== null && courierList.courierList !== undefined && Object.entries(courierList.courierList).length > 0 ? courierList.courierList.map((item, index) => (
          <>
            <Card className="m-1 px-0" key={index}>
              <CardBody>
                <div className="d-flex flex-wrap flex-sm-nowrap justify-content-sm-between align-items-center mycourierlist">
                  <div id={`couriertoggler${item.courier_id}`} className="courierToggler mr-2" style={{ cursor: 'pointer' }}><FontAwesomeIcon icon={faChevronDown} /></div>
                  <div id={`couriertoggler${item.courier_id}`} className="courierId text-nowrap mx-2">Courier ID - <span className="font-weight-500">{item.courier_id}</span></div>
                  <div className="courierLogo mx-2" id={`couriertoggler${item.courier_id}`} style={{ cursor: 'pointer' }}> <CardImg top src={`${process.env.REACT_APP_BASE_URL + '/' + courierList.path + '/' + item.logo}`} alt="logo" style={{ maxWidth: '60px', minWidth: '60px' }} /></div>
                  <div className="courierName mx-2" id={`couriertoggler${item.courier_id}`} style={{ cursor: 'pointer' }}>{item.courierName}</div>
                  <div className="courierSwitch">
                    <CustomInput type="switch" id={`switchToggle${item.courier_id}`} onClick={(e) => getCourierStatus(item.courier_id, e)} value="1" className="switch-md" label={config.status[item.status]} defaultChecked={item.status === 1} />
                  </div>
                </div>
              </CardBody>
            </Card>
            <UncontrolledCollapse toggler={`couriertoggler${item.courier_id}`}>
              <CardBody className={`px-0 ${item.courier_id}`}>
                {courierPricing !== undefined && courierPricing !== null && Object.entries(courierPricing).length > 0 ?
                  <>
                    {/* <PricingTable movement_type_id={item.movement_type_id} pricingList={courierPricing[item.courier_id !== undefined && item.courier_id]} courierId={item.courier_id} merchantId={props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:''} priceApiUrl={process.env.REACT_APP_MERCHANT_PRICING_ADD} disabled={true} /> */}
                    <Table responsive className="text-center custom-table">
                      <thead>
                        <tr>
                          <th className="text-nowrap">Direction</th>
                          <th className="text-nowrap">Type</th>
                          <th className="text-nowrap">Weight Slab</th>
                          <th>Within <br /> City</th>
                          <th>Within <br /> State</th>
                          {/* <th>Within <br /> Zone</th> */}
                          <th className="text-nowrap">M to M</th>
                          <th>ROI</th>
                          <th className="text-nowrap">Ext.<br /> Location</th>
                          <th>Special<br />  Dest.</th>
                          <th>COD</th>
                          <th className="text-nowrap">COD %</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td rowSpan="2" className="align-middle font-weight-bold">Forward</td>
                          <td>Basic</td>
                          <td>{courierPricing[item.courier_id].frwd_b.weight_in_kgs}</td>
                          <td>{courierPricing[item.courier_id].frwd_b.within_city}</td>
                          <td>{courierPricing[item.courier_id].frwd_b.within_state}</td>
                          {/* <td>{courierPricing[item.courier_id].frwd_b.within_zone}</td> */}
                          <td>{courierPricing[item.courier_id].frwd_b.metro}</td>
                          <td>{courierPricing[item.courier_id].frwd_b.roi}</td>
                          <td>{courierPricing[item.courier_id].frwd_b.extended_location}</td>
                          <td>{courierPricing[item.courier_id].frwd_b.special_destination}</td>
                          <td>{courierPricing[item.courier_id].frwd_b.cod_charge}</td>
                          <td>{courierPricing[item.courier_id].frwd_b.cod_percent}</td>
                        </tr>
                        <tr>
                          <td>Additional</td>
                          <td>{courierPricing[item.courier_id].frwd_a.weight_in_kgs}</td>
                          <td>{courierPricing[item.courier_id].frwd_a.within_city}</td>
                          <td>{courierPricing[item.courier_id].frwd_a.within_state}</td>
                          {/* <td>{courierPricing[item.courier_id].frwd_a.within_zone}</td> */}
                          <td>{courierPricing[item.courier_id].frwd_a.metro}</td>
                          <td>{courierPricing[item.courier_id].frwd_a.roi}</td>
                          <td>{courierPricing[item.courier_id].frwd_a.extended_location}</td>
                          <td>{courierPricing[item.courier_id].frwd_a.special_destination}</td>
                          <td colSpan="2" className="align-bottom"></td>
                        </tr>
                        {(item.movement_type_id === 1 || item.movement_type_id === '1') &&
                          <>
                            <tr>
                              <td rowSpan="2" className="align-middle font-weight-bold">RTO</td>
                              <td>Basic</td>
                              <td>{courierPricing[item.courier_id].rto_b.weight_in_kgs}</td>
                              <td>{courierPricing[item.courier_id].rto_b.within_city}</td>
                              <td>{courierPricing[item.courier_id].rto_b.within_state}</td>
                              {/* <td>{courierPricing[item.courier_id].rto_b.within_zone}</td> */}
                              <td>{courierPricing[item.courier_id].rto_b.metro}</td>
                              <td>{courierPricing[item.courier_id].rto_b.roi}</td>
                              <td>{courierPricing[item.courier_id].rto_b.extended_location}</td>
                              <td>{courierPricing[item.courier_id].rto_b.special_destination}</td>
                              <td colSpan="2" className="align-bottom"></td>
                            </tr>
                            <tr>
                              <td>Additional</td>
                              <td>{courierPricing[item.courier_id].rto_a.weight_in_kgs}</td>
                              <td>{courierPricing[item.courier_id].rto_a.within_city}</td>
                              <td>{courierPricing[item.courier_id].rto_a.within_state}</td>
                              {/* <td>{courierPricing[item.courier_id].rto_a.within_zone}</td> */}
                              <td>{courierPricing[item.courier_id].rto_a.metro}</td>
                              <td>{courierPricing[item.courier_id].rto_a.roi}</td>
                              <td>{courierPricing[item.courier_id].rto_a.extended_location}</td>
                              <td>{courierPricing[item.courier_id].rto_a.special_destination}</td>
                              <td colSpan="2" className="align-bottom"></td>
                            </tr>
                          </>
                        }
                      </tbody>
                    </Table>
                  </>
                  : loadertable ? <tr> <td colSpan="6"><LoaderDefault /></td> </tr> :
                    <tr> <td colSpan="6"><NodataFound /></td> </tr>}
              </CardBody>
            </UncontrolledCollapse>
          </>
        )) : loader ? <tr> <td colSpan="6"><LoaderDefault /></td> </tr> :
          <tr> <td colSpan="6"><NodataFound /></td> </tr>}
      </Row>
    </Form>
  );
}

export default MyCouriers;