import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, Spinner, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import LoaderDefault from '../LoaderDefault';
import { changeIntoDate, errorHandling, remExtraSpace } from '../../utlis';
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { connect, useDispatch } from 'react-redux'
import { AllSignupMerchant } from '../../actions';
import { ChevronLeft, ChevronRight } from 'react-feather';
import axios from 'axios';
import { baseString } from '../../config';

const configData = require("../../config");
const SignupMerchants = (props) => {
  const [merchant, setMerchant] = useState({})
  const [page, setPage] = useState(1)
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [modalPending, setModalPending] = useState(false);
  const [currentID, setCurrentID] = useState();
  const dropFilterCount = 20

  const dispatch = useDispatch();
  const togglePending = (obj) => {
    if (obj.status === 0) {
      setModalPending(!modalPending)
    }
    setCurrentID(obj.id);
  };
  const toggleClose = () => {
    setModalPending(false);
  }

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  const merchantListing = () => {
    setLoadertable(true);
    reset();
    setFilterInput('')
    setCurrentCount(1)
    setPage(1)
    let mypost = {}
    let myget = { "page": page, "per_page": selectperpage, "admin_id": props.user[0].id }
    dispatch(AllSignupMerchant(mypost, myget)).then((response) => {
      setMerchant(response);
      setLoadertable(false)
    }).catch(function (response) {
      setLoadertable(false);
      errorHandling(response, "reload");
    });
  }

  useEffect(() => {
    if (checkData(props.state) && checkData(props.state.viewAllSignupMerchant)) {
      setMerchant(props.state.viewAllSignupMerchant);
    } else {
      merchantListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectperpage, page])
  function nextPageData() {
    if (checkData(merchant.data) && checkUndeNullBlank(merchant.totalPage)) {
      // &&checkUndeNullBlank(merchant.totalPage).length>page.length
      if (merchant.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(merchant.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(merchant.data)
      if (currentCount > Object.entries(merchant.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register, handleSubmit, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.brand_name) || checkUndeNullBlank(data.email) || checkUndeNullBlank(data.company_name)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
    fetch(`${process.env.REACT_APP_GET_MERCHANT}?brand_name=${checkUndeNullBlank(data.brand_name) ? remExtraSpace(data.brand_name) : ''}&email=${checkUndeNullBlank(data.email) ? remExtraSpace(data.email) : ''}&company_name=${checkUndeNullBlank(data.company_name) ? remExtraSpace(data.company_name) : ''}&page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}&admin_id=${checkUndeNullBlank(props.user[0].id) ? props.user[0].id : ''}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
        "token": window.btoa(baseString)
      },
    }).then((resp) => {
      resp.json().then((result) => {
        if (result.fatal !== true) {
          setLoader(false)
          setMerchant(result)
        }
      });
    }).catch((err) => {
      setLoader(false);
      errorHandling(err, "");
    });
  };

  const { register: pending, handleSubmit: pendingStatus, formState: { errors } } = useForm();
  const submitPendingStatus = (data) => {
    setLoader(true);
    let formData = new FormData();
    if (props.user[0].user_type === "Super") {
      formData.append("admin_id", props.user[0].id);
    } else {
      formData.append("merchant_id", props.user[0].id);
    }
    formData.append("remarks", data.remarks);
    formData.append("id", currentID);

    axios({
      method: "post",
      url: process.env.REACT_APP_SIGNUP_UPDATE_MERCHANT,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      merchantListing();
      if (res.data.error === false) {
        setModalPending(false);
        Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', })
      }
    })
      .catch(function (err) {
        setLoader(false);
        errorHandling(err, "");
      });
  }

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 manageMerchant shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="mb-0 page-title">Signup Merchants</CardTitle>
              </CardHeader>
              <Form onSubmit={handleSubmit(onSubmit)} className="cardFilter">
                <Row className="mx-0 pt-1 justify-content-start justify-content-md-center">
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <input {...register("brand_name")} className="form-control-sm form-control" placeholder="Enter Brand Name" />
                    </FormGroup>

                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <input {...register("email")} type="email" className="form-control-sm form-control" placeholder="Enter Email" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <input {...register("company_name")} type="text" className="form-control-sm form-control" placeholder="Enter Company Name" />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={merchantListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(merchant) && checkData(merchant.data) && checkUndeNullBlank(merchant.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(merchant.data).length)} of {merchant.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th className='text-nowrap'>Brand Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(merchant.data) ? merchant.data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.company_name || 'NA'}</td>
                        <td>{item.brand_name || 'NA'}</td>
                        <td>{item.email || 'NA'}</td>
                        <td>{item.mobile || 'NA'}</td>
                        <td className="text-nowrap">{(item.created_at !== undefined && item.created_at !== null ? changeIntoDate(item.created_at) : 'NA')}</td>
                        <td className={item.status === 1 ? 'text-success' : 'text-warning'}><span onClick={() => togglePending({ "status": item.status, id: item.id })} className="cursor-pointer">{configData.merchant_status[item.status] || 'NA'}</span></td>
                      </tr>
                    )) : loadertable ? <tr><td colSpan="8"><LoaderDefault /></td></tr> :
                      <tr><td colSpan="8"><NodataFound /></td></tr>}
                  </tbody>
                </Table>
                <Modal isOpen={modalPending} toggle={togglePending} size="lg" scrollable={true}>
                  <ModalHeader toggle={toggleClose}>Remarks</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={pendingStatus(submitPendingStatus)}>
                      <Row className="mx-0 justify-content-center downloadModal">
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="pending_status">Please Enter Remarks<span className="text-danger">*</span></Label>
                            <textarea {...pending("remarks", { required: 'Please Enter Your Remarks' })} rows="4" className="form-control-sm form-control" placeholder="" id="pending_status" />
                            {errors.remarks && <span className="text-danger d-block error">{errors.remarks.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
                {checkData(merchant) && checkData(merchant.data) && checkUndeNullBlank(merchant.TotalRows) ?
                  <>
                    {merchant.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination>
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}
function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(SignupMerchants);