import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label, Spinner } from 'reactstrap';
import { DateRangePicker } from 'rsuite';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowUp, faChevronRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChevronLeft, ChevronRight } from 'react-feather';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { useForm } from "react-hook-form";
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { changeIntoDate, remExtraSpace, errorHandling } from '../../utlis';
import moment from 'moment';
import { baseString, download_delay } from '../../config';

const RemittanceTransactions = (props) => {

  const [merchants, setMerchants] = useState([]);
  const [adjustedData, setAdjustedData] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1)
  const [currentCount, setCurrentCount] = useState(1);
  const [selectperpage, setSelectperpage] = useState('20');
  const [filterDate, setFilterDate] = useState([]);
  const { register, handleSubmit, reset } = useForm();
  const [loader, setLoader] = useState(false);
  const [excelmodal, setExcelmodal] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const history = useHistory();

  const dropFilterCount = 20
  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef = dateRange => dateRangeRef = dateRange;

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    resetRemiAdjfile();
    if (!excelmodal) { setExcelFile(""); }
  }
  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0]);
  }
  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
      }
      return true
    });
  }
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }
  const transactiosLIsting = () => {
    reset();
    setFilterDate([]);
    let data = {}

    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setLoadertable(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_ADJUST_REMITTANE}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false)
      if (res.data.error === false) {
        setAdjustedData(res.data)
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "reload");
    });
  }

  useEffect(() => {
    transactiosLIsting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFilter = () => {
    reset();
    setMerchants([]);
    transactiosLIsting();
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoadertable(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(pagePerpage)
  }
  function nextPageData() {
    if (checkData(adjustedData.data) && checkUndeNullBlank(adjustedData.totalPage)) {
      if (adjustedData.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(adjustedData.data).length)
        setPage(page + 1)
        setLoadertable(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(adjustedData.data)
      if (currentCount > Object.entries(adjustedData.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(pagePerpage);
    }
  }

  const onpaginationFilter = (pagePerpage) => {
    onCommonFilter(pagePerpage)
  };
  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.ref_no) || checkData(merchants) || checkData(filterDate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      onCommonFilter(data, pagePerpage);
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    let merchantsId = [];
    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }
    let byData = { "ref_no": checkUndeNullBlank(data.ref_no) ? remExtraSpace(data.ref_no) : '', "merchant_ids": checkUndeNullBlank(merchantsId) ? merchantsId : '', "start_date": checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '', "end_date": checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '' }
    setLoadertable(true)

    if (props.user[0].user_type === "Super") {
      byData.admin_id = 1
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_ADJUST_REMITTANE}?page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false);
      if (res.data.error === false) {
        setAdjustedData(res.data)
      } else {
        Swal.fire({ title: 'Error', text: res.data.msg, icon: 'error', })
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoadertable(false);
    });
  };
  const { register: uploadFreight, handleSubmit: initRemittUploadSubmit, formState: { errors }, reset: resetRemiAdjfile } = useForm();
  const onInitRemittSubmit = (data) => {
    let formData = new FormData();
    formData.append("file", excelFile);
    formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_ADJUST_REMITTANE_UPLOAD}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      let errorFile = '';
      if (res.data.errorfile !== undefined) {
        errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
      }
      if (res.data.error === false) {
        Swal.fire({
          title: 'Success',
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
          text: res.data.msg,
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            setExcelFile(false);
            transactiosLIsting();
          }
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: res.data.msg,
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            setExcelFile(false);
            transactiosLIsting();
          }
        });
      }
    })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  }

  const exportShipment = (item) => {
    let data = { "merchant_id": item.merchant_id, "adjusted_ref_no": item.ref_no, "is_adjusted_export": 1, "is_export": 1 }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    }
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_REMITTANCE_LIST}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
        if (parseInt(res.data.errno) > 0) {
          setLoader(false)
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.data.is_export === 1) {
            setTimeout(function () {
              setLoader(false);
              history.push("/my-downloads");
            }, download_delay);
          }
          // setAllShipment(res.data);
        }
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err, "")
    });
  }

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 CourierRemittance shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="page-title">Remittance Adjusted</CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  <div className="btn-text btn-light" onClick={excelUpload}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Adjust Remittance</span></div>
                </div>
              </CardHeader>
              <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                <ModalHeader toggle={excelUpload}>Upload Adjust Remittance</ModalHeader>
                <ModalBody>
                  <Form onSubmit={initRemittUploadSubmit(onInitRemittSubmit)}>
                    <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Adjust Remittance via XLSX</p>
                    <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                    <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                    <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                    <Row className="m-0 pb-1">
                      <Col sm={12} className="p-0">
                        <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/remittance_adjust.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download
                        </Link>
                          <span className="ml-2">Upload Adjust Remittance Format File</span></p>
                      </Col>
                      <Col sm={12} className="m-auto pt-1 p-0">
                        <FormGroup>
                          <input {...uploadFreight("excelUpload", {
                            required: 'Please Upload XLSX File',
                            validate: {
                              lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                              acceptedFormats: files =>
                                ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                  files[0]?.type
                                ) || 'Only .xslx file allowed',
                            },
                          })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                          <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                          {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col sm={12} className="text-right">
                        <Button className="btn ctm-btn btn-sm" color="primary">Upload</Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
              <Form onSubmit={handleSubmit(onSubmit)} className="cardFilter">
                <Row className="mx-0 justify-content-start">
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={setMerchants}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                          className={checkData(merchants) && "active"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Tran. Date Range" format="dd-MM-yyyy" ref={dateRangeRef} change={onDateChange} />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <input {...register("ref_no")} className="form-control-sm form-control" placeholder="Enter Transaction ID" />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" onClick={resetFilter} outline>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(adjustedData) && checkData(adjustedData.data) && checkUndeNullBlank(adjustedData.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(adjustedData.data).length)} of {adjustedData.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Merchant Name</th>
                      <th className="text-nowrap">Invoice No.</th>
                      <th className="text-nowrap">Adjusted Date</th>
                      <th className="text-nowrap">Adjusted Amount</th>
                      {/* <th className="text-nowrap">Total Docket(s)</th> */}
                      <th className="text-nowrap">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(adjustedData.data) ? adjustedData.data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.MerchantName}</td>
                        <td>{item.ref_no}</td>
                        <td>{changeIntoDate(item.trans_date)}</td>
                        <td>{item.debit_amt}</td>
                        {/* <td>{item.MerchantName}</td> */}
                        <td><Button className="btn btn-sm text-nowrap" color="info" outline onClick={() => { exportShipment(item) }}>Download Excel</Button></td>
                      </tr>
                    )) :
                      loadertable ? <tr><td colSpan="8" className="text-center"><LoaderDefault /></td></tr>
                        : <tr><td colSpan="8" className="text-center"><NodataFound /></td></tr>}
                  </tbody>
                </Table>
                {checkData(adjustedData) && checkData(adjustedData.data) && checkUndeNullBlank(adjustedData.TotalRows) ?
                  <>
                    {adjustedData.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}

export default connect(mapDispatchToProps)(RemittanceTransactions);