import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, Spinner, UncontrolledTooltip, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DateRangePicker } from 'rsuite';
import { useForm } from "react-hook-form";
import { changeIntoDateTime, remExtraSpace, copyToClipBoard, firstDayOftheMonth, errorHandling } from '../../utlis';
import Swal from 'sweetalert2';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { Copy, ChevronLeft, ChevronRight } from 'react-feather';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { baseString } from '../../config';

const DebitNote = (props) => {
  const [addModal, setAddModal] = useState(false);
  const [debitNote, setDebitNote] = useState({});
  const [merchants, setMerchants] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1)
  const [selectperpage, setSelectperpage] = useState('20');
  const [currentCount, setCurrentCount] = useState(1);
  const [filterDate, setFilterDate] = useState([]);
  const [filterInput, setFilterInput] = useState({});
  const dropFilterCount = 20;

  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef = dateRange => dateRangeRef = dateRange;

  const createEntry = () => {
    setAddModal(!addModal);
  }


  useEffect(() => {
    debitNoteListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectperpage, page])

  const debitNoteListing = () => {
    setLoadertable(true);
    reset();
    resetEntryData();
    setAddModal(false);
    setMerchants([]);
    setFilterDate([firstDayOftheMonth(), new Date()]);

    let data = { "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), "end_date": moment().format('YYYY-MM-DD') }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_DEBIT_NOTE_LIST}?page=${page}&per_page=${selectperpage}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          setDebitNote(res.data);
        }
      }
    }).catch((err) => {
      setLoadertable(false);
      errorHandling(err, "reload")
    });
  }

  // filter Debit Note
  const { register: filter, handleSubmit: filterDebitNoteSubmit, reset } = useForm();

  const { register: addDebitNote, handleSubmit: debitNoteSubmit, formState: { errors }, reset: resetEntryData } = useForm();
  const onCreateDebitNote = (data) => {
    setLoader(true);
    let formData = {};

    if (props.user[0].user_type === "Super") {
      formData.admin_id = props.user[0].id;
    }
    formData.merchant_id = data.merchant_id;
    formData.note_detail = data.note_detail;
    formData.amount = data.amount;
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_DEBIT_NOTE_CREATE,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', })
        debitNoteListing();
      } else {
        Swal.fire({ title: 'Error', text: res.data.msg, icon: 'error', })
      }
    })
      .catch(function (err) {
        setLoader(false);
        errorHandling(err, "");
      });
  }

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }

  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(debitNote.data)
      if (currentCount > Object.entries(debitNote.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }

  function nextPageData() {
    if (checkData(debitNote.data) && checkUndeNullBlank(debitNote.totalPage)) {
      if (debitNote.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(debitNote.data).length)
        setPage(page + 1)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }

  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };

  const onDebitNote = (data, pagePerpage) => {
    // setIsExport(true);
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.debit_note_no) || checkData(merchants) || checkData(filterDate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }
  };

  const onCommonFilter = (data, pagePerpage) => {
    let byData = {}
    let merchantsId = [];
    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }
    setLoader(true)
    let mId = checkUndeNullBlank(merchantsId) ? merchantsId : '';
    let sDate = checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '';
    let eDate = checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '';

    byData = { "debit_note_no": checkUndeNullBlank(data.debit_note_no) ? remExtraSpace(data.debit_note_no) : '', "merchant_ids": mId, "start_date": sDate, "end_date": eDate }
    setFilterInput(byData);

    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_DEBIT_NOTE_LIST}?page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setDebitNote(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "search")
      setLoader(false);
    });
  };


  //copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }

  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
      }
      return true
    });
  }
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 MerchantDebitNote shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="page-title">Manage Debit Note</CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  {props.user[0].user_type === "Super" &&
                    <div className="btn-text btn-light" onClick={createEntry} ><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Create Entry</span></div>}
                </div>
                <Modal isOpen={addModal} toggle={addModal} scrollable={true} >
                  <ModalHeader toggle={createEntry}>Create Entry</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={debitNoteSubmit(onCreateDebitNote)}>
                      <Row className="mx-0 justify-content-center">
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="merchant_id">Select Merchant<span className="text-danger">*</span></Label>
                            <div className="position-relative">
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select {...addDebitNote("merchant_id", { required: 'Please Select Merchant' })} className="form-control-sm form-control" id="merchant_id">
                                <option value="">Select Merchant</option>
                                {checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants) &&
                                  Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
                                    let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
                                    let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
                                    return <option value={merchantId}>{merchantName}</option>
                                  })
                                }
                              </select>
                            </div>
                            {errors.merchant_id && <span className="text-danger d-block error">{errors.merchant_id.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="note_detail">Item Description<span className="text-danger">*</span></Label>
                            <textarea {...addDebitNote("note_detail", { required: 'Please Enter Item Description' })} rows="3" placeholder="Item Description" className="form-control-sm form-control" id="note_detail" />
                            {errors.note_detail && <span className="text-danger d-block error">{errors.note_detail.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="amount">Debit Note Amount<span className="text-danger">*</span></Label>
                            <input {...addDebitNote("amount", {
                              required: 'Please Enter Amount',
                              pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Amount' }
                            })} placeholder="Amount" className="form-control-sm form-control" id="amount" />
                            {errors.amount && <span className="text-danger d-block error">{errors.amount.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </CardHeader>
              <Form onSubmit={filterDebitNoteSubmit(onDebitNote)} className="cardFilter">
                <Row className="mx-0 justify-content-start justify-content-md-center">
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <input {...filter("debit_note_no")} type="text" placeholder="Enter Debit Note No" className="form-control-sm form-control" />
                    </FormGroup>
                  </Col>
                  {props.user[0].user_type === "Super" &&
                    <Col sm={6} md={5} lg={4} xl={3}>
                      <FormGroup>
                        <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={(e) => { setMerchants(e); }}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                          className={checkData(merchants) && "active"}
                        />
                      </FormGroup>
                    </Col>}
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Tra. Date Range" format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} ref={dateRangeRef} change={onDateChange} />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={debitNoteListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(debitNote) && checkData(debitNote.data) && checkUndeNullBlank(debitNote.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(debitNote.data).length)} of {debitNote.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      {props.user[0].user_type === "Super" &&
                        <th>Merchant</th>}
                      <th className="text-nowrap">Debit Note No.</th>
                      <th>Transaction Date</th>
                      <th>Debit Amount</th>
                      <th className="w-some">Item Detail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(debitNote.data) ? debitNote.data.map((item, index) => (
                      <tr>
                        {props.user[0].user_type === "Super" &&
                          <td>{item.MerchantName}</td>
                        }
                        <td>
                          <div className="text-nowrap">
                            <Link target="_blank" to={`/debit-note-detail/${item.id}`}>DN/{item.debit_note_no}</Link>
                            <span onClick={() => copied(item.debit_note_no)} className="ml-1 copy-btn" id={`copyOdr${item.id}`}>
                              <Copy />
                              <UncontrolledTooltip placement="bottom" target={`copyOdr${item.id}`}>
                                Copy
                              </UncontrolledTooltip>
                            </span>
                          </div>
                        </td>
                        <td className="text-nowrap">{changeIntoDateTime(item.created_at)}</td>
                        <td>{item.amount}</td>
                        <td>{item.note_detail}</td>
                      </tr>
                    ))
                      : loadertable ? <tr> <td colSpan="7"><LoaderDefault /></td> </tr> :
                        <tr> <td colSpan="7"><NodataFound /></td> </tr>}
                  </tbody>
                </Table>
                <ToastContainer style={{ width: "200px" }} />
                {checkData(debitNote) && checkData(debitNote.data) && checkUndeNullBlank(debitNote.TotalRows) ?
                  <>
                    {debitNote.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}

export default connect(mapDispatchToProps)(DebitNote);