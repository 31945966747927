import React,{useState, useEffect} from 'react';
import NDRIVRResponse from './NDRIVRResponse';
import NDRSummary from './NDRSummary';
import NDRCards from './NDRCards';
import {connect, useDispatch} from 'react-redux';
import { ndrCardOverView } from '../../../../actions';
import { errorHandling } from '../../../../utlis';
import { checkData } from '../../../../ObjectExist';

const NDR = (props) => {
    const [cardsOverView, setCardsOverView] = useState({});
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const listingReport = () => {
      setLoader(true);
        let mypost = {"merchant_id":props.user[0].id}
        let myget = {}
        dispatch(ndrCardOverView(mypost,myget)).then((response) => {
          if(response.error === false){
            setCardsOverView(response.data)
          }
          setLoader(false)
        }).catch(function (response) {
          setLoader(false);
          errorHandling(response,"reload");
        });
    }

    useEffect(() => {
      if(checkData(props.state) && checkData(props.state.viewNdrCardOver)&& checkData(props.state.viewNdrCardOver.data)){
        setCardsOverView(props.state.viewNdrCardOver.data);
      }else{
        listingReport();
      }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="overview">
            <div className="mb-4 d-none"><NDRIVRResponse user={props.user} /></div>
            <div className="mb-4"><NDRCards  user={props.user} cardsOverView={cardsOverView} loader={loader} /></div>
            <div className="mb-4"><NDRSummary  user={props.user} cardsOverView={cardsOverView} loader={loader} /></div>
        </div>
    );
}

export default NDR;