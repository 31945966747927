import React, {useEffect, useState} from 'react';
import { Row, Col, Card, CardBody,Badge, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMoneyBillAlt,faCubes, faCube, faThLarge, faTh, faCodeBranch, faHistory } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import { changeIntoDatedmY, firstDayOftheMonth, errorHandling, nFormatter } from '../../../../utlis';
import {Link} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux'
import { dashCardOverView } from '../../../../actions';
import { AlertTriangle } from 'react-feather';
import Confetti from 'react-dom-confetti';
import LoaderDefault from '../../../LoaderDefault';
import { baseString } from '../../../../config';


const Cards = (props) => {
    const [cardsOverView, setCardsOverView] = useState({});
    let [processCount,setProcessCount] = useState(0);
    const [loader, setLoader] = useState(false);
    const [startConfetti,setStartConfetti] = useState(false);
    const dispatch = useDispatch();
    const listingSummary = () => {
        setLoader(true);
        let mypost = {"merchant_id":props.user[0].id}
        let myget = {}
        dispatch(dashCardOverView(mypost,myget)).then((response) => {
            if(response.error === false){
              setCardsOverView(response.data);
            }
            setLoader(false)
          }).catch(function (response) {
            setLoader(false);
            errorHandling(response,"reload");
          });
    }

    //orders count listing
    const orderCountListing = () => {
      let data = {}
      if(props.user[0].user_type === "Super"){
        data.admin_id=props.user[0].id
      }else{
        data.merchant_id=props.user[0].id
      }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_ORDER_COUNT}`,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
          if(res.data.error === false){
            let myResponse = res.data.data
            if(checkData(myResponse)){
              myResponse.map((item) => {
                let processingCount = (checkUndeNullBlank(item.order_status_id) && (parseInt(item.order_status_id) === 25)) ? item.total : 0
                if(parseInt(processingCount)>0){
                  setProcessCount(processingCount);
                }
                return true
              }); 
            }
          }else{
            Swal.fire({icon:'error',text:res.data.msg,title:'Error'});
          }
      }).catch((err) => {
        errorHandling(err,"reload");
      })
    }

    window.addEventListener('load',function(){
      setStartConfetti(true);
    })

    useEffect(() => {
      if(checkData(props.state) && checkData(props.state.viewAllCardOver)&& checkData(props.state.viewAllCardOver.data)){
        setCardsOverView(props.state.viewAllCardOver.data);
      }else{
        listingSummary();
      }
      orderCountListing();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const cardData = [
        { icon: faCubes, text: "Today's Shipments", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.today_shipments) ? cardsOverView.today_shipments : '0', link:`/shipments?start_date=${changeIntoDatedmY(new Date())}&end_date=${changeIntoDatedmY(new Date())}`, color: "info" },
        { icon: faMoneyBillAlt, text: "Today's Failed", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.today_failed_shipments) ? cardsOverView.today_failed_shipments : '0', link:`/orders?start_date=${changeIntoDatedmY(new Date())}&end_date=${changeIntoDatedmY(new Date())}`, color: "warning" },
        { icon: faCube, text: "Pickup Pending", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.today_pickuppending_shipments) ? cardsOverView.today_pickuppending_shipments : '0', link:"/shipments?s=label-manifest", color: "primary" },
        { icon: faThLarge, text: "Month Shipments", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.this_month_shipments) ? cardsOverView.this_month_shipments : '0', link:`/shipments?start_date=${changeIntoDatedmY(firstDayOftheMonth())}&end_date=${changeIntoDatedmY(new Date())}`, color: "danger" },
        { icon: faTh, text: "Total Shipments", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.total_shipments) ? cardsOverView.total_shipments : '0', link:"/shipments?total=allShipments", color: "success" },
        // { icon: faCreditCard, text: "Active Couriers", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.total_active_couriers) ? cardsOverView.total_active_couriers : '0', link:"/settings/9", color: "danger" },
        // { icon: faCodeBranch, text: "Channel Orders", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.today_channel_order) ? cardsOverView.today_channel_order : '0', link:`/channel-orders?start_date=${changeIntoDatedmY(new Date())}&end_date=${changeIntoDatedmY(new Date())}`, color: "info" },
        { icon: faCodeBranch, text: "Today's Channel Orders", value: checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.today_channel_order) ? cardsOverView.today_channel_order : '0', link:`/channel-orders?o=all`, color: "info" },
      ];

      let channelOdrVal = checkData(cardsOverView) && checkUndeNullBlank(cardsOverView.today_channel_order) ? cardsOverView.today_channel_order : 0;
      
      const config = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 2000,
        dragFriction: 0.09,
        duration: 20000,
        stagger: 10,
        width: "10px",
        height: "10px",
        perspective: "1000px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
      };
      
    return (
      <Row>
        {/* {loader && <div className="formLoader"><Spinner /></div>} */}
        {channelOdrVal >= 1000 && <div className={`confetti-con ${startConfetti ? 'd-block' : 'd-none'}`}><Confetti active={startConfetti && channelOdrVal >= 1000 ? true : false } config={ config } className="confetti" /></div>}
        <Col xs={12} sm={12} md={12} lg={12} className="">          
          <Card className="overview-card shadow border-0 py-3">
          {loader ? <LoaderDefault /> :
            <CardBody className="py-0 d-flex">  
            <span className='reload-card' onClick={listingSummary} title="Refresh"><i class="fa fa-refresh" aria-hidden="true"></i></span>          
              <Row className="card-overview-list w-100">
              {cardData.map((cardData)=>
                <Col xs={6} sm={4} md={3} className="border-right">                  
                  <div className='media'>
                    <div className='media-aside mr-2 align-self-start'>
                      <div className="card-icon"><Badge color={cardData.color} pill className="p-0 d-flex justify-content-center align-items-center mx-auto"><FontAwesomeIcon icon={cardData.icon} /></Badge></div>
                    </div>
                    <div className='media-body my-auto position-relative'>
                      <h4 className={`card-val ${cardData.text === "Today's Channel Orders" && startConfetti && channelOdrVal >= 1000 && 'confetti-animate-text'}`}>
                       <Link to={cardData.link}>{
                         cardData.text === "Today's Channel Orders" ?
                         cardData.value : nFormatter(cardData.value, 1)
                       }</Link>
                       {cardData.text === "Today's Shipments" && processCount > 0 && 
                       <div className="processing-triangle-icon"><Link to="/orders?o=processing"><AlertTriangle /></Link></div>}
                      </h4>
                      <p className="text-muted text-nowrap font-weight-500 title-card">{cardData.text}</p>
                      {cardData.text === "Today's Shipments" && processCount > 0 &&  
                      <p className="text-warning text-nowrap font-weight-500 title-card act-pending-area m-0 d-none">
                        <span className="process-loader d-inline-flex align-items-center justify-content-center"><Spinner size="sm" /></span><Link to="/orders?o=processing" className="text-warning text-decoration-underline">Action Pending ({processCount})</Link></p>}
                    </div>
                  </div>
                </Col>
              )}
              </Row>
            </CardBody>}
          </Card>
        </Col>
      </Row>
    );
}

function mapDispatchToProps (state) {
  return{
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(Cards);