import React, {useState} from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Overview from './Overview/Overview';
import AllCourier from './Couriers/AllCourier';
import NDR from './NDR/NDR';

const Dashboard = (props) => {

    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div className="app-content content overflow-hidden">
          <Row>
           <Col sm={12}>            
            <div className="p-0 my-3 dashboard content-wrapper">
                <div className="content-header mb-4">
                  <Nav tabs pills className="justify-content-center mb-2 border-0 header-tabs">
                      <NavItem>
                          <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                              Overview
                          </NavLink>
                      </NavItem>
                      <NavItem className='d-none'>
                          <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                              Couriers
                          </NavLink>
                      </NavItem>
                      <NavItem className=''>
                          <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                             NDR Report
                          </NavLink>
                      </NavItem>
                  </Nav>
                </div>
                <TabContent activeTab={activeTab} className="pb-0">
                  <TabPane tabId="1">
                    <Overview user={props.user} oneCalendar={props.oneCalendar} />
                  </TabPane>
                  <TabPane tabId="2">
                    <AllCourier user={props.user} oneCalendar={props.oneCalendar} />
                  </TabPane>
                  <TabPane tabId="3">
                    <NDR user={props.user} oneCalendar={props.oneCalendar} />
                  </TabPane>
                </TabContent>
            </div>
            </Col>         
          </Row>
        </div>
    );
}

export default Dashboard;