import React, { useEffect } from 'react';
import { Row, Col, Label, Form, FormGroup, Button, CardTitle } from 'reactstrap';
import Swal from 'sweetalert2'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { errorHandling } from '../../../utlis';
import { baseString } from '../../../config';
import { checkData } from '../../../ObjectExist';

const EmailNotification = (props) => {
    const { register: addEmailNotification, handleSubmit: merchantEmailSubmit, reset: emailNotiReset, formState: { errors } } = useForm();

    useEffect(() => {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_NOTIFICATION}/view`,
            data: { "merchant_id": props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '' },
            headers: { "token": window.btoa(baseString) }
        })
            .then(function (response) {
                let myResponse = response.data[0];
                emailNotiReset(myResponse);
            })
            .catch(function (err) {
                errorHandling(err, "reload");
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onEmailNotification = (data) => {
        props.setLoader(true);
        let formData = new FormData();
        // formData.append("merchant_id", props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '');
        formData.append("merchant_id", checkData(props.user) && props.user[0].user_type === "Merchant" ? props.user[0].id : '');
        formData.append("accounts_email", data.accounts_email);
        formData.append("ndr_email", data.ndr_email);
        formData.append("order_summary_email", data.order_summary_email);
        formData.append("weight_alert_email", data.weight_alert_email);

        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_NOTIFICATION}`,
            data: formData,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            props.setLoader(false);
            if (res.data.error === false) {
                Swal.fire({
                    title: 'Success',
                    text: res.data.msg,
                    icon: 'success',
                })
            }
        })
            .catch((err) => {
                props.setLoader(false);
                errorHandling(err, "");
            });
    };
    return (
        <Form onSubmit={merchantEmailSubmit(onEmailNotification)} >
            <div className="sub-page-header" >
                <CardTitle tag="h4" className="sub-page-title" > Email Notification </CardTitle></div>
            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="acont_mail" > Accounts Mail </Label>
                        <input {...addEmailNotification("accounts_email", { pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' } })} type="text" className="form-control" placeholder="Accounts Mail" id="acont_mail" />
                        {errors.accounts_email && <span className="text-danger d-block error">{errors.accounts_email.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="ndr_mail"> NDR Mail </Label>
                        <input {...addEmailNotification("ndr_email", { pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' } })} type="text" className="form-control" placeholder="NDR Mail" id="ndr_mail" />
                        {errors.ndr_email && <span className="text-danger d-block error">{errors.ndr_email.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="order_summary"> Order Summary </Label>
                        <input {...addEmailNotification("order_summary_email", { pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' } })} type="text" className="form-control" placeholder="Order Summary" id="order_summary" />
                        {errors.order_summary_email && <span className="text-danger d-block error">{errors.order_summary_email.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="weight_alert" > Weight Alert </Label>
                        < input {...addEmailNotification("weight_alert_email", { pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' } })} type="text" className="form-control" placeholder="Weight Alert" id="weight_alert" />
                        {errors.weight_alert_email && <span className="text-danger d-block error">{errors.weight_alert_email.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={12} className="mt-2 text-right">
                    <Button className="btn-sm ctm-btn" color="primary" > Save changes </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default EmailNotification;