import React, { useEffect, useState } from 'react';
import { Row, Col, Label, Button, Form, FormGroup, CardTitle, UncontrolledTooltip } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import axios from 'axios';
import { errorHandling, copyToClipBoard } from '../../utlis';
import { baseString } from '../../config';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { Copy } from 'react-feather';
import { toast } from 'react-toastify';

const ApiIntegration = (props) => {
    const [apikey, setApikey] = useState({})
    const { register: apiInteg, handleSubmit: ApiIntegSubmit, formState: { errors }, reset } = useForm();
    const onApiIntegFetch = () => {
        let data = {}
        props.setLoader(true);
        if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
            data.merchant_id = props.user[0].id
        } else {
            data.admin_id = props.user[0].id
            data.merchant_id = props.merchantId
        }

        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_GET_API_KEY}`,
            data: data,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            props.setLoader(false);
            // if (res.data.error === false) {
            reset(res.data[0])
            setApikey(res.data[0])
            // }
        })
            .catch((err) => {
                props.setLoader(false);
                errorHandling(err, "");
            });
    };
    useEffect(() => {
        onApiIntegFetch();
    }, [])
    const onApiInteg = (data) => {
        var crypto = require("crypto");
        var id = crypto.randomBytes(16).toString('hex');
        reset({ live_api_key: id })
        console.log('id', id)
        props.setLoader(true);
        let formData = new FormData();
        formData.append("live_api_key", id);
        if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
            formData.append("merchant_id", props.user[0].id);
        } else {
            formData.append("admin_id", props.user[0].id);
            formData.append("merchant_id", props.merchantId);
        }

        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_SAVE_API_KEY}`,
            data: formData,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            props.setLoader(false);
            if (res.data.error === false) {
                // Swal.fire({
                //     title: 'Success',
                //     text: res.data.msg,
                //     icon: 'success',
                // })
                onApiIntegFetch();
            }
            if (res.data.error === true) {
                Swal.fire({
                    title: 'Error',
                    text: res.data.msg,
                    icon: 'error',
                })
            }
        })
            .catch((err) => {
                props.setLoader(false);
                errorHandling(err, "");
            });
    };
    // copy text
    const copied = (text) => {
        let decision = copyToClipBoard(text);
        decision.then(function (result) {
            if (result === true) {
                toast("Copied!", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }
    return (
        <Form onSubmit={ApiIntegSubmit(onApiInteg)}>
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">API Credintial</CardTitle>
            </div>
            <Row>
                <Col sm={9}>
                    <FormGroup>
                        <Label for="api-key">API Key<span className="text-danger">*</span></Label>
                        {/* <input {...apiInteg("live_api_key", { required: 'Please Enter API Key',minLength: { value: 32, message: "Please Enter minimum 32 Character" } })} className="form-control" placeholder="API Key" id="api-key" disabled /> */}
                        <input {...apiInteg("live_api_key")} className="form-control" placeholder="API Key" id="api-key" disabled />
                        {checkData(apikey) && checkUndeNullBlank(apikey.live_api_key) ?<span onClick={() => copied(apikey.live_api_key)} className="ml-1 copy-btn" id={`copykey`}>
                            Copy this API key <Copy />
                            <UncontrolledTooltip placement="bottom" target={`copykey`}>
                                Copy
                            </UncontrolledTooltip>
                        </span>:''}
                        {errors.live_api_key && <span className="text-danger d-block error">{errors.live_api_key.message}</span>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={12} className="mt-2 text-right">
                    <Button className="btn-sm ctm-btn" color="primary"> {checkData(apikey) && checkUndeNullBlank(apikey.live_api_key) ? 'ReGenerate' : 'Generate'}</Button>
                </Col>
            </Row>
        </Form>
    );
}

export default ApiIntegration;