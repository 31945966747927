import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,Button,Table, CardTitle,Badge, FormGroup,Modal,Form, CardHeader, ModalHeader, CardFooter, ModalBody, Label, Spinner, CardText, UncontrolledTooltip} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { baseString, order_status } from '../../config';
import { connect } from 'react-redux';
import { checkData,checkUndeNullBlank } from '../../ObjectExist';
import {Link} from "react-router-dom";
import wordpressIcon from '../../assets/img/wordpress-icon.png';
import shopifyIcon from '../../assets/img/shopify-icon.png';
import customIcon from '../../assets/img/custom.png';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2'
import NodataFound from '../NodataFound';
import LoaderDefault from '../LoaderDefault';
import { copyToClipBoard, errorHandling } from '../../utlis';
import { Copy } from 'react-feather';
import { toast } from 'react-toastify';
import Status from '../Status';

const ChannelIntegration = (props) => {
  const [loader, setLoader] = useState(false);
  const [addShopify, setAddShopify] = useState(false);
  const [addWordPress, setAddWordPress] = useState(false);
  const [addCustom, setAddCustom] = useState(false);
  const [payLoadModal, setPayLoadModal] = useState(false);
 const [brandList, setBrandList] = useState({});
 const [intrigatedBrandList, setIntrigatedBrandList] = useState({});
 const [currentCount,setCurrentCount] = useState(1);
 const [loadertable, setLoadertable] = useState(false);
 
  const brandPointListing = () =>{
    setLoader(true);
  let data = {}
   if(props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant"){
    data = {"merchant_id":props.user[0].id}
   }
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_BRANDS,
      data: data,
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
    setLoader(false);
    if(response.data.fatal !== true){
          setBrandList(response.data)
        }
      })
      .catch(function (response) {
    setLoader(false);
    errorHandling(response,"reload")
      });
  }
  const intrigatedBrandListing = () =>{
    setLoadertable(true);
  let data = {}
   if(props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant"){
    data = {"merchant_id":props.user[0].id, "is_channel" :1}
   }
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_BRANDS,
      data: data,
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
    setLoadertable(false);
    if(response.data.fatal !== true){
      setIntrigatedBrandList(response.data)
        }
      })
      .catch(function (response) {
    setLoadertable(false);
    errorHandling(response,"reload")
      });
  }
  
  useEffect(() => {
    intrigatedBrandListing();
    setCurrentCount(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toggleShopify = () => {
    setAddShopify(!addShopify);
    brandPointListing();
  }
  const toggleWordpress = () => {
    brandPointListing();
    setAddWordPress(!addWordPress);
  }
  const toggleCustom = () => {
    brandPointListing();
    setAddCustom(!addCustom);
  }
  const customPayLoad = () => {
    setPayLoadModal(!payLoadModal);
  }

  const opt_orderStatus = [];
  for (const property in order_status) {
    let tempObj = {label: `${order_status[property]}`, value: property };
    opt_orderStatus.push(tempObj);
  }

  var opt_allCourier = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)){
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }     
      return opt_allCourier.push(tempObj)
    });
  }


 const { register:shopify, handleSubmit:shopifySubmit,formState: { errors }, setValue} = useForm();
 const { register:wordpress, handleSubmit:wordpressSubmit,formState: { errors: errorswordpress }, setValue:wordpressurl} = useForm();
 const { register:custom, handleSubmit:customSubmit,formState: { errors: errorscustom }, setValue:customurl} = useForm();
 const onShopifySubmit = (data)=> {
    data.merchant_id = props.user[0].id;
    data.webhook_url = '';
    data.webhook_status = 1;
    data.channel = 'shopify';
    setLoader(true);
    
    // axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/channel/shopify/${data.brand_id}/${user_id}`)
    axios({
      method: "post",
      url: process.env.REACT_APP_CHANNEL_UPDATE,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if(res.data.error === false){
        setAddShopify(false);
        Swal.fire({title: 'Success',text: res.data.msg,icon: 'success',});
        intrigatedBrandListing();
      }
    })
    .catch(function (err) {
      setLoader(false);
      errorHandling(err,"")
    });
 }
 const onWordpressSubmit = (data)=> {
    data.merchant_id = props.user[0].id;
    data.webhook_url = '';
    data.webhook_status = 1;
    data.channel = 'wordpress';
    setLoader(true);
    
    // axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/channel/shopify/${data.brand_id}/${user_id}`)
    axios({
      method: "post",
      url: process.env.REACT_APP_CHANNEL_UPDATE,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if(res.data.error === false){
        setAddWordPress(false);
        Swal.fire({title: 'Success',text: res.data.msg,icon: 'success',});
        intrigatedBrandListing();
      }
    })
    .catch(function (err) {
      setLoader(false);
      errorHandling(err,"")
    });
 }
 const EditChannel = (data)=>{
   console.log("data",data);

 }

 const onCustomSubmit = (data)=> {
    data.merchant_id = props.user[0].id;
    // data.webhook_url = '';
    data.webhook_status = 1;
    data.channel = 'custom';
    setLoader(true);
    
    // axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/channel/shopify/${data.brand_id}/${user_id}`)
    axios({
      method: "post",
      url: process.env.REACT_APP_CHANNEL_UPDATE,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if(res.data.error === false){
        setAddCustom(false);
        Swal.fire({title: 'Success',text: res.data.msg,icon: 'success',});
        intrigatedBrandListing();
      }
    })
    .catch(function (err) {
      setLoader(false);
      errorHandling(err,"")
    });
 }

 const getBrand = (brandId)=>{
    if(brandId !== ''){
      let user_id = props.user[0].id;
      setValue("webhook_url", `${process.env.REACT_APP_BASE_URL}/api/v1/channels/shopify/${brandId}/${user_id}`)
    }else{
      setValue("webhook_url", '')
    }
  }
 const getBrandwordpress = (brandId)=>{
    if(brandId !== ''){
      let user_id = props.user[0].id;
      wordpressurl("webhook_url", `${process.env.REACT_APP_BASE_URL}/api/v1/channels/wordpress/${brandId}/${user_id}`)
    }else{
      wordpressurl("webhook_url", '')
    }
  }
 const getBrandcustom = (brandId)=>{
    if(brandId !== ''){
      let user_id = props.user[0].id;
      customurl("webhook_url", `${process.env.REACT_APP_BASE_URL}/api/v1/channels/custom/${brandId}/${user_id}`)
    }else{
      customurl("webhook_url", '')
    }
  }
  let pLoad = {
      "order_number":121212,
      "tags":"test",
      "total_weight_in_kgs":0.5,
      "customer_ip":"102.111.111.22",
      "delivery_type":"cod or prepaid",
      "order_date":"2022-09-01", 
      "order_time":"12:00:00",
          "customer_details":{
              "name":"Joe",
              "address1":"34 2nd Street",
              "address2":"A Block",
              "phone":"9898989898",
              "city":"New Delhi",
              "zip":"110019",
              "state":"Delhi",
              "email":"abc@gmail.com"
          },
          "product_details":{
              "product_category":"Electronics",
              "product_code":"IPHONE12",
              "product_name":"IPHONE",
              "product_invoice_value":"1800",
              "product_cod_value":"1500",
              "product_qty":"1",
              "length_in_cms":"15",
              "breadth_in_cms":"25",
              "height_in_cms":"25"
          }
      }
 // copy text
 const copied = (text) =>{
  let decision = copyToClipBoard(text);
  decision.then(function(result) {
    if(result === true){
      toast("Copied!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  })
}
  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      
      <Row>
       <Col sm={12}>
         <div className="p-0 my-3 channelIntegration content-wrapper">
          <Card className="shadow border-0 mb-4">
            <CardHeader className="page-header d-flex justify-content-between align-items-start flex-wrap">
              <CardTitle className="mb-0 page-title">Channel Integration</CardTitle>              
            </CardHeader>
            <CardBody className="py-0">
              <div>
                <CardTitle tag="h6" className="font-weight-600 text-center">To Synchronous Your Order From Third Party Apps</CardTitle>
                <Row className="mt-4">
                  <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                    <div className="integrate-box mb-4"> 
                        <div className="d-flex align-items-center">
                            <Card className="mb-0 shadow mx-auto">
                                <CardBody className="d-flex flex-wrap align-items-center">
                                <div className='courier-img'>
                                    <img src={shopifyIcon} alt="img" className="courier_img img-fluid" width="50" />
                                </div>
                                <div className="mx-3">
                                    <CardTitle tag="h5" className="mb-0">Shopify</CardTitle>
                                </div>
                                </CardBody>
                                <CardFooter><div className="d-flex justify-content-between align-items-center"><Button className="btn ctm-btn btn-sm mr-2" color="primary" onClick={toggleShopify}>Setup</Button><Link target="_blank" download to="/format/provid_etrack_shopify.pdf"><span className='text-primary cursor-pointer'>Documentation</span></Link></div></CardFooter>
                            </Card>
                        </div>     
                    </div>   
                    <Modal isOpen={addShopify} toggle={addShopify} scrollable={true}>
                      <ModalHeader toggle={toggleShopify}>Shopify Webhook</ModalHeader>
                      <ModalBody>
                        <Form onSubmit={shopifySubmit(onShopifySubmit)}>
                          <Row className="mx-0 justify-content-center addModal">                            
                            <Col sm={12}>
                                <FormGroup>
                                  <Label for="brand">Choose Brand *</Label>
                                  <div className="position-relative">
                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                    <select {...shopify("brand_id", { required: 'Please Select Brand'})} className="form-control-sm form-control" id="brand" onChange={(e)=>getBrand(e.target.value)}>
                                      <option value="">Choose Brand</option>
                                      {checkData(brandList.data) &&  brandList.data.map((item, index) => (
                                        <>
                                        <option value={item.id}>{item.brand_name}</option>
                                        </>
                                      ))}
                                    </select>
                                  </div>
                                  {errors.brand && <span className="text-danger d-block error">{errors.brand.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={12}>
                                <FormGroup>
                                  <Label for="shopify_webhook">Shopify Webhook</Label>
                                  <input type="text" {...shopify("webhook_url", { required: 'Please Select Brand'})} className="form-control-sm form-control" value="" id="shopify_webhook" readonly="true" />
                                  {/* {errors.shopify_webhook && <span className="text-danger d-block error">{errors.shopify_webhook.message}</span>} */}
                                </FormGroup>
                            </Col>
                            <Col sm={12}>
                                <FormGroup>
                                  <Label for="shopify_app_token">Shopify App Token</Label>
                                  <input type="text" {...shopify("shopify_app_token")}  className="form-control-sm form-control"  id="shopify_app_token"  />
                                  {errors.shopify_app_token && <span className="text-danger d-block error">{errors.shopify_app_token.message}</span>}

                                 </FormGroup>
                            </Col>
                            <Col sm={12}>
                                <FormGroup>
                                  <Label for="shopify_app_token">Shopify Store Name</Label>
                                  <input type="text" {...shopify("shopify_store_name")}  className="form-control-sm form-control"  id="shopify_store_name"  />
                                  {errors.shopify_store_name && <span className="text-danger d-block error">{errors.shopify_store_name.message}</span>}

                                 </FormGroup>
                            </Col>
                            <Col sm={12} className="text-right">
                              <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                            </Col>
                          </Row>
                        </Form>  
                      </ModalBody>
                    </Modal>                       
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                    <div className="integrate-box mb-4"> 
                        <div className="d-flex align-items-center">
                            <Card className="mb-0 shadow mx-auto">
                                <CardBody className="d-flex flex-wrap align-items-center">
                                <div className='courier-img'>
                                    <img src={wordpressIcon} alt="img" className="courier_img img-fluid" width="50" />
                                </div>
                                <div className="mx-3">
                                    <CardTitle tag="h5" className="mb-0">Wordpress</CardTitle>
                                </div>
                                </CardBody>
                                <CardFooter><div className="d-flex justify-content-between align-items-center"><Button className="ctm-btn btn-sm mr-2" color="primary"  onClick={toggleWordpress}>Setup</Button><span className='text-primary cursor-pointer'>Documentation</span></div></CardFooter>
                            </Card>
                        </div>     
                    </div>  
                    <Modal isOpen={addWordPress} toggle={addWordPress} scrollable={true}>
                      <ModalHeader toggle={toggleWordpress}>Wordpress Web Hook</ModalHeader>
                      <ModalBody>
                        <Form onSubmit={wordpressSubmit(onWordpressSubmit)}>
                          <Row className="mx-0 justify-content-center addModal">
                          <Col sm={12}>
                                <FormGroup>
                                  <Label for="brand">Choose Brand</Label>
                                  <div className="position-relative">
                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                    <select {...wordpress("brand_id", { required: 'Please Select Brand'})} className="form-control-sm form-control" id="brand" onChange={(e)=>getBrandwordpress(e.target.value)}>
                                      <option value="">Choose Brand</option>
                                      {checkData(brandList.data) &&  brandList.data.map((item, index) => (
                                        <>
                                        <option value={item.id}>{item.brand_name}</option>
                                        </>
                                      ))}
                                    </select>
                                  </div>
                                  {errorswordpress.brand && <span className="text-danger d-block error">{errorswordpress.brand.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={12}>
                                <FormGroup>
                                  <Label for="wordpress_webhook">Word Press Webhook</Label>
                                  <input type="text" {...wordpress("webhook_url", { required: 'Please Select Brand'})} className="form-control-sm form-control" value="" id="wordpress_webhook" readonly="true" />
                                  {/* {errors.wordpress_webhook && <span className="text-danger d-block error">{errors.wordpress_webhook.message}</span>} */}
                                </FormGroup>
                            </Col>
                            <Col sm={12} className="text-right">
                              <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                            </Col>
                          </Row>
                        </Form>  
                      </ModalBody>
                    </Modal>                          
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                    <div className="integrate-box mb-4"> 
                        <div className="d-flex align-items-center">
                            <Card className="mb-0 shadow mx-auto">
                                <CardBody className="d-flex flex-wrap align-items-center">
                                <div className='courier-img'>
                                    <img src={customIcon} alt="img" className="courier_img img-fluid" width="50" />
                                </div>
                                <div className="mx-3">
                                    <CardTitle tag="h5" className="mb-0">Custom</CardTitle>
                                </div>
                                </CardBody>
                                <CardFooter><div className="d-flex justify-content-between align-items-center"><Button className="ctm-btn btn-sm mr-2" color="primary"  onClick={toggleCustom}>Setup</Button>
                                <span className='text-primary cursor-pointer' onClick={customPayLoad}>Payload</span></div></CardFooter>
                            </Card>
                        </div>     
                    </div>  
                    <Modal isOpen={payLoadModal} toggle={payLoadModal} scrollable={true}>
                      <ModalHeader toggle={customPayLoad}>Payload<span className='ml-2 text-small'>(Use POST Method to send below Payload)</span></ModalHeader>
                      <ModalBody>
                          <div className='d-flex'>
                            <CardText>
                            <p>
                              <span onClick={() => copied(JSON.stringify(pLoad))} className="ml-1 copy-btn" id={`copyspyformat`}>
                              Copy this payload <Copy />
                                <UncontrolledTooltip placement="bottom" target={`copyspyformat`}>
                                  Copy
                                </UncontrolledTooltip>
                              </span>
                            </p>
                            <pre>
                              {JSON.stringify(pLoad, null, 2) }
                            </pre>
                          </CardText> 
                          <Table responsive className="text-left custom-table mb-0">
                              <thead>
                                <tr>
                                  <th>Data Type</th>
                                  <th>Rules</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr><td>order_number (</td><td><span className='text-danger'>Required</span> (Varchar 100)</td></tr>
                                <tr><td>Tags</td><td>Not Required</td></tr>
                                <tr><td>Weight in kgs</td><td><span className='text-danger'>Required</span> (Decimal 5,2) Float Value</td></tr>
                                <tr><td>Customer IP</td><td>Not Required</td></tr>
                                <tr><td>Delivery Type</td><td><span className='text-danger'>Required</span> (cod or prepaid)</td></tr>
                                <tr><td>Order Date</td><td><span className='text-danger'>Required</span> (YYYY-MM-DD)</td></tr>
                                <tr><td>Order Time</td><td><span className='text-danger'>Required</span> (H-i-s)</td></tr>
                                <tr><td>Customer Name</td><td><span className='text-danger'>Required</span> (Varchar 255)</td></tr>
                                <tr><td>Customer Address 1</td><td><span className='text-danger'>Required</span> (Varchar 255)</td></tr>
                                <tr><td>Customer Address 2</td><td>Not Required (Varchar 255)</td></tr>
                                <tr><td>Customer Phone</td><td><span className='text-danger'>Required</span> (10 Digits Number Only) </td> </tr>
                                <tr><td>Customer City</td><td><span className='text-danger'>Required</span> (Varchar 100)</td></tr>
                                <tr><td>Customer Zip</td><td><span className='text-danger'>Required</span> (6 Digits Numbers Only) </td></tr>
                                <tr><td>Customer State</td><td><span className='text-danger'>Required</span> (Varchar 100) </td></tr>
                                <tr><td>Customer Email</td><td>Not Required (Varchar 50)</td></tr>
                                <tr><td>Product Category</td><td><span className='text-danger'>Required</span> (Varchar 255)</td></tr>
                                <tr><td>Product Code</td><td><span className='text-danger'>Required</span> (Varchar 100)</td></tr>
                                <tr><td>Product Name</td><td><span className='text-danger'>Required</span> (Varchar 255)</td></tr>
                                <tr><td>Product Invoice Value</td><td><span className='text-danger'>Required</span> (Decimal 10,2) Float Value  </td></tr>
                                <tr><td>Product COD Value</td><td><span className='text-danger'>Required</span> (If Order is COD) (Decimal 10,2) Float Value</td></tr>
                                <tr><td>Product Qty</td><td><span className='text-danger'>Required</span> (INT) Integer</td></tr>
                                <tr><td>Product Length in cm</td><td><span className='text-danger'>Required</span> (Decimal 5,2) Float Value</td></tr>
                                <tr><td>Product Breadth in cm</td><td><span className='text-danger'>Required</span> (Decimal 5,2) Float Value</td></tr>
                                <tr><td>Product Height in cm</td><td><span className='text-danger'>Required</span>(Decimal 5,2) Float Value</td></tr>
                              </tbody>
                          </Table>
                        </div> 
                      </ModalBody>
                    </Modal>                          
                    <Modal isOpen={addCustom} toggle={addCustom} scrollable={true} >
                      <ModalHeader toggle={toggleCustom}>Wordpress Web Hook</ModalHeader>
                      <ModalBody>
                        <Form onSubmit={customSubmit(onCustomSubmit)}>
                          <Row className="mx-0 justify-content-center addModal">
                          <Col sm={12}>
                                <FormGroup>
                                  <Label for="brand">Choose Brand</Label>
                                  <div className="position-relative">
                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                    <select {...custom("brand_id", { required: 'Please Select Brand'})} className="form-control-sm form-control" id="brand" onChange={(e)=>getBrandcustom(e.target.value)}>
                                      <option value="">Choose Brand</option>
                                      {checkData(brandList.data) &&  brandList.data.map((item, index) => (
                                        <>
                                        <option value={item.id}>{item.brand_name}</option>
                                        </>
                                      ))}
                                    </select>
                                  </div>
                                  {errorscustom.brand && <span className="text-danger d-block error">{errorscustom.brand.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={12}>
                                <FormGroup>
                                  <Label for="custom_webhook">Custom Webhook</Label>
                                  <input type="text" {...custom("webhook_url", { required: 'Please Select Brand'})} className="form-control-sm form-control" value="" id="custom_webhook" readonly="true" />
                                </FormGroup>
                            </Col>
                            <Col sm={12} className="text-right">
                              <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                            </Col>
                          </Row>
                        </Form>  
                      </ModalBody>
                    </Modal>                          
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          <Card className="shadow border-0">
            <CardBody className="p-0">                  
                  {checkData(brandList) && checkData(brandList.data) && checkUndeNullBlank(brandList.TotalRows) ?
                  <div className="showing-count"><span class="small">Showing {currentCount} to {(currentCount-1)+(Object.entries(brandList.data).length)} of {brandList.TotalRows}</span></div>: ''}    
                  
                  <Table responsive className="text-left custom-table mb-0">
                    <thead>
                      <tr>
                        <th>Brand</th>
                        <th>Channel</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {checkData(intrigatedBrandList.data) ? intrigatedBrandList.data.map((item, index) => (
                      <tr>
                        <td>
                        <div className="d-inline-flex align-items-center">
                            <div className="list-icon mr-2">
                              <div className="avatar">
                                <div className="avatar-content">
                                {item.logo !== null && item.logo !== undefined ?
                                  <img className="img-fluid" src={process.env.REACT_APP_IMAGE_MERCHANT+item.logo} alt="logo" width="30" />
                                : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500"></span>}
                                </div>
                              </div>
                            </div>
                            <div className="list-content text-left">
                              <div className="font-weight-bold">
                                <p className="mb-0">{(item.brand_name)}</p>                             
                              </div>
                              <div>
                                <span className="fsc-2">{item.brand_website}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                        {item.shopify_webhook_status === 1 &&
                        <div className="d-inline-flex align-items-center">
                            <div className="list-icon mr-2">
                              <div className="avatar">
                                <div className="avatar-content">
                                {item.logo !== null && item.logo !== undefined ?
                                  <img className="img-fluid" src={shopifyIcon} alt="logo" width="32" />
                                : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500"></span>}
                                </div>
                              </div>
                            </div>
                            <div className="list-content text-left">
                              <div className="font-weight-bold">
                                <p className="mb-0">{'Shopify'}</p>                             
                              </div>
                              <div>
                                <span className="fsc-2">{`${process.env.REACT_APP_BASE_URL}/api/v1/channels/shopify/${item.id}/${item.merchant_id}`}</span>
                                <span onClick={() => copied(`${process.env.REACT_APP_BASE_URL}/api/v1/channels/shopify/${item.id}/${item.merchant_id}`)} className="ml-1 copy-btn" id={`copyspy${item.id}`}>
                                  <Copy />
                                  <UncontrolledTooltip placement="bottom" target={`copyspy${item.id}`}>
                                    Copy
                                  </UncontrolledTooltip>
                                </span>
                              </div>
                            </div>
                          </div>
                        }
                        {item.wordpress_webhook_status === 1 &&
                        <div className="d-inline-flex align-items-center">
                            <div className="list-icon mr-2">
                              <div className="avatar">
                                <div className="avatar-content">
                                {item.logo !== null && item.logo !== undefined ?
                                  <img className="img-fluid" src={wordpressIcon} alt="logo" width="32" />
                                : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500"></span>}
                                </div>
                              </div>
                            </div>
                            <div className="list-content text-left">
                              <div className="font-weight-bold">
                                <p className="mb-0">{'Word Press'}</p>                             
                              </div>
                              <div>
                                <span className="fsc-2">{`${process.env.REACT_APP_BASE_URL}/api/v1/channels/wordpress/${item.id}/${item.merchant_id}`}</span>
                                <span onClick={() => copied(`${process.env.REACT_APP_BASE_URL}/api/v1/channels/wordpress/${item.id}/${item.merchant_id}`)} className="ml-1 copy-btn" id={`copywprs${item.id}`}>
                                  <Copy />
                                  <UncontrolledTooltip placement="bottom" target={`copywprs${item.id}`}>
                                    Copy
                                  </UncontrolledTooltip>
                                </span>
                              </div>
                            </div>
                          </div>
                        }
                        {item.custom_webhook_status === 1 &&
                        <div className="d-inline-flex align-items-center">
                            <div className="list-icon mr-2">
                              <div className="avatar">
                                <div className="avatar-content">
                                {item.logo !== null && item.logo !== undefined ?
                                  <img className="img-fluid" src={customIcon} alt="logo" width="32" />
                                : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500"></span>}
                                </div>
                              </div>
                            </div>
                            <div className="list-content text-left">
                              <div className="font-weight-bold">
                                <p className="mb-0">{'Custom'}</p>                             
                              </div>
                              <div>
                                <span className="fsc-2">{`${process.env.REACT_APP_BASE_URL}/api/v1/channels/custom/${item.id}/${item.merchant_id}`}</span>
                                <span onClick={() => copied(`${process.env.REACT_APP_BASE_URL}/api/v1/channels/custom/${item.id}/${item.merchant_id}`)} className="ml-1 copy-btn" id={`copycstm${item.id}`}>
                                  <Copy />
                                  <UncontrolledTooltip placement="bottom" target={`copycstm${item.id}`}>
                                    Copy
                                  </UncontrolledTooltip>
                                </span>
                              </div>
                            </div>
                          </div>
                        }
                        </td>
                        <td><Badge color={item.status === 1 ? 'success' : 'danger'} pill>{item.status === 1 ? 'Active' : 'InActive'}</Badge></td>
                        <td>
                          <Status statusUrl={process.env.REACT_APP_MERCHANT_BRANDS} listId={item.id} valStatus={item.status} reListing={intrigatedBrandListing} data={{"merchant_id":props.user[0].id, "status": item.status === 1 ? 0 : 1, "brand_id" :item.id}} channelInteMsg="Channel" />
                          <Button className="d-none btn ctm-btn btn-sm mr-2 ml-1" color="primary" onClick={() => EditChannel(item)}>Edit</Button>
                          </td>
                        </tr> 
                      ))
                      : loadertable ? <tr><td colSpan="4"><LoaderDefault /></td> </tr>:
                      <tr><td colSpan="4"><NodataFound /></td> </tr> }
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
      </Col>
      
      </Row>
       </div>
  );
}

function mapDispatchToProps(state){
  return {
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(ChannelIntegration);