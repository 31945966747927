import React from 'react'

const NodataFound = () => {
    return (
        <div className="nodataImage py-3 text-center">
            <img src="/images/empty.svg" className="img-fluid w-100 h-auto" alt="No Data Found" /><br/>
            <h6 className="text-muted pt-2">No Data Found</h6>
        </div>
    )
}

export default NodataFound
 