import React, { useState } from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle, Input,FormGroup,Form, CardHeader, Table,Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlus} from '@fortawesome/free-solid-svg-icons';
import AddEmailTemplate from './AddEmailTemplate';
import EditEmailTemplate from './EditEmailTemplate';
import ViewEmailTemplate from './ViewEmailTemplate';
import { ChevronLeft, ChevronRight } from 'react-feather';

const EmailTemplate = (props) => {

    const [addEmailTemplate, setAddEmailTemplate] = useState(false);
    const [editEmailTemplate, setEditEmailTemplate] = useState(false);
    const [viewEmailTemplate, setViewEmailTemplate] = useState(false);
    const toggleAdd = () => setAddEmailTemplate(!addEmailTemplate);
    const toggleEdit = () => setEditEmailTemplate(!editEmailTemplate);
    const toggleView = () => setViewEmailTemplate(!viewEmailTemplate);

  return (
    <div className="app-content content overflow-hidden">
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 manageEmailContent shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
              <CardTitle className="page-title">Manage Email Content</CardTitle>
              <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                <div className="btn-text btn-light" onClick={toggleAdd}><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add Email Content</span>
                </div>
              </div>       
              <AddEmailTemplate isOpen={addEmailTemplate} toggle={toggleAdd}/>       
              <EditEmailTemplate isOpen={editEmailTemplate} toggle={toggleEdit}/>       
              <ViewEmailTemplate isOpen={viewEmailTemplate} toggle={toggleView}/>       
            </CardHeader>
                <Form className="cardFilter">
                  <Row className="mx-0 justify-content-start justify-content-md-center">
                    <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                        <input className="form-control-sm form-control" placeholder="Enter Title" />
                      </FormGroup>                      
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                        <input className="form-control-sm form-control" placeholder="Enter Subject" />
                      </FormGroup>                      
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <Input type="select" name="select"  className="form-control-sm">
                            <option>Select Status</option>
                            <option>Active</option>
                            <option>InActive</option>
                            </Input>
                        </FormGroup>
                    </Col>                    
                    <Col sm={3} className="btn-container">
                      <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline>Reset</Button>
                    </Col>
                  </Row>
                </Form>
              <CardBody className="p-0">
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Content Handler</th>
                      <th>Subject</th>
                      <th>Created</th>
                      <th>Updated</th>
                      <th>Actions</th>
                    </tr>
                   </thead>
                  <tbody>
                      <tr>
                      <td>Remittance Initiated</td>
                      <td>remittance_initiated</td>
                      <td>Remittance Initiated</td>
                      <td className="text-nowrap">12/16/20, 12:00 AM</td>
                      <td className="text-nowrap">12/16/20, 12:00 AM</td>
                      <td className="text-nowrap text-right">
                        <span><Button className="ctm-btn btn-sm ml-2" color="success" outline>Action</Button></span>
                        <span><Button className="ctm-btn btn-sm ml-2" color="info" outline onClick={toggleEdit}>Edit</Button></span>
                        <span><Button className="ctm-btn btn-sm ml-2" color="danger" outline>Delete</Button></span>
                        <span><Button className="ctm-btn btn-sm ml-2" color="primary" outline onClick={toggleView}>View</Button></span>
                      </td>
                    </tr>
                    </tbody>
                </Table>
                <Row className="m-0 table-footer">
                  <Col xs="6">
                    <Pagination>
                          <PaginationItem className="prev">
                            <PaginationLink previous to="#" className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                          </PaginationItem>
                          <PaginationItem className="next ml-3">
                            <PaginationLink next to="#" className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                          </PaginationItem>
                      </Pagination>
                    </Col>
                    <Col xs="6">                
                    <Form>
                      <FormGroup>
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <Input type="select" name="select" className="form-control-sm">
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              </Input>
                      </FormGroup>
                    </Form>
                    </Col>
                </Row>
              </CardBody>
            </Card>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

export default EmailTemplate;