import React, {useEffect, useState} from 'react';
import { Col, FormGroup, Table, Form, Button, Label,Spinner, Input} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import configData, { baseString } from '../config'
import { checkData, checkUndeNullBlank } from '../ObjectExist';
import { changeIntoDateTime, errorHandling} from '../utlis';

const PricingTable = (props) => {
    const [loader, setLoader] = useState(false);
    const ft = checkUndeNullBlank(props.fType) ? parseInt(props.fType) : 2
    useEffect(() => {
        let data = {}
        if(props.pricingList !== undefined && props.pricingList !== null && Object.entries(props.pricingList).length > 0){
        data = {
            frwd_additional_weight_in_kgs : props.pricingList.frwd_a.weight_in_kgs,frwd_additional_within_city : props.pricingList.frwd_a.within_city,frwd_additional_within_state : props.pricingList.frwd_a.within_state,
            frwd_additional_within_zone : props.pricingList.frwd_a.within_zone,frwd_additional_metro : props.pricingList.frwd_a.metro,frwd_additional_roi : props.pricingList.frwd_a.roi,
            frwd_additional_ex_location : props.pricingList.frwd_a.extended_location,frwd_additional_special_destination : props.pricingList.frwd_a.special_destination,
            frwd_basic_weight_in_kgs : props.pricingList.frwd_b.weight_in_kgs,frwd_basic_within_city : props.pricingList.frwd_b.within_city,frwd_basic_within_state : props.pricingList.frwd_b.within_state,
            frwd_basic_within_zone : props.pricingList.frwd_b.within_zone,frwd_basic_metro : props.pricingList.frwd_b.metro,frwd_basic_roi : props.pricingList.frwd_b.roi,
            frwd_basic_ex_location : props.pricingList.frwd_b.extended_location,frwd_basic_special_destination : props.pricingList.frwd_b.special_destination,frwd_basic_cod_charge : props.pricingList.frwd_b.cod_charge,frwd_basic_cod_percent : props.pricingList.frwd_b.cod_percent,
            rto_additional_weight_in_kgs : props.pricingList.rto_a.weight_in_kgs,rto_additional_within_city : props.pricingList.rto_a.within_city,rto_additional_within_state : props.pricingList.rto_a.within_state,
            rto_additional_within_zone : props.pricingList.rto_a.within_zone,rto_additional_metro : props.pricingList.rto_a.metro,rto_additional_roi : props.pricingList.rto_a.roi,
            rto_additional_ex_location : props.pricingList.rto_a.extended_location,rto_additional_special_destination : props.pricingList.rto_a.special_destination,
            rto_basic_weight_in_kgs : props.pricingList.rto_b.weight_in_kgs,rto_basic_within_city : props.pricingList.rto_b.within_city,rto_basic_within_state : props.pricingList.rto_b.within_state,
            rto_basic_within_zone : props.pricingList.rto_b.within_zone,rto_basic_metro : props.pricingList.rto_b.metro,rto_basic_roi : props.pricingList.rto_b.roi,
            rto_basic_ex_location : props.pricingList.rto_b.extended_location,rto_basic_special_destination : props.pricingList.rto_b.special_destination
        }
    }
    if(checkData(data)){
        reset(data)
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.pricingList])
    
    const sameAsAboveFrwdBasic = (event) => {
        if(event.target.checked === true){
        const {frwd_basic_weight_in_kgs,frwd_basic_within_city,frwd_basic_within_state,frwd_basic_within_zone,frwd_basic_metro,frwd_basic_roi,frwd_basic_ex_location,frwd_basic_special_destination}  = getValues();
        [ 
            { name: 'frwd_additional_weight_in_kgs', value: frwd_basic_weight_in_kgs },
            { name: 'frwd_additional_within_city', value: frwd_basic_within_city },
            { name: 'frwd_additional_within_state', value: frwd_basic_within_state },
            { name: 'frwd_additional_within_zone', value: frwd_basic_within_zone },
            { name: 'frwd_additional_metro', value: frwd_basic_metro },
            { name: 'frwd_additional_roi', value: frwd_basic_roi },
            { name: 'frwd_additional_ex_location', value: frwd_basic_ex_location },
            { name: 'frwd_additional_special_destination', value: frwd_basic_special_destination }
         ].forEach(({ name, value }) => setValue(name, value))
        }else{
            [ 
                { name: 'frwd_additional_weight_in_kgs', value: "" },
                { name: 'frwd_additional_within_city', value: "" },
                { name: 'frwd_additional_within_state', value: "" },
                { name: 'frwd_additional_within_zone', value: "" },
                { name: 'frwd_additional_metro', value: "" },
                { name: 'frwd_additional_roi', value: "" },
                { name: 'frwd_additional_ex_location', value: "" },
                { name: 'frwd_additional_special_destination', value: "" }
            ].forEach(({ name, value }) => setValue(name, value))
        }
    }
    const sameAsForward = (event) => {
        if(event.target.checked === true){
        const {frwd_basic_weight_in_kgs,frwd_basic_within_city,frwd_basic_within_state,frwd_basic_within_zone,frwd_basic_metro,frwd_basic_roi,frwd_basic_ex_location,frwd_basic_special_destination,frwd_additional_weight_in_kgs,frwd_additional_within_city,frwd_additional_within_state,frwd_additional_within_zone,frwd_additional_metro,frwd_additional_roi,frwd_additional_ex_location,frwd_additional_special_destination}  = getValues();
        [ 
            { name: 'rto_basic_weight_in_kgs', value: frwd_basic_weight_in_kgs },
            { name: 'rto_basic_within_city', value: frwd_basic_within_city },
            { name: 'rto_basic_within_state', value: frwd_basic_within_state },
            { name: 'rto_basic_within_zone', value: frwd_basic_within_zone },
            { name: 'rto_basic_metro', value: frwd_basic_metro },
            { name: 'rto_basic_roi', value: frwd_basic_roi },
            { name: 'rto_basic_ex_location', value: frwd_basic_ex_location },
            { name: 'rto_basic_special_destination', value: frwd_basic_special_destination },
            { name: 'rto_additional_weight_in_kgs', value: frwd_additional_weight_in_kgs },
            { name: 'rto_additional_within_city', value: frwd_additional_within_city },
            { name: 'rto_additional_within_state', value: frwd_additional_within_state },
            { name: 'rto_additional_within_zone', value: frwd_additional_within_zone },
            { name: 'rto_additional_metro', value: frwd_additional_metro },
            { name: 'rto_additional_roi', value: frwd_additional_roi },
            { name: 'rto_additional_ex_location', value: frwd_additional_ex_location },
            { name: 'rto_additional_special_destination', value: frwd_additional_special_destination },
         ].forEach(({ name, value }) => setValue(name, value))
        }else{
            [ 
                { name: 'rto_basic_weight_in_kgs', value: "" },
                { name: 'rto_basic_within_city', value: "" },
                { name: 'rto_basic_within_state', value: "" },
                { name: 'rto_basic_within_zone', value: "0" },
                { name: 'rto_basic_metro', value: "" },
                { name: 'rto_basic_roi', value: "" },
                { name: 'rto_basic_ex_location', value: "" },
                { name: 'rto_basic_special_destination', value: "" },
                { name: 'rto_additional_weight_in_kgs', value: "" },
                { name: 'rto_additional_within_city', value: "" },
                { name: 'rto_additional_within_state', value: "" },
                { name: 'rto_additional_within_zone', value: "0" },
                { name: 'rto_additional_metro', value: "" },
                { name: 'rto_additional_roi', value: "" },
                { name: 'rto_additional_ex_location', value: "" },
                { name: 'rto_additional_special_destination', value: "" },
             ].forEach(({ name, value }) => setValue(name, value))
        }
    }
    const sameAsAbovertoBasic = (event) => {
        if(event.target.checked === true){
        const {rto_basic_weight_in_kgs,rto_basic_within_city,rto_basic_within_state,rto_basic_within_zone,rto_basic_metro,rto_basic_roi,rto_basic_ex_location,rto_basic_special_destination}  = getValues();
        [ 
            { name: 'rto_additional_weight_in_kgs', value: rto_basic_weight_in_kgs },
            { name: 'rto_additional_within_city', value: rto_basic_within_city },
            { name: 'rto_additional_within_state', value: rto_basic_within_state },
            { name: 'rto_additional_within_zone', value: rto_basic_within_zone },
            { name: 'rto_additional_metro', value: rto_basic_metro },
            { name: 'rto_additional_roi', value: rto_basic_roi },
            { name: 'rto_additional_ex_location', value: rto_basic_ex_location },
            { name: 'rto_additional_special_destination', value: rto_basic_special_destination }
         ].forEach(({ name, value }) => setValue(name, value))
        }else{
            [ 
                { name: 'rto_additional_weight_in_kgs', value: "" },
                { name: 'rto_additional_within_city', value: "" },
                { name: 'rto_additional_within_state', value: "" },
                { name: 'rto_additional_within_zone', value: "0" },
                { name: 'rto_additional_metro', value: "" },
                { name: 'rto_additional_roi', value: "" },
                { name: 'rto_additional_ex_location', value: "" },
                { name: 'rto_additional_special_destination', value: "" }
             ].forEach(({ name, value }) => setValue(name, value))
        }
    }
    const { register:addPricing, handleSubmit:pricingSubmit, formState: { errors }, setValue, getValues, reset   } = useForm();
    const onAddPricingSubmit = (data) => {
    setLoader(true)
    if(props.merchantId !== undefined && props.merchantId !== '' && parseInt(props.merchantId)>0){
        data.merchant_id = props.merchantId;
    }
    data.courier_id=props.courierId;
    data.admin_id=checkData(props.user) && checkData(props.user[0]) && checkUndeNullBlank(props.user[0].id) ? props.user[0].id : '';
    axios({
        method: "post",
        url: props.priceApiUrl,
        data: data,
        headers: { "token": window.btoa(baseString) }
        })
        .then((res) => {
            setLoader(false);
            if(res.data.error === false){
            props.setAddmodal !== undefined && props.setAddmodal(false);
            props.couurierListing !== undefined && props.couurierListing();
            props.courierPriceing !== undefined && props.courierPriceing();
            Swal.fire({
            title: 'Success',
            text: res.data.msg,
            icon: 'success',
            })
        }
        }).catch((err) => {
            setLoader(false);
            errorHandling(err,"")
        });          
    };
 
 return (
     <>
    {loader && <div className="formLoader"><Spinner /></div>}
    <Form>
        <Table responsive className="text-center custom-table fixed-minw-100">
            <thead>
            <tr>
                <th className="text-nowrap">Direction</th>
                <th className="text-nowrap">Type</th>
                <th className="text-nowrap">Weight Slab</th>
                <th className="text-nowrap">Within <br /> City</th>
                <th className="text-nowrap">Within <br /> State</th>
                {/* <th className="text-nowrap">Within <br /> Zone</th> */}
                <th className="text-nowrap">Metro to <br /> Metro</th>
                <th className="text-nowrap">Rest of<br /> India</th>
                <th className="text-nowrap">Ext. Location</th>
                <th className="text-nowrap">Special<br />  Destination</th>
                <th className="text-nowrap">COD <br />Charges</th>
                <th className="text-nowrap">COD<br /> Charges %</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td rowSpan="2" className="align-middle font-weight-bold">Forward</td>
            <td>Basic</td>
            <td>
                <FormGroup className="position-relative mb-0">
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select type="select"  disabled={props.disabled === true} {...addPricing("frwd_basic_weight_in_kgs",{ required: 'Please Select Weight Slab', pattern:/^[+-]?\d+(\.\d+)?$/})} className="form-control-sm form-control" style={{ borderColor: errors.frwd_basic_weight_in_kgs ?'red' :''}}>
                        <option value="">Upto</option>
                        {Object.entries(configData.weight).map(([key,value]) => (
                              <option value={value}>{value}</option>
                              ))}
                        </select>
                </FormGroup>
                </td>
            <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_basic_within_city",{ required: 'Please Enter Price',min: { value: 1, message: "Please enter a value greater than or equal to 1."}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees" style={{ borderColor: errors.frwd_basic_within_city ?'red' :''}} />
                </FormGroup>
            </td>
            <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_basic_within_state",{ required: 'Please Enter Price',min: { value: 1, message: "Please enter a value greater than or equal to 1."}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_basic_within_state ?'red' :''}} />
                </FormGroup>
            </td>
            {/* <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_basic_within_zone",{ required: 'Please Enter Price', pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_basic_within_state ?'red' :''}} />
                </FormGroup>
            </td> */}
            <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_basic_metro",{ required: 'Please Enter Price',min: { value: 1, message: "Please enter a value greater than or equal to 1."}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_basic_metro ?'red' :''}} />
                </FormGroup>
            </td>
            <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_basic_roi",{ required: 'Please Enter Price',min: { value: 1, message: "Please enter a value greater than or equal to 1."}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_basic_roi ?'red' :''}} />
                </FormGroup>
            </td>
            <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_basic_ex_location",{ required: 'Please Enter Price',min: { value: 1, message: "Please enter a value greater than or equal to 1."}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_basic_ex_location ?'red' :''}} />
                </FormGroup>
            </td>
            <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_basic_special_destination",{ required: 'Please Enter Price',min: { value: 1, message: "Please enter a value greater than or equal to 1."}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_basic_special_destination ?'red' :''}} />
                </FormGroup>
            </td>
            <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_basic_cod_charge",{ required: 'Please Enter Price',min: { value: ft === 1 ? 0 : 1, message: "Please enter valid value"}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_basic_cod_charge ?'red' :''}} />
                </FormGroup>
            </td>
            <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_basic_cod_percent",{ required: 'Please Enter Price',min: { value: ft === 1 ? 0 : 1, message: "Please enter valid value"}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_basic_cod_percent ?'red' :''}} />
                </FormGroup>
            </td>
            </tr>
            <tr>
            <td>Additional</td>
            <td className="align-bottom">
            <FormGroup className="position-relative mb-0">
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select type="select"  disabled={props.disabled === true} {...addPricing("frwd_additional_weight_in_kgs",{ required: 'Please Select Weight Slab', pattern:/^[+-]?\d+(\.\d+)?$/})} className="form-control-sm form-control" style={{ borderColor: errors.frwd_additional_weight_in_kgs ?'red' :''}}>
                        <option value="">Upto</option>
                        {Object.entries(configData.weight).map(([key,value]) => (
                              <option value={value}>{value}</option>
                              ))}
                        </select>
                </FormGroup>
                </td>
            <td className="align-bottom">
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_additional_within_city",{ required: 'Please Enter Price',min: { value: 1, message: "Please enter a value greater than or equal to 1."}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_additional_within_city ?'red' :''}} />
                </FormGroup>
            </td>
            <td className="align-bottom">
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_additional_within_state",{ required: 'Please Enter Price',min: { value: 1, message: "Please enter a value greater than or equal to 1."}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_additional_within_state ?'red' :''}} />
                </FormGroup>
            </td>
            {/* <td className="align-bottom">
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_additional_within_zone",{ required: 'Please Enter Price', pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_additional_within_zone ?'red' :''}} />
                </FormGroup>
            </td> */}
            <td className="align-bottom">
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_additional_metro",{ required: 'Please Enter Price',min: { value: 1, message: "Please enter a value greater than or equal to 1."}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_additional_metro ?'red' :''}} />
                </FormGroup>
            </td>
            <td className="align-bottom">
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_additional_roi",{ required: 'Please Enter Price',min: { value: 1, message: "Please enter a value greater than or equal to 1."}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_additional_roi ?'red' :''}} />
                </FormGroup>
            </td>
            <td className="align-bottom">
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_additional_ex_location",{ required: 'Please Enter Price',min: { value: 1, message: "Please enter a value greater than or equal to 1."}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_additional_ex_location ?'red' :''}} />
                </FormGroup>
            </td>
            <td className="align-bottom">
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("frwd_additional_special_destination",{ required: 'Please Enter Price',min: { value: 1, message: "Please enter a value greater than or equal to 1."}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.frwd_additional_special_destination ?'red' :''}} />
                </FormGroup>
            </td>
            <td colSpan="2" className="align-bottom">
                <FormGroup check>
                    <Label check>
                    <Input type="checkbox" disabled={props.disabled === true} {...addPricing("same_as_frwd_basic")} onChange={ sameAsAboveFrwdBasic } style={{ borderColor: errors.same_as_frwd_basic ?'red' :''}} />
                    Same as Above
                    </Label>
                </FormGroup>
            </td>
            </tr>
            {(props.movement_type_id === 1 || props.movement_type_id === '1' || props.addnew === 1) &&
            <>
            <tr>
            <td rowSpan="2" className="align-middle font-weight-bold">RTO</td>
            <td>Basic</td>
            <td>
                <FormGroup className="position-relative mb-0">
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select type="select"  disabled={props.disabled === true} {...addPricing("rto_basic_weight_in_kgs",{ required: 'Please Select Weight Slab', pattern:/^[+-]?\d+(\.\d+)?$/})} className="form-control-sm form-control" style={{ borderColor: errors.rto_basic_weight_in_kgs ?'red' :''}}>
                        <option value="">Upto</option>
                        {Object.entries(configData.weight).map(([key,value]) => (
                              <option value={value}>{value}</option>
                              ))}
                        </select>
                </FormGroup>
                </td>
            <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("rto_basic_within_city",{ required: 'Please Enter Price',min: { value: ft === 1 ? 0 : 1, message: "Please enter valid value"}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees" style={{ borderColor: errors.rto_basic_within_city ?'red' :''}} />
                </FormGroup>
            </td>
            <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("rto_basic_within_state",{ required: 'Please Enter Price',min: { value: ft === 1 ? 0 : 1, message: "Please enter valid value"}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.rto_basic_within_state ?'red' :''}} />
                </FormGroup>
            </td>
            {/* <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("rto_basic_within_zone",{ required: 'Please Enter Price', pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.rto_basic_within_zone ?'red' :''}} />
                </FormGroup>
            </td> */}
            <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("rto_basic_metro",{ required: 'Please Enter Price',min: { value: ft === 1 ? 0 : 1, message: "Please enter valid value"}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.rto_basic_metro ?'red' :''}} />
                </FormGroup>
            </td>
            <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("rto_basic_roi",{ required: 'Please Enter Price',min: { value: ft === 1 ? 0 : 1, message: "Please enter valid value"}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.rto_basic_roi ?'red' :''}} />
                </FormGroup>
            </td>
            <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("rto_basic_ex_location",{ required: 'Please Enter Price',min: { value: ft === 1 ? 0 : 1, message: "Please enter valid value"}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.rto_basic_ex_location ?'red' :''}} />
                </FormGroup>
            </td>
            <td>
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("rto_basic_special_destination",{ required: 'Please Enter Price',min: { value: ft === 1 ? 0 : 1, message: "Please enter valid value"}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.rto_basic_special_destination ?'red' :''}} />
                </FormGroup>
            </td>
            <td colSpan="2" className="align-bottom">
                <FormGroup check>
                    <Label check>
                    <Input type="checkbox" disabled={props.disabled === true} {...addPricing("same_as_frwd")} onChange={ sameAsForward } />
                    Same as Forward
                    </Label>
                </FormGroup>
            </td>
            </tr>
            <tr>
            <td>Additional</td>
            <td className="align-bottom">
            <FormGroup className="position-relative mb-0">
                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select type="select"  disabled={props.disabled === true} {...addPricing("rto_additional_weight_in_kgs",{ required: 'Please Select Weight Slab', pattern:/^[+-]?\d+(\.\d+)?$/})} className="form-control-sm form-control" style={{ borderColor: errors.rto_additional_weight_in_kgs ?'red' :''}}>
                        <option value="">Upto</option>
                        {Object.entries(configData.weight).map(([key,value]) => (
                              <option value={value}>{value}</option>
                              ))}
                        </select>
                </FormGroup>
                </td>
            <td className="align-bottom">
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("rto_additional_within_city",{ required: 'Please Enter Price',min: { value: ft === 1 ? 0 : 1, message: "Please enter valid value"}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.rto_additional_within_city ?'red' :''}} />
                </FormGroup>
            </td>
            <td className="align-bottom">
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("rto_additional_within_state",{ required: 'Please Enter Price',min: { value: ft === 1 ? 0 : 1, message: "Please enter valid value"}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.rto_additional_within_state ?'red' :''}} />
                </FormGroup>
            </td>
            {/* <td className="align-bottom">
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("rto_additional_within_zone",{ required: 'Please Enter Price', pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.rto_additional_within_zone ?'red' :''}} />
                </FormGroup>
            </td> */}
            <td className="align-bottom">
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("rto_additional_metro",{ required: 'Please Enter Price',min: { value: ft === 1 ? 0 : 1, message: "Please enter valid value"}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.rto_additional_metro ?'red' :''}} />
                </FormGroup>
            </td>
            <td className="align-bottom">
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("rto_additional_roi",{ required: 'Please Enter Price',min: { value: ft === 1 ? 0 : 1, message: "Please enter valid value"}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.rto_additional_roi ?'red' :''}} />
                </FormGroup>
            </td>
            <td className="align-bottom">
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("rto_additional_ex_location",{ required: 'Please Enter Price',min: { value: ft === 1 ? 0 : 1, message: "Please enter valid value"}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.rto_additional_ex_location ?'red' :''}} />
                </FormGroup>
            </td>
            <td className="align-bottom">
                <FormGroup className="mb-0">
                <input disabled={props.disabled === true} {...addPricing("rto_additional_special_destination",{ required: 'Please Enter Price',min: { value: ft === 1 ? 0 : 1, message: "Please enter valid value"}, pattern:/^[+-]?\d+(\.\d+)?$/})}  className="form-control-sm form-control" placeholder="In Rupees"  style={{ borderColor: errors.rto_additional_special_destination ?'red' :''}} />
                </FormGroup>
            </td>
            <td colSpan="2" className="align-bottom">
                <FormGroup check>
                    <Label check>
                    <Input type="checkbox" disabled={props.disabled === true} {...addPricing("same_as_rto_basic")} onChange={ sameAsAbovertoBasic } />
                    Same as Above
                    </Label>
                </FormGroup>
            </td>
            </tr>
            </>
            }
            </tbody>
        </Table>
        {props.disabled !== true &&
        <Col sm={12} className="text-right">{checkData(props.pricingList) && checkUndeNullBlank(props.pricingList.frwd_a.updated_at) ? <span className="mr-2">Last Submitted On {changeIntoDateTime(props.pricingList.frwd_a.updated_at)}</span>:checkData(props.pricingList)&&checkUndeNullBlank(props.pricingList.frwd_a.created_at)&&<span className="mr-2">Last Submitted On {props.pricingList.frwd_a.created_at}</span>}
        <Button className="ctm-btn btn-sm search" color="primary" onClick={pricingSubmit(onAddPricingSubmit)}>Save Courier Pricing</Button>
        </Col>
        }
    </Form>
    </>
  );
}

export default PricingTable;