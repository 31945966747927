import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label, Spinner } from 'reactstrap';
import DatePicker from 'rsuite/DatePicker';
// import 'rsuite/dist/rsuite.css';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { errorHandling, remExtraSpace, changeIntoDate } from '../../utlis';
import { baseString, ledger_heads } from '../../config';
import { connect } from 'react-redux';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { ChevronLeft, ChevronRight } from 'react-feather';
import moment from 'moment';

const MerchantLedger = (props) => {
  const [merchantLedger, setMerchantLedger] = useState({});
  const [addmodal, setAddodal] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1)
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({});
  const [loader, setLoader] = useState(false);
  const [filterDate, setFilterDate] = useState(null);
  const [addTransDate, setAddTransDate] = useState(new Date());
  const [isDebitAmt, setIsDebitAmt] = useState(false);
  const [isCreditAmt, setIsCreditAmt] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const dropFilterCount = 20;

  const toggleAdd = () => setAddodal(!addmodal);
  const closeToggleAdd = () => {
    setAddodal(false);
    resetLedgerForm();
    setIsDebitAmt(false);
    setIsCreditAmt(false);
  }

  const toggleAmt = (e) => {
    if (e === "debit") {
      setIsDebitAmt(true);
      setIsCreditAmt(false);
    } else if (e === "credit") {
      setIsDebitAmt(false);
      setIsCreditAmt(true);
    } else {
      setIsDebitAmt(false);
      setIsCreditAmt(false);
    }
  }

  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
      }
      return true
    });
  }
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  useEffect(() => {
    merchantLedgerListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const { register: addLedger, handleSubmit: merchantLedgerSubmit, formState: { errors }, reset: resetLedgerForm } = useForm();
  const onAddMerchantLedger = (data) => {

    if (checkUndeNullBlank(addTransDate)) {
      setLoader(true);
      let sendData = {};
      sendData.admin_id = checkData(props.user[0]) ? props.user[0].id : '';
      sendData.merchant_id = data.merchant_id;
      sendData.ledger_head_id = data.ledger_head_id;
      sendData.ref_no = data.ref_no;
      sendData.trans_desc = data.trans_desc;
      sendData.trans_date = checkUndeNullBlank(addTransDate) ? moment(addTransDate).format('YYYY-MM-DD') : '';
      sendData.trans_type = data.trans_type;
      if (data.trans_type === "debit") {
        sendData.debit_amt = data.debit_amt;
      } else if (data.trans_type === "credit") {
        sendData.credit_amt = data.credit_amt;
      }

      axios({
        method: "post",
        url: process.env.REACT_APP_MERCHANT_LEDGER_CREATE,
        data: sendData,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false);
        merchantLedgerListing();
        if (res.data.error === false) {
          closeToggleAdd();
          Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', })
        }
      })
        .catch(function (err) {
          setLoader(false);
          errorHandling(err, "");
        });
    }
  };

  const { register: filter, handleSubmit: filterMerchantLedgerSubmit, reset: resetFilter } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };

  const onFilterMerchantLedger = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.ref_no) || checkData(merchants) || checkUndeNullBlank(filterDate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }
  };

  const merchantLedgerListing = () => {
    resetFilter();
    setMerchants([]);
    setFilterDate(null);

    let data = {}

    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setLoadertable(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_LEDGER_LIST}?page=${page}&per_page=${selectperpage}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false)
      if (res.data.error === false) {
        setMerchantLedger(res.data);
      } else {
        Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "reload");
    });
  }

  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(merchantLedger.data)
      if (currentCount > Object.entries(merchantLedger.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }

  function nextPageData() {
    if (checkData(merchantLedger.data) && checkUndeNullBlank(merchantLedger.totalPage)) {
      if (merchantLedger.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(merchantLedger.data).length)
        setPage(page + 1)
        setLoadertable(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoadertable(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }

  const onCommonFilter = (data, pagePerpage) => {
    setLoader(true)
    let byData = {}
    let merchantsId = [];
    let mId = checkUndeNullBlank(merchantsId) ? merchantsId : '';

    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }

    if (data === undefined) {
      data = filterInput
    }

    // let sDate = checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '';
    // let eDate = checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '';
    let transDate = checkUndeNullBlank(filterDate) ? moment(filterDate).format('YYYY-MM-DD') : '';

    byData = { "ref_no": checkUndeNullBlank(data.ref_no) ? remExtraSpace(data.ref_no) : '', "merchant_id": mId, "trans_date": transDate }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_LEDGER_LIST}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setMerchantLedger(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };


  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 merchantLedger shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="page-title">Merchant Ledger</CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  <div className="btn-text btn-light" onClick={toggleAdd}><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Create Entry</span></div>
                </div>
                <Modal isOpen={addmodal} toggle={addmodal} scrollable={true} size="lg">
                  <ModalHeader toggle={closeToggleAdd}>Add Merchant Ledger</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={merchantLedgerSubmit(onAddMerchantLedger)}>
                      <Row className="mx-0 justify-content-start addModal">
                        <Col sm={12} md={6}>
                          <FormGroup>
                            <Label for="selMerchant">Select Merchant<span className="text-danger">*</span></Label>
                            <div className="position-relative p-0">
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select {...addLedger("merchant_id", { required: 'Please Select Merchant' })} className="form-control-sm form-control" id="selMerchant">
                                <option value="">Select Merchant</option>
                                {checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants) &&
                                  props.state.shipmentMerchantCourier.merchants.map((item, index) => (
                                    <option value={item.merchant_id}>{item.merchantName}</option>
                                  ))
                                }
                              </select>
                            </div>
                            {errors.merchant_id && <span className="text-danger d-block error">{errors.merchant_id.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={6}>
                          <FormGroup>
                            <Label for="ledger_head_id">Select Heads<span className="text-danger">*</span></Label>
                            <div className="position-relative p-0">
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select {...addLedger("ledger_head_id", { required: 'Please Select Heads' })} className="form-control-sm form-control" id="ledger_head_id">
                                <option value="">Select Heads</option>
                                {Object.entries(ledger_heads).map(([key, value]) => (
                                  <option value={key}>{value}</option>
                                ))}
                              </select>
                            </div>
                            {errors.ledger_head_id && <span className="text-danger d-block error">{errors.ledger_head_id.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={6}>
                          <FormGroup>
                            <Label for="ref_no">Ref No.<span className="text-danger">*</span></Label>
                            <input {...addLedger("ref_no", { required: 'Please Enter Ref. No.' })} className="form-control-sm form-control" placeholder="Enter Ref No." id="ref_no" />
                            {errors.ref_no && <span className="text-danger d-block error">{errors.ref_no.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={6}>
                          <FormGroup>
                            <Label for="trans_desc">Narration<span className="text-danger">*</span></Label>
                            <input {...addLedger("trans_desc", { required: 'Please Enter Narration' })} className="form-control-sm form-control" placeholder="Enter Narration" id="trans_desc" />
                            {errors.trans_desc && <span className="text-danger d-block error">{errors.trans_desc.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={6}>
                          <FormGroup>
                            <Label for="trans_date">Transaction Date<span className="text-danger">*</span></Label>
                            <DatePicker defaultValue={new Date()} onChange={date => { setAddTransDate(date) }} placement="auto" format="dd-MM-yyyy" />
                            {addTransDate == null && <span className="text-danger d-block error">Please Select Date</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={6}>
                          <FormGroup>
                            <Label for="trans_type">Transaction Type<span className="text-danger">*</span></Label>
                            <div className="position-relative p-0">
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select {...addLedger("trans_type", { required: 'Please Select Transaction Type' })} onChange={(e) => { toggleAmt(e.target.value) }} className="form-control-sm form-control" id="trans_type">
                                <option value="">Select Transaction Type</option>
                                <option value="debit">Debit</option>
                                <option value="credit">Credit</option>
                              </select>
                            </div>
                            {errors.trans_type && <span className="text-danger d-block error">{errors.trans_type.message}</span>}
                          </FormGroup>
                        </Col>
                        {isDebitAmt &&
                          <Col sm={12} md={6}>
                            <FormGroup>
                              <Label for="debit_amt">Debit Amount<span className="text-danger">*</span></Label>
                              <input {...addLedger("debit_amt", { required: 'Please Enter Debit Amount' })} className="form-control-sm form-control" placeholder="Amount" id="debit_amt" />
                              {errors.debit_amt && <span className="text-danger d-block error">{errors.debit_amt.message}</span>}
                            </FormGroup>
                          </Col>}
                        {isCreditAmt &&
                          <Col sm={12} md={6}>
                            <FormGroup>
                              <Label for="credit_amt">Credit Amount<span className="text-danger">*</span></Label>
                              <input {...addLedger("credit_amt", { required: 'Please Enter Credit Amount' })} className="form-control-sm form-control" placeholder="Amount" id="credit_amt" />
                              {errors.credit_amt && <span className="text-danger d-block error">{errors.credit_amt.message}</span>}
                            </FormGroup>
                          </Col>}
                        <Col sm={12} className="text-right">
                          <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </CardHeader>
              <Form onSubmit={filterMerchantLedgerSubmit(onFilterMerchantLedger)} className="cardFilter">
                <Row className="mx-0 mb-3 mb-sm-0">
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <input {...filter("ref_no")} type="text" placeholder="Enter Ref. No" className="form-control-sm form-control" />
                    </FormGroup>
                  </Col>
                  {props.user[0].user_type === "Super" &&
                    <Col sm={6} md={3} lg={3} xl={2}>
                      <FormGroup>
                        <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={(e) => { setMerchants(e); }}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                          className={checkData(merchants) && "active"}
                        />
                      </FormGroup>
                    </Col>}
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DatePicker value={filterDate} onChange={setFilterDate} placeholder="Choose Tra. Date Range" placement="bottomEnd" format="dd-MM-yyyy" />
                      {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Tra. Date Range" placement="bottomEnd" format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} /> */}
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" onClick={merchantLedgerListing} outline>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(merchantLedger) && checkData(merchantLedger.data) && checkUndeNullBlank(merchantLedger.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(merchantLedger.data).length)} of {merchantLedger.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      {props.user[0].user_type === "Super" &&
                        <th className="text-nowrap">Merchant</th>}
                      <th className="text-nowrap">Head</th>
                      <th className="text-nowrap">Ref. No.</th>
                      <th className="text-nowrap">Trans. Date</th>
                      <th className="text-nowrap">Debit (Amt.)</th>
                      <th className="text-nowrap">Credit (Amt.)</th>
                      <th className="text-nowrap">Balance</th>
                      <th className="text-nowrap">Entry On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(merchantLedger.data) ? merchantLedger.data.map((item, index) => (
                      <tr>
                        {props.user[0].user_type === "Super" &&
                          <td>{item.merchantName}</td>}
                        <td>{ledger_heads[item.ledger_head_id]}</td>
                        <td>{item.ref_no}</td>
                        <td>{changeIntoDate(item.trans_date)}</td>
                        <td>{checkUndeNullBlank(item.debit_amt) ? item.debit_amt : 'NA'}</td>
                        <td>{checkUndeNullBlank(item.credit_amt) ? item.credit_amt : 'NA'}</td>
                        <td>{checkUndeNullBlank(item.balance) ? item.balance : 'NA'}</td>
                        <td>{changeIntoDate(item.created_at)}</td>
                      </tr>
                    )) :
                      loadertable ? <tr><td colSpan="10" className="text-center"><LoaderDefault /></td></tr>
                        : <tr><td colSpan="10" className="text-center"><NodataFound /></td></tr>}
                  </tbody>
                </Table>
                {checkData(merchantLedger) && checkData(merchantLedger.data) && checkUndeNullBlank(merchantLedger.TotalRows) ?
                  <>
                    {merchantLedger.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}

export default connect(mapDispatchToProps)(MerchantLedger);
