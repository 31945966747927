import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Badge, ListGroup, ListGroupItem, Progress, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faMapMarkerAlt, faCalendarAlt, faComment } from '@fortawesome/free-solid-svg-icons';
import { Bell, RefreshCcw } from 'react-feather';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { changeIntoDateTime, checkAdminStatus, daysCount, errorHandling } from '../../utlis';
import { baseString, order_status } from '../../config';
import axios from 'axios';
import Swal from 'sweetalert2';

const TrackOrder = (props) => {
  const [loader, setLoader] = useState(false);
  const handleClick = event => {
    props.closeTracker(event.target.value);
  }
  const onStatusRecheck = () => {
    let data = {}
    if (props.statusRecheckOrderId.length > 0) {
      // setLoader(true);
      if (props.statusRecheckOrderId.length > 0) {
        data.order_ids = props.statusRecheckOrderId
      }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_STATUS_RECHECK}`,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false)
        if (res.data.sqlMessage !== undefined) {
          Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
        }
        if (res.data.error === false) {
          Swal.fire({ icon: 'success', title: 'Success', text: res.data.msg });
          props.orderTrack !== undefined && props.orderTrack();
        } else {
          setLoader(false)
          Swal.fire({ icon: 'error', title: 'Error', text: res.data.msg })
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "")
      });
    } else {
      Swal.fire({ icon: 'warning', title: 'Warning', text: 'Please Select The Order First' })
    }
  }
  const shipStart = (checkData(props.orderTracking)) ? (checkData(props.orderTracking.booking_date) && props.orderTracking.booking_date) || (checkData(props.orderTracking.created_at) && props.orderTracking.created_at) : ''
  const shipEnd = checkData(props.orderTracking) && checkData(props.orderTracking.log) && (props.orderTracking.log[0].logistify_status_id === 10 || props.orderTracking.log[0].logistify_status_id === 13) ? (checkData(props.orderTracking.log)) && (checkData(props.orderTracking.log[0].courier_event_date_time)) && props.orderTracking.log[0].courier_event_date_time : new Date();
  const totalDays = daysCount(shipEnd, shipStart)
  return (
    <div className={`right-side-anim position-${props.position} overflow-auto ${props.toggleTrack}`}>
      {loader && <div className="formLoader"><Spinner /></div>}
      <Card className="border-0 main-card">
        <CardHeader tag="h5" className={`px-4 py-3 border-bottom bg-white ${props.toggleTrack}`}><span className="m-0">Track Order </span>{props.toggleTrack === "show" && <span className="btn custom-close" onClick={handleClick}><FontAwesomeIcon icon={faTimes} /></span>}</CardHeader>
        <CardBody className="px-4">
          <div className="card-content">
            <div className="pb-2 position-relative progress-header d-flex justify-content-between flex-wrap">
              <div className='border-right pr-3 mr-3' style={{ width: "135px" }}>
                <span className="fsc-2">Total Attempt</span><br />
                <span className="fsc-2">{checkData(props.orderTracking) && props.orderTracking.total_attempt} Attempt(s)</span>
              </div>
              <div>
                <div><span className="fsc-2">Order No. - </span><span className="fsc-2">{checkData(props.orderTracking) && props.orderTracking.order_no}</span></div>
                <div><span className="fsc-2">AWB - </span><span className="fsc-2">{checkData(props.orderTracking) && props.orderTracking.awb_no}</span></div>
              </div>
              {props.user[0].user_type === "Super" && <div><span className='refresh-tracking cursor-pointer ml-2' onClick={() => onStatusRecheck()}><RefreshCcw /> </span></div>}
            </div>
            <div className="d-flex border-primary p-2 mb-2 progress-area">
              <span className="p-3 icon">
                <Bell />
              </span>
              <div className="activity-progress flex-grow-1">
                <small className="d-inline-block mb-1 text-uppercase"> {checkUndeNullBlank(props.lastStatus) ? order_status[props.lastStatus] : ''}</small>
                <Badge className="float-right" color="primary" pill style={{ fontSize: "12px" }}>Within - {totalDays !== undefined ? totalDays : 'NA'} day <span style={{ fontSize: "9px" }}>[s]</span></Badge>
                <Progress color="primary" className="bg-white" value="50" style={{ height: ".4rem" }} />
              </div>
            </div>
            {checkData(props.orderTracking.log) ?
              <>
                <ListGroup className="widget-timeline border-0 mt-1">
                  {checkData(props.orderTracking) && checkData(props.orderTracking.log) ? props.orderTracking.log.map((item, index) => (
                    <ListGroupItem className={`${(item.logistify_status_id === 12) || (item.logistify_status_id === 13) ? 'timeline-icon-danger' : (item.logistify_status_id === 11) ? 'timeline-icon-warning border border-danger p-75' : (item.logistify_status_id === 10) ? 'timeline-icon-success' : 'timeline-icon-info'}`}>
                      <div className="d-flex flex-wrap align-items-start justify-content-between">
                        <h6 className="timeline-title mr-2">{checkAdminStatus(props.user[0].user_type, item.logistify_status_id, props.orderTracking.is_lost)}</h6>
                        <span className="text-nowrap"><span className="icon"><FontAwesomeIcon icon={faCalendarAlt} /></span>{changeIntoDateTime(item.courier_event_date_time)}</span>
                      </div>
                      <div className="timeline-content mt-2"><span className="icon"><FontAwesomeIcon icon={faMapMarkerAlt} /></span>{item.current_location}</div>
                      {item.logistify_status_id === 11 &&
                        <div className="timeline-content font-weight-bold mt-1"><span className="icon"><FontAwesomeIcon icon={faComment} /></span><span className='failed-track-msg'>{item.courier_msg}</span></div>
                      }
                    </ListGroupItem>
                  )) : ''}
                </ListGroup>
              </> :
              <h6 className="text-center mt-3">No Tracking Log Found</h6>}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default TrackOrder;

TrackOrder.defaultProps = {
  position: "relative",
  toggleTrack: ""
};