import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Label, CardTitle, UncontrolledTooltip, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, Modal, ModalHeader, ModalBody, PaginationLink, Spinner } from 'reactstrap';
import { DateRangePicker } from 'rsuite';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowUp, faArrowDown, faChevronRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { copyToClipBoard, firstDayOftheMonth, errorHandling, remExtraSpace, changeIntoDateTime } from '../../utlis';
import { Link } from 'react-router-dom';
import { Copy } from 'react-feather';
import { toast, ToastContainer } from 'react-toastify';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { connect, useDispatch } from 'react-redux'
import { CourierRemittanceList } from '../../actions';
import { ChevronLeft, ChevronRight } from 'react-feather';
import axios from 'axios';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import moment from 'moment';
import { baseString } from '../../config';

const CourierRemittance = (props) => {

  const [loadertable, setLoadertable] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [allCourier, setAllCourier] = useState([]);
  const [courierRemittance, setCourierRemittance] = useState({});
  const [filterDate, setFilterDate] = useState([firstDayOftheMonth(), new Date()]);
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), });
  const [loader, setLoader] = useState(false);
  const [excelmodal, setExcelmodal] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const dispatch = useDispatch();
  const dropFilterCount = 20;
  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    // remittanceListing();
    if (!excelmodal) { setExcelFile(""); }
  }
  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef = dateRange => dateRangeRef = dateRange;
  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0]);
  }
  const opt_merchants = [
    { label: "Ecommerce Store", value: "Ecommerce Store" },
    { label: "AA Interprises", value: "AA Interprises" },
    { label: "Abhipriya", value: "Abhipriya" },
    { label: "Ashok Lighting", value: "Ashok Lighting" },
  ];
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  const allCourierMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Couriers"
  }
  const opt_allCourier = [
    { label: "Fedex (Express - 0.5KG)", value: "Fedex (Express - 0.5KG)" },
  ];
  useEffect(() => {
    if (checkData(props.state) && checkData(props.state.courierRemittance)) {
      setCourierRemittance(props.state.courierRemittance);
    } else {
      remittanceListing();
    }
    // eslint-disable-next-line
  }, [])
  const { register: uploadRemittance, handleSubmit: courierUploadSubmit, formState: { errors } } = useForm();
  const onInitRemittSubmit = (data) => {
    let formData = new FormData();
    formData.append("file", excelFile);
    formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_COURIER_REMITTANCE}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      let errorFile = '';
      if (res.data.errorfile !== undefined) {
        errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
      }
      if (res.data.error === false) {
        Swal.fire({
          title: 'Success',
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
          text: res.data.msg,
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            setExcelmodal(false);
            remittanceListing();
          }
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: res.data.msg,
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            setExcelmodal(false);
            remittanceListing();
          }
        });
      }
    })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  }
  // remittance Listing 
  const remittanceListing = () => {
    setFilterDate([firstDayOftheMonth(), new Date()]);
    let data = { "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), "end_date": moment().format('YYYY-MM-DD') }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }

    reset(); setMerchants([]); setAllCourier([]);
    setLoadertable(true);
    setFilterInput({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), })
    setCurrentCount(1)
    setPage(1)
    let mypost = data
    let myget = { "page": page, "per_page": selectperpage }
    dispatch(CourierRemittanceList(mypost, myget)).then((response) => {
      // setMerchant(response);
      setCourierRemittance(response);
      setLoadertable(false)
    }).catch(function (response) {
      setLoadertable(false);
      errorHandling(response, "reload")
    });
  }

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(courierRemittance.data) && checkUndeNullBlank(courierRemittance.totalPage)) {
      if (courierRemittance.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(courierRemittance.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(courierRemittance.data)
      if (currentCount > Object.entries(courierRemittance.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register: filter, handleSubmit: filtermerchRemitSubmit, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onFilterMerchRemit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.courier_txn_id) || checkData(merchants) || checkData(allCourier) || checkUndeNullBlank(data.awb_no) || checkData(filterDate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }

  };
  const onCommonFilter = (data, pagePerpage) => {
    setLoader(true)
    let byData = {}
    let courierId = [];
    let merchantsId = [];
    // let tmpallCourier = [];
    // allCourier.map((item) => (
    //   tmpallCourier.push(item.value)      
    // ));

    if (checkData(allCourier)) {
      allCourier.map((item) => (
        courierId.push(item.value)
      ));
    }
    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }

    byData = { "courier_txn_id": checkUndeNullBlank(data.courier_txn_id) ? data.courier_txn_id : '', "awb_no": checkUndeNullBlank(data.awb_no) ? remExtraSpace(data.awb_no) : '', "courier_id": checkUndeNullBlank(courierId) ? courierId : '', "merchant_ids": checkUndeNullBlank(merchantsId) ? merchantsId : '', "start_date": checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '', "end_date": checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '' }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = 1
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_COURIER_REMITTANCE_LIST}?page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setCourierRemittance(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "")
      setLoader(false);
    });
  };
  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 CourierRemittance shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="mb-0 page-title">Manage Courier Remittance</CardTitle>
                <div className="card-header-right d-flex align-items-center ml-auto">
                  <div className="btn-text btn-light mx-2"><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Data</span></div>
                  <div className="btn-text btn-light" onClick={excelUpload}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Uploads Courier Remittance</span></div>
                </div>
                <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                  <ModalHeader toggle={excelUpload}>Upload Courier Remittance</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={courierUploadSubmit(onInitRemittSubmit)}>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Courier Remittance via XLSX</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                      <Row className="m-0 pb-1">
                        <Col sm={12} className="p-0">
                          <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/courier_remittance.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download
                          </Link>
                            <span className="ml-2">Upload Courier Remittance Format File</span></p>
                        </Col>
                        <Col sm={12} className="m-auto pt-1 p-0">
                          <FormGroup>
                            <input {...uploadRemittance("excelUpload", {
                              required: 'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                    files[0]?.type
                                  ) || 'Only .xslx file allowed',
                              },
                            })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                            <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                            {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="btn ctm-btn btn-sm" color="primary">Upload</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </CardHeader>
              <Form onSubmit={filtermerchRemitSubmit(onFilterMerchRemit)} className="cardFilter">
                <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-start">
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <input {...filter("courier_txn_id")} className="form-control-sm form-control" placeholder="Enter Courier Txn Id" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <input {...filter("awb_no")} className="form-control-sm form-control" placeholder="Enter AWB No" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2} className="d-none">
                    <FormGroup>
                      <MultiSelect
                        options={opt_allCourier}
                        value={allCourier}
                        onChange={setAllCourier}
                        labelledBy="All Couriers"
                        overrideStrings={allCourierMultiSelectSettings}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2} className="d-none">
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={setMerchants}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Courier Trans. Date" placement="bottomEnd" format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} ref={dateRangeRef} change={onDateChange} />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={remittanceListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(courierRemittance) && checkData(courierRemittance.data) && checkUndeNullBlank(courierRemittance.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(courierRemittance.data).length)} of {courierRemittance.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th>Txn Ref. NO</th>
                      <th>AWB No</th>
                      <th>COD Amount</th>
                      <th>Trans. Date</th>
                      <th>Courier</th>
                      <th>Remittance Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(courierRemittance.data) ? courierRemittance.data.map((item, index) => {
                      let ref_no = checkUndeNullBlank(item.courier_txn_id) ? item.courier_txn_id : 'NA'
                      let awb_no = checkUndeNullBlank(item.awb_no) ? item.awb_no : 'NA'
                      let cod_amount = checkUndeNullBlank(item.courier_cod_amt) ? item.courier_cod_amt : 0
                      let trans_date = checkUndeNullBlank(item.courier_trans_date) ? changeIntoDateTime(item.courier_trans_date) : 'NA'
                      let courier = checkUndeNullBlank(item.CourierName) ? item.CourierName : 'NA'
                      let remittance_date = checkUndeNullBlank(item.created_at) ? changeIntoDateTime(item.created_at) : 'NA'
                      return (
                        <tr>
                          <td>{ref_no}</td>
                          <td>
                            <div><span>{awb_no}</span>
                              <span onClick={() => copied(awb_no)} className="ml-25 copy-btn" id={`copy${item.id}`}>
                                <Copy />
                                <UncontrolledTooltip placement="bottom" target={`copy${item.id}`}>
                                  Copy
                                </UncontrolledTooltip>
                              </span>
                            </div>
                          </td>
                          <td>{cod_amount}</td>
                          <td>{trans_date}</td>
                          <td>{courier}</td>
                          <td>{remittance_date}</td>
                        </tr>
                      )
                    }) : loadertable ? <tr><td colSpan="6"><LoaderDefault /></td></tr> :
                      <tr><td colSpan="6"><NodataFound /></td></tr>}

                  </tbody>
                </Table>
                <ToastContainer style={{ width: "200px" }} />
                {checkData(courierRemittance) && checkData(courierRemittance.data) && checkUndeNullBlank(courierRemittance.TotalRows) ?
                  <>
                    {courierRemittance.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}
function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(CourierRemittance);