import React, {useEffect, useState} from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Spinner} from 'reactstrap';
import {useParams} from 'react-router-dom'
import { ArrowLeft } from 'react-feather';
import {Link} from 'react-router-dom';
import axios from 'axios';
import PricingTable from '../PricingTable';
import { checkData } from '../../ObjectExist';
import { errorHandling } from "../../utlis";
import { baseString } from '../../config';

const CourierPricing = (props) => {
  let courierName = (new URLSearchParams(window.location.search)).get("name")
  let movementId = (new URLSearchParams(window.location.search)).get("type")
  const [priceData, setPriceData] = useState()
  const {courierId} = useParams();
  const [loader, setLoader] = useState(false);
  // const {courierId} = useParams();
 useEffect (() =>{
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_GET_COURIER_PRICING}`,
      data: {"courier_id":courierId,"admin_id":checkData(props.user[0]) ? props.user[0].id:''},
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
    setLoader(false);
    if(response.data.fatal !== true){
          setPriceData(response.data)
        }
      })
      .catch(function (err) {
    setLoader(false);
    errorHandling(err,"")
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
 },[courierId])

 return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 courierPricing shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
              <CardTitle className="mb-0 page-title pb-2 font-weight-bold border-short">Add {courierName} Pricing</CardTitle>
              <div className="card-header-right d-flex align-items-center ml-auto">
                <Link to='/courier' className="btn-text btn-light mx-1"><span className="icon"><ArrowLeft /></span><span>Back to Courier list</span></Link>
              </div>
              </CardHeader>
               <CardBody className="px-0">
                <PricingTable movement_type_id={movementId} pricingList={priceData} priceApiUrl={process.env.REACT_APP_GET_COURIER_PRICING_UPDATE} courierId={courierId} user={props.user} />
                </CardBody>
            </Card>
        </Card>
      </Col>
      </Row>
       </div>
  );
}

export default CourierPricing;