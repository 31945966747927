import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, CardTitle, Form, Spinner, Button, Label } from 'reactstrap';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { errorHandling } from '../../../utlis';
import { baseString } from '../../../config';

const FilterTags = (props) => {
  const [loader, setLoader] = useState(false);
  const { register: tag, handleSubmit: handleTag, formState: { errors }, reset, setValue } = useForm();

  useEffect(() => {
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_SETTING}/view`,
      data: { "setting_id": '3', "merchant_id": props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '', },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        setLoader(false);
        if (response.data.length > 0) {
          let myresponse = response.data[0];
          reset(myresponse);
        }
      })
      .catch(function (err) {
        setLoader(false);
        errorHandling(err, "")
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  const submitTag = (data) => {
    props.setLoader(true);
    let formData = new FormData();
    formData.append("merchant_id", props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id : '');
    formData.append("setting_value", data.setting_value);
    formData.append("setting_id", '3');
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_SETTING}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      props.setLoader(false);
      if (res.data.error === false) {
        Swal.fire({
          title: 'Success',
          text: res.data.msg,
          icon: 'success',
        })
      }
    })
      .catch((err) => {
        props.setLoader(false);
        errorHandling(err, "")
      });
  };


  const seperateTag = (e) => {
    var text = e.target.value;
    var lastData = text.slice(-1);
    var inputData = text;
    var newText = "";
    var listData = "";
    listData = text.replace(/\n|\r/g, "");
    listData = inputData.split(/,+/);

    if (lastData === ",") {
      newText = listData.toString();
      setValue("setting_value", newText);
    }
  }

  return (
    <>
      {loader && <div className="formLoader"><Spinner /></div>}
      <Form onSubmit={handleTag(submitTag)}>
        <div className="sub-page-header">
          <CardTitle tag="h4" className="sub-page-title">Filter Tags</CardTitle>
        </div>
        <Row>
          <Col sm={12}>
            <FormGroup>
              <Label for="tag">Enter Your Tags<span className="text-danger">*</span></Label>
              <textarea {...tag("setting_value", { required: 'Please Enter Tag Seperated' })} rows={15} onChange={e => seperateTag(e)} className="form-control-sm form-control" placeholder="Please Enter Your Tag" id="tag" />
              {errors.setting_value && <span className="text-danger d-block error">{errors.setting_value.message}</span>}
              <span className="small">Please Enter Comma Seperated Tag</span>
            </FormGroup>
          </Col>
          <Col sm={12} className="mt-2 text-right">
            <Button className="btn-sm ctm-btn" color="primary"> Save </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default FilterTags;