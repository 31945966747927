import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { order_ndrs } from '../../../../config';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';

const NDRSummary = (props) => {
    let totalNdr = 0
    let totalactionTaken = 0
    let ndrSUmmary = checkData(props.cardsOverView) && checkData(props.cardsOverView.top_ndr) ? props.cardsOverView.top_ndr.map((item, index) => {
        let total = checkUndeNullBlank(item.total) ? item.total : 0;
        let actionTaken = checkUndeNullBlank(item.TotalAction) ? item.TotalAction : 0;
        totalNdr += parseInt(total);
        totalactionTaken += parseInt(actionTaken);
        let pending = total-actionTaken;
        return(
            <tr>
                <td>{order_ndrs[item.ndr_status_id]}</td>
                <td className="text-nowrap">{total}</td>
                <td className="text-nowrap">{actionTaken}</td>
                <td className="text-nowrap">{pending}</td>
            </tr>
        )
        }):''
    return (
        <div className="NDRSummary">
            <Card className="shadow border-0">
                <div className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <div className="page-title">NDR Summary</div>  
                </div>
                <CardBody className="p-0">
                    <div className="fixHeader-table">
                        <Table responsive className="text-center custom-table mb-0">
                        <thead>
                            <tr>
                                <th className="text-nowrap">Reasons</th>
                                <th className="text-nowrap">Total</th>
                                <th className="text-nowrap">Action Taken</th>
                                <th className="text-nowrap">Action Pending</th>
                            </tr>
                            <tr>
                                <th className="text-nowrap">Total</th>
                                <th className="text-nowrap">{totalNdr}</th>
                                <th className="text-nowrap">{totalactionTaken}</th>
                                <th className="text-nowrap">{parseInt(totalNdr) - parseInt(totalactionTaken)}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {checkData(props.cardsOverView) && checkData(props.cardsOverView.top_ndr) ? props.cardsOverView.top_ndr.map((item, index) => {
                                let total = checkUndeNullBlank(item.total) ? item.total : 0;
                                let actionTaken = checkUndeNullBlank(item.TotalAction) ? item.TotalAction : 0;
                                let pending = total-actionTaken;
                                return(
                                    <tr>
                                        <td>{order_ndrs[item.ndr_status_id]}</td>
                                        <td className="text-nowrap">{total}</td>
                                        <td className="text-nowrap">{actionTaken}</td>
                                        <td className="text-nowrap">{pending}</td>
                                    </tr>
                                )
                                }):''
                            }  */}
                            {ndrSUmmary} 
                        </tbody>
                    </Table>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}

export default NDRSummary;