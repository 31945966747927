import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Table, Spinner } from 'reactstrap';
import { ArrowLeft, Download } from 'react-feather';
import { useHistory, useParams } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import axios from 'axios';
import Swal from 'sweetalert2';
import { errorHandling, changeIntoDate } from '../../utlis';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { ToWords } from 'to-words';
import { baseString, download_delay } from '../../config';
const MerchantDebitNoteDatail = (props) => {
  const [debitNoteDetail, setDebitNoteDetail] = useState({});
  const [loader, setLoader] = useState(false);
  const { debitNoteId } = useParams();
  const toWords = new ToWords();
  useEffect(() => {
    viewDebitNoteDetail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debitNoteId])
  const viewDebitNoteDetail = () => {
    setLoader(true);
    let data = { "debit_note_id": debitNoteId }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_DEBIT_NOTE_DETAIL,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          setDebitNoteDetail(res.data.data);
        }
      }
    })
      .catch((err) => {
        setLoader(false)
        errorHandling(err, "reload");
      });
  }


  let history = useHistory();
  const goToPreviousPath = () => {
    history.goBack()
  }
  const exportDebitNotePDF = () => {
    let data = { "debit_note_id": debitNoteId }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setLoader(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_DEBIT_NOTE_DOWNLOAD_PDF,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
        if (parseInt(res.data.errno) > 0) {
          setLoader(false);
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.data.error === false) {
            setTimeout(function () {
              setLoader(false);
              history.push("/my-downloads");
            }, download_delay);
          }
        }
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err, "");
    });
  }
  const exportDebitExcel = () => {
    let data = {}
    data.credit_note_id = checkUndeNullBlank(debitNoteId) ? debitNoteId : '';

    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setLoader(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_CREDIT_NOTE_DOWNLOAD,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
        if (parseInt(res.data.errno) > 0) {
          setLoader(false);
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.data.error === false) {
            setTimeout(function () {
              setLoader(false);
              history.push("/my-downloads");
            }, download_delay);
          }
        }
      }
    }).catch((err) => {
      setLoader(false);
      errorHandling(err, "")
    });
  }

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 debitNoteDetail shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                <CardTitle className="mb-0 page-title">Debit Note Details</CardTitle>
                <div className="card-header-right d-flex align-items-center ml-auto">
                  <div onClick={exportDebitNotePDF} className="btn-text btn-light"><span className="icon"><Download /></span><span>Download PDF</span></div>
                  <div onClick={exportDebitExcel} className="btn-text btn-light d-none"><span className="icon"><Download /></span><span>Download Excel</span></div>
                  <div onClick={goToPreviousPath} className="btn-text btn-light d-none"><span className="icon"><ArrowLeft /></span><span>Back to Debit Note</span></div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                {checkData(debitNoteDetail.invoice_data) ?
                  <div className='debitNoteDtl-table-con'>
                    <Table className='debit-table custom-table mb-0 border-bottom-0' bordered>
                      <thead>
                        <tr>
                          <th colSpan={3} className="position-relative">
                            <div className="logo-wrapper position-absolute">
                              <img src={Logo} alt="logo" style={{ maxWidth: '150px' }} />
                            </div>
                            <h3 className="text-center mb-0">Debit Note</h3>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td rowSpan={3} className="col-4">
                            <div>
                              <div className="ivc_box">
                                {/* <span className="ivc_ttl d-block">KERYX RETAIL SOLUTIONS PVT LTD</span> */}
                                <span className="ivc_ttl d-block">{checkData(debitNoteDetail.company) && debitNoteDetail.company.name}</span>
                                {/* <span className="ivc-text">K-81 B, Basement, Kalkaji New Delhi-110019</span> */}
                                <span className="ivc-text">{checkData(debitNoteDetail.company) && debitNoteDetail.company.address}</span>
                              </div>
                              <div className="ivc_box">
                                <span className="ivc_ttl mr-1">GSTIN/UIN : </span>
                                {/* <span className="ivc-text">07AAGCK7373K1Z1</span> */}
                                <span className="ivc-text">{checkData(debitNoteDetail.company) && debitNoteDetail.company.GSTIN}</span>
                              </div>
                              <div className="ivc_box">
                                <span className="ivc_ttl mr-1">State Name : </span>
                                {/* <span className="ivc-text">Delhi,</span>*/}
                                <span className="ivc-text">{checkData(debitNoteDetail.company) && debitNoteDetail.company.state},</span>
                                <span className="ivc_ttl mr-1">CODE : </span>
                                {/* <span className="ivc-text">07</span> */}
                                <span className="ivc-text">{checkData(debitNoteDetail.company) && debitNoteDetail.company.code}</span>
                              </div>
                              <div className="ivc_box">
                                <span className="ivc_ttl mr-1">PAN No. : </span>
                                {/* <span className="ivc-text">AAGCK7373K</span> */}
                                <span className="ivc-text">{checkData(debitNoteDetail.company) && debitNoteDetail.company.pan}</span>
                              </div>
                              <div className="ivc_box">
                                <span className="ivc_ttl mr-1">CIN : </span>
                                {/* <span className="ivc-text">U74999DL2017PTC322719</span>  */}
                                <span className="ivc-text">{checkData(debitNoteDetail.company) && debitNoteDetail.company.CIN}</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block">Debit Note No.</span>
                              <span className="ivc-text">DN/{debitNoteDetail.invoice_data[0].debit_note_no}</span>
                            </div>
                          </td>
                          <td>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block">Debit Note Date</span>
                              <span className="ivc-text">{changeIntoDate(debitNoteDetail.invoice_data[0].created_at)}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block">Reverse Charge</span>
                              <span className="ivc-text">N</span>
                            </div>
                          </td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block">Mode of Payment</span>
                              <span className="ivc-text">{checkData(debitNoteDetail.invoice) && debitNoteDetail.invoice.mode}</span>
                            </div>
                          </td>
                          <td>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block">Payment Terms</span>
                              <span className="ivc-text">{checkData(debitNoteDetail.invoice) && debitNoteDetail.invoice.payment_terms}</span>
                            </div>
                          </td>
                        </tr>
                        <tr className='bg-light'>
                          <th><div>Details of Receiver (Billed to)</div></th>
                          <th colSpan={2}><div>Details of Consignee (Shipped to)</div></th>
                        </tr>
                        <tr>
                          <td>
                            <div style={{ minHeight: "100px" }}>
                              <span className="ivc_ttl d-block">{debitNoteDetail.invoice_data[0].company_name}</span>
                              <span className="ivc-text">{debitNoteDetail.invoice_data[0].contact_number}</span><br />
                              <span className="ivc-text">{debitNoteDetail.invoice_data[0].address}</span><br />
                              <span className="ivc-text">{debitNoteDetail.invoice_data[0].city}</span>,
                              <span className="ivc-text">{checkUndeNullBlank(debitNoteDetail.state_name) && debitNoteDetail.state_name}</span>,
                              <span className="ivc-text">{debitNoteDetail.invoice_data[0].pincode}</span><br />
                              <span className="ivc_ttl mr-1">GSTIN/UIN : </span> <span className="ivc-text">{checkData(debitNoteDetail.invoice_data) && debitNoteDetail.invoice_data[0].gst_number}</span><br />
                              <span className="ivc_ttl mr-1">PAN No. : </span> <span className="ivc-text">{checkData(debitNoteDetail.invoice_data) && debitNoteDetail.invoice_data[0].pan_card_no}</span>
                            </div>

                          </td>
                          <td colSpan={2}>
                            <div style={{ minHeight: "100px" }}>
                              <span className="ivc_ttl d-block">{debitNoteDetail.invoice_data[0].company_name}</span>
                              <span className="ivc-text">{debitNoteDetail.invoice_data[0].contact_number}</span><br />
                              <span className="ivc-text">{debitNoteDetail.invoice_data[0].address}</span><br />
                              <span className="ivc-text">{debitNoteDetail.invoice_data[0].city}</span>,
                              <span className="ivc-text">{checkUndeNullBlank(debitNoteDetail.state_name) && debitNoteDetail.state_name}</span>,
                              <span className="ivc-text">{debitNoteDetail.invoice_data[0].pincode}</span><br />
                              <span className="ivc_ttl mr-1">GSTIN/UIN : </span> <span className="ivc-text">{checkData(debitNoteDetail.invoice_data) && debitNoteDetail.invoice_data[0].gst_number}</span><br />
                              <span className="ivc_ttl mr-1">PAN No. : </span> <span className="ivc-text">{checkData(debitNoteDetail.invoice_data) && debitNoteDetail.invoice_data[0].pan_card_no}</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Table className='debit-table invoice-table-min custom-table align-top mb-0' bordered>
                      <thead>
                        <tr className='bg-light text-center'>
                          <th className='col-1 text-center text-center'>S No.</th>
                          <th className='col-4 text-center'>Particulars</th>
                          <th className='col-2 text-center'></th>
                          <th className='col-3 text-center'>Amount (₹)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center">
                            <div>1</div>
                          </td>
                          <td className="text-center">
                            <div>{checkUndeNullBlank(debitNoteDetail.invoice_data) && debitNoteDetail.invoice_data[0].note_detail}</div>
                          </td>
                          <td></td>
                          <td className="text-center">
                            {checkUndeNullBlank(debitNoteDetail.invoice_data) && debitNoteDetail.invoice_data[0].amount}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr className='bg-light'>
                          <th colSpan={3} className="text-center">Total Debit Note Value (INR)</th>
                          <th className="text-center">{checkUndeNullBlank(debitNoteDetail.invoice_data) && debitNoteDetail.invoice_data[0].amount}</th>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block">Amount Chargeable (in words) </span>
                              <span className="ivc-text">{checkUndeNullBlank(debitNoteDetail.invoice_data) && toWords.convert(debitNoteDetail.invoice_data[0].amount, { currency: true, ignoreZeroCurrency: true })} /-</span>
                            </div>
                          </td>
                          <td className="text-right">E. & E.O</td>
                        </tr>
                      </tfoot>
                    </Table>
                    <Table className='debit-table custom-table align-top' bordered>
                      <tbody>
                        <tr className='bg-light'>
                          <td className='col-6'>
                            <div className="ivc_box">
                              <span className="ivc_ttl">Declaration : </span>
                            </div>
                          </td>
                          <td className='col-6'>
                            <div className="ivc_box">
                              <span className="ivc_ttl">Company Bank Details : </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2}>
                            <div className="ivc_box">
                              <div>
                                <span className="ivc-text d-block">1. We declare that this debit note shows the actual price of service described and that all particulars are true and correct.</span>
                                <span className="ivc-text d-block">2. Any discrepancy found in the debit note must be raised within 7 working days of receipt of this debit note and subject to realisation of balance undisputed amount .</span>
                                <span className="ivc-text d-block">3. This is computer generated debit note and does not require any stamp or signature</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-inline-block" style={{ minWidth: '120px' }}>Bank Name : </span>
                              {/* <span className="ivc-text">Induslnd Bank</span>*/}
                              <span className="ivc-text">{checkData(debitNoteDetail.bank_details) && debitNoteDetail.bank_details.name}</span>
                            </div>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-inline-block" style={{ minWidth: '120px' }}>A/c No. : </span>
                              {/* <span className="ivc-text">201001656627</span>*/}
                              <span className="ivc-text">{checkData(debitNoteDetail.bank_details) && debitNoteDetail.bank_details.account_no}</span>
                            </div>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-inline-block" style={{ minWidth: '120px' }}>Branch & IFSC : </span>
                              {/* <span className="ivc-text">Mohan Co-Operate</span>  */}
                              <span className="ivc-text">{checkData(debitNoteDetail.bank_details) && debitNoteDetail.bank_details.branch}</span>
                            </div>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-inline-block" style={{ minWidth: '120px' }}>Code : </span>
                              {/* <span className="ivc-text">INDB0000735</span>*/}
                              <span className="ivc-text">{checkData(debitNoteDetail.bank_details) && debitNoteDetail.bank_details.Code}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-right'>
                            <div className="ivc_box">
                              <span className="ivc_ttl d-block">For Keryx Retail Solutions Pvt Ltd</span>
                              <span className='signature d-block' style={{ minHeight: "40px" }}></span>
                              <span className="ivc-text">Authorised Signatory</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={5} className="text-center">
                            <span className="ivc-text">**This is computer generated debit note**</span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  : loader ? <div className="w-100 text-center"><Spinner /></div> : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

export default MerchantDebitNoteDatail;