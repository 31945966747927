import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, Spinner, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, DropdownToggle, DropdownMenu, UncontrolledDropdown, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlus, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { changeIntoDate, errorHandling, remExtraSpace } from "../../utlis";
import { useForm } from "react-hook-form"
import axios from 'axios';
import Swal from 'sweetalert2';
import Status from '../Status';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { connect, useDispatch } from 'react-redux';
import { AllCourier } from '../../actions';
import { ChevronLeft, ChevronRight } from 'react-feather';
import UrlImageDownloader from "react-url-image-downloader";
import { baseString } from '../../config';

const configData = require("../../config");
const Courier = (props) => {
  const [courier, setCourier] = useState({});
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [editmodal, setEditmodal] = useState(false);
  const [addmodal, setAddodal] = useState(false);
  const [logo, setLogo] = useState();
  const [editCourier, setEditCourier] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const { register: editCourierdata, handleSubmit: courierEditSubmit, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const [filterInput, setFilterInput] = useState('');
  const dropFilterCount = 20
  const dispatch = useDispatch();

  // const {name} = useLocation();
  const toggleAdd = () => setAddodal(!addmodal)
  const toggleClose = () => {
    setEditmodal(false);
  }
  const toggleEdit = (i) => {
    setLoader(true);
    setEditmodal(!editmodal);
    fetch(`${process.env.REACT_APP_GET_COURIER}/view/${i}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
        "token": window.btoa(baseString)
      },
    }).then((resp) => {
      resp.json().then((result) => {
        setLoader(false);
        let myResponse = result[0];
        if (myResponse !== undefined) {
          if (myResponse.status !== undefined) {
            myResponse.status = String(myResponse.status);
          }
          if (myResponse.category_id !== undefined) {
            myResponse.category_id = String(myResponse.category_id);
          }
        }
        reset2(myResponse);
        setEditCourier(result)
      }).catch(err => {
        setLoader(false);
        errorHandling(err, "");
      });
    });
  }

  const handleFileInput = (e) => {
    setLogo(e.target.files[0])
  }
  const courierListing = () => {
    setLoadertable(true);
    setFilterInput('')
    setCurrentCount(1)
    resetFilter();
    setPage(1)
    let mypost = {}
    let myget = { "page": page, "per_page": selectperpage, "status": 1 }
    dispatch(AllCourier(mypost, myget)).then((response) => {
      setLoadertable(false);
      setCourier(response);
    }).catch((err) => {
      setLoadertable(false);
      errorHandling(err, "reload");
    });
  }
  useEffect(() => {
    if (checkData(props.state) && checkData(props.state.viewAllCourier)) {
      setCourier(props.state.viewAllCourier);
    } else {
      courierListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectperpage, page,])
  const onEditCourierSubmit = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("full_name", data.full_name);
    formData.append("brand_name", data.brand_name);
    formData.append("tat_in_days", data.tat_in_days);
    formData.append("aggreator_courier_id", data.aggreator_courier_id);
    formData.append("email", data.email);
    formData.append("volumetric_value", data.volumetric_value);
    formData.append("service_id", data.service_id);
    formData.append("weight_category", data.weight_category);
    formData.append("movement_type_id", data.movement_type_id);
    formData.append("max_liability_per_shipment", data.max_liability_per_shipment);
    formData.append("logo", logo !== undefined && logo !== '' ? logo : editCourier[0].logo);
    formData.append("status", data.status);
    formData.append("category_id", data.category_id);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_GET_COURIER}/update/${editCourier[0].id}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        setEditmodal(false);
        courierListing(); setLogo('');
        Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', })
      }
    }).catch((err) => {
      setLoader(false);
      errorHandling(err, "");
    })
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(courier.data) && checkUndeNullBlank(courier.totalPage)) {
      // &&checkUndeNullBlank(courier.totalPage).length>page.length
      if (courier.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(courier.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(courier.data)
      if (currentCount > Object.entries(courier.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register, handleSubmit, watch: filterWatch, reset: resetFilter } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.full_name) || checkUndeNullBlank(data.email) || checkUndeNullBlank(data.courier_id) || checkUndeNullBlank(data.movement_type_id) || checkUndeNullBlank(data.status)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
    fetch(`${process.env.REACT_APP_GET_COURIER}?full_name=${checkUndeNullBlank(data.full_name) ? remExtraSpace(data.full_name) : ''}&email=${checkUndeNullBlank(data.email) ? remExtraSpace(data.email) : ''}&courier_id=${checkUndeNullBlank(data.courier_id) ? remExtraSpace(data.courier_id) : ''}&status=${checkUndeNullBlank(data.status) ? data.status : 1}&movement_type_id=${checkUndeNullBlank(data.movement_type_id) ? data.movement_type_id : ''}&page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
        "token": window.btoa(baseString)
      },
    }).then((resp) => {
      resp.json().then((result) => {
        if (result.fatal !== true) {
          setLoader(false)
          setCourier(result)
        }
      });
    }).catch((err) => {
      setLoader(false);
      errorHandling(err, "");
    });
  };
  const { register: addCourier, handleSubmit: courierSubmit, formState: { errors }, reset } = useForm();
  const onAddCourierSubmit = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("full_name", data.full_name);
    formData.append("brand_name", data.brand_name);
    formData.append("tat_in_days", data.tat_in_days);
    formData.append("aggreator_courier_id", data.aggreator_courier_id);
    formData.append("email", data.email);
    formData.append("volumetric_value", data.volumetric_value);
    formData.append("service_id", data.service_id);
    formData.append("weight_category", data.weight_category);
    formData.append("movement_type_id", data.movement_type_id);
    formData.append("max_liability_per_shipment", data.max_liability_per_shipment);
    formData.append("logo", logo);
    formData.append("status", data.status);
    formData.append("category_id", data.category_id);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_ADD_COURIER}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      setAddodal(false);
      if (res.data.error === false) {
        setEditmodal(false);
        reset();
        courierListing();
        Swal.fire({
          title: 'Success',
          text: res.data.msg,
          icon: 'success',
        })
      }
    }).catch(err => {
      errorHandling(err, "");
    });
  };
  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 manageCourier shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                <CardTitle className="page-title">Manage Courier Company</CardTitle>
                {checkData(props.user[0]) && props.user[0].id === 1 &&
                  <div className="card-header-right d-flex align-items-center ml-auto">
                    <div className="btn-text btn-light" onClick={toggleAdd}><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add Courier Company</span>
                    </div>
                  </div>
                }
                <Modal isOpen={addmodal} toggle={addmodal} scrollable={true} size="lg">
                  <ModalHeader toggle={toggleAdd}>Add Courier Company</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={courierSubmit(onAddCourierSubmit)}>
                      <Row className="mx-0 justify-content-center addModal">
                        <Col sm={4}>
                          <FormGroup>
                            <Label for="Courier Name">Enter Courier Name<span className="text-danger">*</span></Label>
                            <input {...addCourier("full_name", { required: 'Please Enter Courier Name' })} className="form-control-sm form-control" placeholder="Enter Courier Name*" id="Courier Name" />
                            {errors.full_name && <span className="text-danger d-block error">{errors.full_name.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <Label for="email">Enter Email<span className="text-danger">*</span></Label>
                            <input {...addCourier("email", {
                              required: 'Please Enter Email',
                              pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' }
                            })} type="text" className="form-control-sm form-control" placeholder="Enter Email*" id="email" />
                            {errors.email && <span className="text-danger d-block error">{errors.email.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <Label for="brand_name">Enter Brand Name<span className="text-danger">*</span></Label>
                            <input {...addCourier("brand_name", { required: 'Please Enter Brand Name' })} className="form-control-sm form-control" placeholder="Enter Brand Name*" id="brand_name" />
                            {errors.brand_name && <span className="text-danger d-block error">{errors.brand_name.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <Label for="tat_in_days">TAT In Days<span className="text-danger">*</span></Label>
                            <input {...addCourier("tat_in_days", { required: 'Please Enter TaT In Days' })} className="form-control-sm form-control" placeholder="Enter TAT In Days*" id="tat_in_days" />
                            {errors.tat_in_days && <span className="text-danger d-block error">{errors.tat_in_days.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <Label for="aggreator_courier_id">Aggreator Courier Id</Label>
                            <input {...addCourier("aggreator_courier_id", { pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Aggreator Courier Id' } })} className="form-control-sm form-control" placeholder="Enter Aggreator Courier Id" id="aggreator_courier_id" />
                            {errors.aggreator_courier_id && <span className="text-danger d-block error">{errors.aggreator_courier_id.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <Label for="brandLogo">Upload Logo<span className="text-danger">*</span></Label>
                            <input {...addCourier("brandlogo", { required: 'Please Add Courier Logo' })} type="file" onChange={handleFileInput} className="form-control-sm form-control" id="brandLogo" />
                            {errors.brandlogo && <span className="text-danger d-block error">{errors.brandlogo.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <Label for="selectCourier">Select Service<span className="text-danger">*</span></Label>
                            <div className="position-relative">
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select {...addCourier("service_id", { required: 'Please Select Surface' })} className="form-control-sm form-control">
                                <option value="">Selcet Service</option>
                                {Object.entries(configData.service).map(([key, value]) => (
                                  <option value={key}>{value}</option>
                                ))}
                              </select>
                            </div>
                            {errors.service_id && <span className="text-danger d-block error">{errors.service_id.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <Label for="selectCourier">Weight Category <small> (In Kgs.)</small><span className="text-danger">*</span></Label>
                            <div className="position-relative">
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select {...addCourier("weight_category", { required: 'Please Select Weight Category' })} className="form-control-sm form-control">
                                <option value="">Selcet Weight Category</option>
                                {Object.entries(configData.weight).map(([key, value]) => (
                                  <option value={value}>{value}</option>
                                ))}
                              </select>
                              {errors.weight_category && <span className="text-danger d-block error">{errors.weight_category.message}</span>}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <Label for="selectCourier">Movement Type<span className="text-danger">*</span></Label>
                            <div className="position-relative">
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select {...addCourier("movement_type_id", { required: 'Please Select Movement Type' })} className="form-control-sm form-control">
                                <option value="">Selcet Movement Type</option>
                                {Object.entries(configData.movement_type).map(([key, value]) => (
                                  <option value={key}>{value}</option>
                                ))}
                              </select>
                              {errors.movement_type_id && <span className="text-danger d-block error">{errors.movement_type_id.message}</span>}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <Label for="volumetric_value">Enter Volumetric Value<span className="text-danger">*</span></Label>
                            <input {...addCourier("volumetric_value", {
                              required: 'Please Enter Volumetric Value',
                              pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Volumetric Value' }
                            })} className="form-control-sm form-control" placeholder="Enter Volumertic Value*" id="volumetric_value" />
                            {errors.volumetric_value && <span className="text-danger d-block error">{errors.volumetric_value.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <Label for="maxLiaShip">Enter Max Liability Shipment<span className="text-danger">*</span></Label>
                            <input {...addCourier("max_liability_per_shipment", {
                              required: 'Please Enter Max Liability Shipment',
                              pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Max Liability Shipment' }
                            })} type="text" className="form-control-sm form-control" placeholder="Enter Max Liability Shipment*" id="maxLiaShip" />
                            {errors.max_liability_per_shipment && <span className="text-danger d-block error">{errors.max_liability_per_shipment.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <Label>Category<span className="text-danger">*</span></Label>
                            <div>
                              <span className="form-check2 mr-2" >
                                <input {...addCourier("category_id", { required: true })} type="radio" value="2" id="custom" defaultChecked />
                                <Label className="mx-2" for="custom">Custom</Label>
                              </span>
                              <span className="form-check2">
                                <input {...addCourier("category_id", { required: true })} type="radio" value="1" id="regular" />
                                <Label className="mx-2" for="regular">Regular</Label>
                              </span>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <Label>Status<span className="text-danger">*</span></Label>
                            <div>
                              <span className="form-check2 mr-2">
                                <input {...addCourier("status", { required: true })} type="radio" value="1" id="active" defaultChecked />
                                <Label className="mx-2" for="active">Active</Label>
                              </span>
                              <span className="form-check2">
                                <input {...addCourier("status", { required: true })} type="radio" value="0" id="inactive" />
                                <Label className="mx-2" for="inactive">InActive</Label>
                              </span>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
                <Modal isOpen={editmodal} toggle={editmodal} scrollable={true} size="lg">
                  <ModalHeader toggle={toggleClose}>Edit Courier Company</ModalHeader>
                  <ModalBody>
                    {checkData(editCourier) &&
                      <Form onSubmit={courierEditSubmit(onEditCourierSubmit)}>
                        <Row className="mx-0 justify-content-center addModal">
                          <Col sm={4}>
                            <FormGroup>
                              <Label for="Courier Name">Enter Courier Name<span className="text-danger">*</span></Label>
                              <input {...editCourierdata("full_name", { required: 'Please Enter Courier Name' })} className="form-control-sm form-control" placeholder="Enter Courier Name*" id="Courier Name" />
                              {errors2.full_name && <span className="text-danger d-block error">{errors2.full_name.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={4}>
                            <FormGroup>
                              <Label for="email">Enter Email<span className="text-danger">*</span></Label>
                              <input {...editCourierdata("email", {
                                required: 'Please Enter Email',
                                pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' }
                              })} type="text" className="form-control-sm form-control" placeholder="Enter Email*" id="email" />
                              {errors2.email && <span className="text-danger d-block error">{errors2.email.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={4}>
                            <FormGroup>
                              <Label for="brand_name">Enter Brand Name<span className="text-danger">*</span></Label>
                              <input {...editCourierdata("brand_name", { required: 'Please Enter Brand Name' })} className="form-control-sm form-control" placeholder="Enter Brand Name*" id="brand_name" />
                              {errors2.brand_name && <span className="text-danger d-block error">{errors2.brand_name.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={4}>
                            <FormGroup>
                              <Label for="tat_in_days">TAT In Days<span className="text-danger">*</span></Label>
                              <input {...editCourierdata("tat_in_days", { required: 'Please Enter Tat In Days' })} className="form-control-sm form-control" placeholder="Enter TAT In Days*" id="tat_in_days" />
                              {errors2.tat_in_days && <span className="text-danger d-block error">{errors2.tat_in_days.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={4}>
                            <FormGroup>
                              <Label for="aggreator_courier_id">Aggreator Courier Id</Label>
                              <input {...editCourierdata("aggreator_courier_id", { pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Aggreator Courier Id' } })} className="form-control-sm form-control" placeholder="Enter Aggreator Courier Id*" id="aggreator_courier_id" disabled />
                              {errors2.aggreator_courier_id && <span className="text-danger d-block error">{errors2.aggreator_courier_id.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={4}>
                            <FormGroup>
                              <Label for="logo">Upload Logo<span className="text-danger">*</span></Label>
                              <input {...editCourierdata("brandlogo")} type="file" className="form-control-sm form-control" id="logo" onChange={handleFileInput} />
                              <div className="edit-brand-logo download-img-con mt-2">
                                {/* <span><img src={process.env.REACT_APP_IMAGE_COURIER+editCourier[0].logo} alt="logo" style={{width:"150px"}} /></span>
                              <Link target="_blank" download onClick={(event) => { event.preventDefault(); window.open(process.env.REACT_APP_IMAGE_COURIER+editCourier[0].logo); }} className="btn-text ml-2"><FontAwesomeIcon icon={faDownload} /></Link> */}
                                <UrlImageDownloader imageUrl={process.env.REACT_APP_IMAGE_COURIER + editCourier[0].logo} />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm={4}>
                            <FormGroup>
                              <Label for="selectCourier">Select Service<span className="text-danger">*</span></Label>
                              <div className="position-relative">
                                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                <select {...editCourierdata("service_id", { required: 'Please Select Surface' })} className="form-control-sm form-control">
                                  <option>Select Service</option>
                                  {Object.entries(configData.service).map(([key, value]) => (
                                    <option value={key}>{value}</option>
                                  ))}
                                </select>
                              </div>
                              {errors2.service_id && <span className="text-danger d-block error">{errors2.service_id.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={4}>
                            <FormGroup>
                              <Label for="selectCourier">Weight Category <small> (In Kgs.)</small><span className="text-danger">*</span></Label>
                              <div className="position-relative">
                                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                <select {...editCourierdata("weight_category", { required: 'Please Select Weight Category' })} className="form-control-sm form-control">
                                  <option>Selcet Weight Category</option>
                                  {Object.entries(configData.weight).map(([key, value]) => (
                                    <option value={value}>{value}</option>
                                  ))}
                                </select>
                              </div>
                              {errors2.weight_category && <span className="text-danger d-block error">{errors2.weight_category.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={4}>
                            <FormGroup>
                              <Label for="selectCourier">Movement Type<span className="text-danger">*</span></Label>
                              <div className="position-relative">
                                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                <select {...editCourierdata("movement_type_id", { required: 'Please Select Movement Type' })} className="form-control-sm form-control">
                                  <option value="">Select Movement Type</option>
                                  {Object.entries(configData.movement_type).map(([key, value]) => (
                                    <option value={key}>{value}</option>
                                  ))}
                                </select>
                                {errors2.movement_type_id && <span className="text-danger d-block error">{errors2.movement_type_id.message}</span>}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm={4}>
                            <FormGroup>
                              <Label for="volumerticValue">Enter Volumertic Value<span className="text-danger">*</span></Label>
                              <input {...editCourierdata("volumetric_value", {
                                required: 'Please Enter Volumetric Value',
                                pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Volumetric Value' }
                              })} className="form-control-sm form-control" placeholder="Enter Volumertic Value*" id="volumerticValue" />
                              {errors2.volumetric_value && <span className="text-danger d-block error">{errors2.volumetric_value.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={4}>
                            <FormGroup>
                              <Label for="maxLiaship">Enter Max Liability Shipment<span className="text-danger">*</span></Label>
                              <input {...editCourierdata("max_liability_per_shipment", {
                                required: 'Please Enter Max Liability Shipment',
                                pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Max Liability Shipment' }
                              })} type="text" className="form-control-sm form-control" placeholder="Enter Max Liability Shipment*" id="maxLiaship" />
                              {errors2.max_liability_per_shipment && <span className="text-danger d-block error">{errors2.max_liability_per_shipment.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={4}>
                            <FormGroup>
                              <Label>Category<span className="text-danger">*</span></Label>
                              <div>
                                <span className="form-check2 mr-2">
                                  <input {...editCourierdata("category_id", { required: 'Please Choose Category' })} type="radio" value="2" id="edit_custom" />
                                  <Label className="mx-2" for="edit_custom">Custom</Label>
                                </span>
                                <span className="form-check2">
                                  <input {...editCourierdata("category_id", { required: 'Please Choose Category' })} type="radio" value="1" id="edit_regular" />
                                  <Label className="mx-2" for="edit_regular">Regular</Label>
                                </span>
                              </div>
                              {errors2.category_id && <span className="text-danger d-block error">{errors2.category_id.message}</span>}
                            </FormGroup>
                          </Col>
                          <Col sm={4}>
                            <FormGroup>
                              <Label>Status<span className="text-danger">*</span></Label>
                              <div>
                                <span className="form-check2 mr-2">
                                  <input type="radio" id="edit_active" {...editCourierdata("status", { required: 'Please Choose Status' })} value="1" />
                                  <Label className="mx-2" for="edit_active">Active</Label>
                                </span>
                                <span className="form-check2">
                                  <input type="radio" id="edit_inactive" {...editCourierdata("status", { required: 'Please Choose Status' })} value="0" />
                                  <Label className="mx-2" for="edit_inactive">InActive</Label>
                                </span>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm={12} className="text-right">
                            <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                          </Col>
                        </Row>
                      </Form>
                    }
                  </ModalBody>
                </Modal>
              </CardHeader>
              <Form onSubmit={handleSubmit(onSubmit)} className="cardFilter">
                <Row className="mx-0 justify-content-start justify-content-md-center mb-3">
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <input {...register("full_name")} className="form-control-sm form-control" placeholder="Enter Name" />
                    </FormGroup>

                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <input {...register("email")} type="email" className="form-control-sm form-control" placeholder="Enter Email" />
                    </FormGroup>
                  </Col>
                  {/* <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                        <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select {...register("weight_category")} className={`form-control-sm form-control ${checkData(filterWatch("weight_category")) ? 'valid':''}`}>
                              <option value="">Selcet Weight Category</option>
                              {Object.entries(configData.weight).map(([key,value]) => (
                              <option value={value}>{value}</option>
                              ))}
                          </select>
                        </div>
                      </FormGroup>
                      </Col> */}
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <select {...register("movement_type_id")} className={`form-control-sm form-control ${checkData(filterWatch("movement_type_id")) ? 'valid' : ''}`}>
                          <option value="">Selcet Movement Type</option>
                          {Object.entries(configData.movement_type).map(([key, value]) => (
                            <option value={key}>{value}</option>
                          ))}
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs={4} sm={3} md={3} lg={2} xl={2}>
                    <FormGroup>
                      <input {...register("courier_id")} type="text" className="form-control-sm form-control" placeholder="Courier Id" />
                    </FormGroup>
                  </Col>
                  <Col sm={3} md={3} lg={3} xl={3}>
                    <div className="d-flex flex-wrap">
                      <FormGroup className="mb-md-0">
                        <span className="form-check2 mr-2">
                          <input {...register("status")} type="radio" value='1' id="active" defaultChecked />
                          <Label className="mx-2" for="active">Active</Label>
                        </span>
                      </FormGroup>
                      <FormGroup className="mr-sm-4 mb-0">
                        <span className="form-check2 mr-2">
                          <input {...register("status")} type="radio" value='0' id="inActive" />
                          <Label className="mx-2" for="inActive">InActive</Label>
                        </span>
                      </FormGroup>
                    </div>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={courierListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(courier) && checkData(courier.data) && checkUndeNullBlank(courier.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(courier.data).length)} of {courier.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th className="w-some">Name</th>
                      <th>Email</th>
                      <th>Logo</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(courier.data) ? courier.data.map((item, index) => (
                      <tr>
                        <td>{item.id}</td>
                        <td>{item.full_name}</td>
                        <td>{item.email}</td>
                        <td><div className="courier-logo text-center"><img src={process.env.REACT_APP_IMAGE_COURIER + item.logo} alt="Courier Logo" style={{ width: '60px' }} /></div></td>

                        <td className="text-nowrap">{(item.created_at !== undefined && item.created_at !== null ? changeIntoDate(item.created_at) : '')}</td>

                        <td className="text-nowrap">
                          <div className='btn-group-ctm'>
                            {checkData(props.user[0]) && props.user[0].id === 1 &&
                              <>
                                <span className=""><Status statusUrl={process.env.REACT_APP_GET_COURIER} listId={item.id} valStatus={item.status} reListing={courierListing} data={{ "courier_id": item.id, "status": item.status === 1 ? 0 : 1 }} /></span>
                                <span><Button outline className="ctm-btn btn-sm ml-2 btn-edit" color="info" onClick={() => toggleEdit(item.id)}>Edit</Button></span>
                              </>
                            }
                            <span><UncontrolledDropdown className="d-inline-block">
                              <DropdownToggle outline className="btn btn-sm ml-2 btn-more" color="secondary">
                                More<span className="ml-1"><FontAwesomeIcon icon={faAngleDown} /></span>
                              </DropdownToggle>
                              <DropdownMenu>
                                {checkData(props.user[0]) && props.user[0].id === 1 &&
                                  <>
                                    <Link to={`/courier-details/${item.id}`} className="dropdown-item">Courier Details</Link>
                                    <Link to={`/zoning/${item.id}?name=${item.brand_name}`} className="dropdown-item">Zoing</Link>
                                  </>
                                }
                                <Link to={`/courier-pricing/${item.id}?name=${item.brand_name}&type=${item.movement_type_id}`} className="dropdown-item">Pricing</Link>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                      : loadertable ? <tr> <td colSpan="6"><LoaderDefault /></td> </tr> :
                        <tr> <td colSpan="6"><NodataFound /></td> </tr>}
                  </tbody>
                </Table>
                {checkData(courier) && checkData(courier.data) && checkUndeNullBlank(courier.TotalRows) ?
                  <>
                    {courier.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}
function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}

export default connect(mapDispatchToProps)(Courier);