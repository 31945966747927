import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle, FormGroup,UncontrolledTooltip, InputGroup,InputGroupAddon,InputGroupText,Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Spinner, Badge, Nav, NavItem,NavLink} from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown,faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { baseString, delivery_type_id, download_delay, order_ndrs,  } from '../../config';
import { connect } from 'react-redux';
import { checkData,checkUndeNullBlank } from '../../ObjectExist';
import { ChevronLeft, ChevronRight, Copy } from 'react-feather';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { copyToClipBoard, changeIntoDate, errorHandling, remExtraSpace, moreText } from '../../utlis';
import { toast} from 'react-toastify';
import { Link, useHistory} from 'react-router-dom';
import { useForm } from "react-hook-form";
import TrackOrder from "../Orders/TrackOrder";
import classnames from 'classnames';
import { Pie } from 'react-chartjs-2';

const AllNdr = (props) => {
  const [searchValue, setSearchValue] = useState(' ');
  const [liveNdrList, setLiveNdrList] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [sideAnim, setSideAnim] = useState(false);
  const [page, setPage] = useState(1)
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({});
  const [loader, setLoader] = useState(false);
  const [orderTracking, setOrderTracking] = useState({});
  const [statusRecheckOrderId, setStatusRecheckOrderId] = useState([]);
  const [lastOrderStatus, setlastOrderStatus] = useState();
  const [tempClass, setTempClass] = useState(null);
  const [merchants, setMerchants] = useState([]);

  const dropFilterCount = 20;
  const [isExport, setIsExport] = useState(false);
  const [ndrStatus, setNdrStatus] = useState([]);
  const [activeTab, setActiveTab] = useState('0');
  const history = useHistory();

  const toggleActionTab = tab => {
    if(activeTab !== tab) setActiveTab(tab);
    AllNdrList(tab);
  }

  const closeTracker = (data) => {
    setSideAnim(!sideAnim) 
    setOrderTracking({});
    setTempClass(null);
  }
  const orderTrack = (orderId, lastStatus) => {
    setlastOrderStatus(lastStatus)
    setStatusRecheckOrderId(orderId)
    let data = {"order_id":orderId}
    // axios.post(`${process.env.REACT_APP_TRACK_ORDER}`,data)
    axios({
      method: "post",
      url: process.env.REACT_APP_TRACK_ORDER,
      data: data,
      headers: {"token":window.btoa(baseString)}
      }).then((res) => {
      setLoader(false);
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.fatal !== true && res.error !== true){
            setOrderTracking(res.data.data)
          }
          else{
            Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
          }
        }
      }
    }).catch((err) => {
      errorHandling(err,"");
      setLoader(false);
    });
  }
  const NdrStatusMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "NDR Status"
  }
  const opt_ndrStatus = [];
  for (const property in order_ndrs) {
    let tempObj = {label: `${order_ndrs[property]}`, value: property };
    opt_ndrStatus.push(tempObj);
  }
  var opt_merchants = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)){
      Object.keys(props.state.shipmentMerchantCourier.merchants).map(function(key){
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {} 
      if(merchantName && merchantId){
          tempObj = {label: merchantName, value: merchantId }
          opt_merchants.push(tempObj)   
      }
      return true
      });
  }
  const merchantsMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }
  const [allCourier, setAllCourier] = useState([]);
  const allCourierMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "All Couriers"
  }
  var opt_allCourier = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)){
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }     
      return opt_allCourier.push(tempObj)
    });
  }

  const AllNdrList = (action_id) => {
  setMerchants([]);
  setAllCourier([]);
  setNdrStatus([]); 
  reset();
  
  let data = {}  
  data.action = action_id !== undefined && action_id;

  if(props.user[0].user_type === "Super"){
      data.admin_id=props.user[0].id
    }else{
      data.merchant_id=props.user[0].id
    }
  setLoadertable(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_NDR}?page=${page}&per_page=${selectperpage}`,
      data: data,
      headers: {"token":window.btoa(baseString)}
    }).then((res) => {
      setLoadertable(false)
        if(res.data.error === false){
          setLiveNdrList(res.data);
        }else{
          Swal.fire({icon:'error',text:res.data.msg,title:'Error'});
        }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err,"reload");
    });
  }
  useEffect(() => {
    AllNdrList(activeTab);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
// copy text
const copied = (text) =>{
  let decision = copyToClipBoard(text);
  decision.then(function(result) {
    if(result === true){
      toast("Copied!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  })
}
function handleSelectChange(event) {
  setSelectperpage(event.target.value);
  setLoadertable(true)
  setPage(1)
  setCurrentCount(1)
  let pagePerpage = {"page":1, "per_page":event.target.value};
  onpaginationFilter(filterInput,pagePerpage)
}
function nextPageData() {
if(checkData(liveNdrList.data)&&checkUndeNullBlank(liveNdrList.totalPage)){
  // &&checkUndeNullBlank(liveNdrList.totalPage).length>page.length
    if(liveNdrList.totalPage > page){
      setCurrentCount(currentCount+Object.entries(liveNdrList.data).length)
  setPage(page+1)
  setLoadertable(true)
    let pagePerpage = {"page":page+1, "per_page":selectperpage};
    onpaginationFilter(filterInput,pagePerpage);
  }
}
}
function previousPageData() {
if(page >1){
setPage(page-1)
checkData(liveNdrList.data)
if(currentCount>Object.entries(liveNdrList.data).length){
  setCurrentCount(currentCount-selectperpage)
}else{
  setCurrentCount(1)
}
let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
  onpaginationFilter(filterInput,pagePerpage);
}
}
const { register:filter, handleSubmit:filterliveNdrListSubmit, watch:filterWatch, reset } = useForm();
const onpaginationFilter = (data,pagePerpage) => {
  onCommonFilter(data,pagePerpage)
};
const onFilterliveNdrList = (data,pagePerpage) => {
  setIsExport(true);
  pagePerpage.page=1
  if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(data.delivery_type_id) || checkData(ndrStatus) || checkData(allCourier) || checkData(merchants)){
    setCurrentCount(1);
    setSelectperpage(selectperpage);
    setFilterInput(data);
    onCommonFilter(data,pagePerpage)
  }else{
    Swal.fire({title: 'info',text: 'Please Choose Filter',icon: 'info',})
  }
};
const onCommonFilter = (data,pagePerpage) => {
  let byData = {}
  let tmpNdrStatus = [];
  let courierId = [];
  let merchantsId = [];
  if(checkData(ndrStatus)){
    ndrStatus.map((item) => (
      tmpNdrStatus.push(item.value)
    )); 
  } 
  if(checkData(allCourier)){
    allCourier.map((item) => (
      courierId.push(item.value)
    )); 
  } 
  if(checkData(merchants)){
    merchants.map((item) => (
      merchantsId.push(item.value)
    )); 
  }

  if(checkUndeNullBlank(courierId)){
    byData.courier_id=courierId
  }
  setLoader(true)
  if(data === undefined){
    data = filterInput
  }
  let mId = checkUndeNullBlank(merchantsId)?merchantsId:'';
  byData={"action":activeTab,"search_field":checkUndeNullBlank(data.search_field)?data.search_field:'',"search_value":checkUndeNullBlank(data.search_value)?remExtraSpace(data.search_value):'',"ndr_status":checkUndeNullBlank(tmpNdrStatus)?tmpNdrStatus:'',"delivery_type_id":checkUndeNullBlank(data.delivery_type_id)?data.delivery_type_id:'',"merchant_ids":mId,"courier_id":checkUndeNullBlank(courierId)?courierId:''}
  setFilterInput(byData);
  if(props.user[0].user_type === "Super"){
    byData.admin_id = props.user[0].id
  }else{
    byData.merchant_id = props.user[0].id
  }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_NDR}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
      data: byData,
      headers: {"token":window.btoa(baseString)}
    }).then((res) => {
      setLoader(false);
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.fatal !== true && res.error !== true){
            setLiveNdrList(res.data);
          }
          else{
            Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
          }
        }
      }
    }).catch((err) => {
      errorHandling(err,"");
      setLoader(false);
    });
};

const exportAllNdr = () => {
  if(isExport){
    let data = filterInput
    if(props.user[0].user_type === "Super"){
      data.admin_id = props.user[0].id
    }else{
      data.merchant_id = props.user[0].id
    }
    data.is_export=1
      setLoader(true);
      // axios.post(process.env.REACT_APP_ALL_NDR, data)
      axios({
        method: "post",
        url: process.env.REACT_APP_ALL_NDR,
        data: data,
        headers: {"token":window.btoa(baseString)}
        }).then((res) => {
    if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
      if(parseInt(res.data.errno)>0){
        setLoader(false);
        if(res.data.sqlMessage!==undefined){
          Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
        }
      }else{
        if(res.data.is_export === 1){
          setTimeout(function(){ 
            setLoader(false);
            history.push("/my-downloads"); 
          },download_delay);
        }
      }
    }
  }).catch((err) => {
    setLoader(false)
    errorHandling(err,"");
  });
  }else{
    Swal.fire({icon:'error',title:'Error', text:'Please Choose Filter'})
  }
}

const resetForm = () => {
  AllNdrList(activeTab);
}

let actionPendingRows = checkData(liveNdrList) ? checkUndeNullBlank(liveNdrList.TotalRows) ? liveNdrList.TotalRows : 0 : 0;
let actionTakenRows = 0;

const data = {
  labels: ['Action Pending', 'Action Taken'],
  datasets: [
    {
      label: 'Action (counts)',
      data: [actionPendingRows,actionTakenRows],
      backgroundColor: [
          '#fd7e14',
          'rgb(57,218,138)',
      ],
      hoverOffset: 0
    },
  ],      
};

const options = {
  plugins: { 
      tooltip: {
          enabled: false,
      },           
    title: {
      display: false,
      text: ''
    },
    legend: {
      display: false,
      position:"right",
      labels: { usePointStyle: true }
    },
  },       
};

  return (
    <div className="app-content content overflow-hidden">
     {loader && <div className="formLoader"><Spinner /></div>}
     {checkData(orderTracking) && <div className="overlay"></div>}
      
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 ndr allndr shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between align-items-start flex-wrap">
              <CardTitle className="page-title">Manage All NDR<br/><small className="fsc-2">Pending Shipments NDR</small></CardTitle>
              <div className="card-header-right d-flex flex-wrap align-items-center ml-auto position-relative">
                <div className="ndrPie-main d-none">
                    <div className="d-flex align-items-center ">
                      <div className="ndrPiecon" style={{maxWidth: "70px",width:"100%"}}>
                          <Pie data={data} options={options} />
                      </div>
                      <div className="ndrpieLabels">
                          <Badge color='warning' className='mb-2'><span className="d-block mb-1">Action Pending </span><span className="d-block">{actionPendingRows}</span></Badge><br/>
                          <Badge color='success'><span className="d-block mb-1">Action Taken</span><span>{}</span></Badge>
                      </div>
                    </div>
                </div>
                <div className="btn-text btn-light" onClick={exportAllNdr}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Export In All NDR</span>
                </div>
              </div>
            </CardHeader>
            <Row className="mx-0 position-relative">
                <Col sm={12}>                
                  <Nav tabs className="mb-4 border-bottom text-center flex-nowrap">
                      <NavItem>
                          <NavLink className={`warning-ctm ${classnames({ active: activeTab === '0' })}`} onClick={() => { toggleActionTab('0'); }}>
                              <div>
                                  <span className="mr-2"></span>
                                  <span className="text-nowrap">Action Pending</span>
                              </div>
                          </NavLink>
                      </NavItem>
                      <NavItem>
                          <NavLink className={`success-ctm ${classnames({ active: activeTab === '1' })}`} onClick={() => { toggleActionTab('1'); }}>
                              <div>
                                  <span className="mr-2"></span>
                                  <span className="text-nowrap">Action Taken</span>
                              </div>
                          </NavLink>
                      </NavItem>
                  </Nav>
                </Col>                
            </Row>
            <Form onSubmit={filterliveNdrListSubmit(onFilterliveNdrList)} className="cardFilter">
                  <Row className="mx-0 mb-3 justify-content-start">
                    <Col sm={6} md={5} lg={5} xl={4}>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control" onChange={(e) => {if(e.target.selectedOptions[0].text !== "Search By"){setSearchValue("Enter "+e.target.selectedOptions[0].text)}else{setSearchValue('')}}}>
                              <option value=''>Search By</option>
                              <option value="awb_number">AWB Number</option>
                              <option value="customer_name">Customer Name</option>
                              <option value= "customer_mobile">Customer Mobile</option>
                              <option value="order_no">Order No</option>
                              <option value="product_name">Product Name</option>
                            </select>
                            </InputGroupText>
                          </InputGroupAddon>
                          <input {...filter("search_value")} type="text"  className="form-control-sm form-control" placeholder={searchValue} />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col sm={6} md={3} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_ndrStatus}
                              value={ndrStatus}
                              onChange={setNdrStatus}
                              labelledBy="NDR Status"
                              overrideStrings={NdrStatusMultiSelectSettings}
                              className={checkData(ndrStatus) && "active"}
                            />
                          </div>
                        </FormGroup>
                    </Col> 
                    {props.user[0].user_type === "Super" &&
                    <Col sm={6} md={3} lg={3} xl={2}>
                        <FormGroup>
                          <MultiSelect
                              options={opt_merchants}
                              value={merchants}
                              onChange={(e) => {setMerchants(e);}}
                              labelledBy="Select Merchant"
                              overrideStrings={merchantsMultiSelectSettings}
                              className={checkData(merchants) && "active"}
                            />
                        </FormGroup>
                    </Col>}  
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_allCourier}
                              value={allCourier}
                              onChange={setAllCourier}
                              labelledBy="All Couriers"
                              overrideStrings={allCourierMultiSelectSettings}
                              className={checkData(allCourier) && "active"}
                            />
                          </div>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                      <FormGroup>
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select {...filter("delivery_type_id")} className={`form-control-sm form-control ${checkData(filterWatch("delivery_type_id")) ? 'valid':''}`}>
                            <option value="">Delivery Type</option>
                            {Object.entries(delivery_type_id).map(([key,value]) => (
                            <option value={key}>{value}</option>
                            ))}
                          </select>
                      </FormGroup>
                    </Col> 
                    <Col sm={3} className="btn-container">
                      <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" onClick={resetForm} outline>Reset</Button>
                    </Col>
                  </Row>
                </Form>
              <CardBody className="p-0">
              <div className="showing-count">
                  {checkData(liveNdrList) && checkData(liveNdrList.data) && checkUndeNullBlank(liveNdrList.TotalRows) ?
                  <span class="small">Showing {currentCount} to {(currentCount-1)+(Object.entries(liveNdrList.data).length)} of {liveNdrList.TotalRows}</span>: ''}  
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      {props.user[0].user_type === "Super" &&
                      <th className="text-nowrap">Merchant Name</th>
                      }
                      <th>Customer Details</th>
                      <th>Shipment Details</th>
                      <th>NDR</th>
                      <th>Update/Response</th>
                      <th>Remarks</th>
                      <th>Product</th>
                    </tr>
                   </thead>
                  <tbody>
                  {checkData(liveNdrList.data) ? liveNdrList.data.map((item, index) => (
                    <tr key={index} className={`${tempClass === item.id && "showTop"}`}>
                    {props.user[0].user_type === "Super" &&
                      <td>{item.merchantName}</td>
                      }
                      <td className="align-middle">{item.customer_name}<br /> {item.customer_mobile}<br /> {item.product_cod_value + ' (' + delivery_type_id[item.delivery_type_id] + ')'}</td>
                      <td className="align-middle text-left col-2">
                        <div className="list-group-item border-0 p-0 d-inline-flex">
                          <div className="list-icon">
                            <div className="avatar">
                              {item.logo !== null && item.logo !== undefined ?
                                <img className="img-fluid" src={process.env.REACT_APP_IMAGE_COURIER+item.logo} alt="logo" width="60" />
                              : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500">M</span>}
                            </div>
                          </div>
                          <div className="list-content text-left">
                            <div className="">
                              <p className="mb-0">{(item.CourierName)}</p>                             
                            </div>
                            <div className="d-flex">
                            <span className="mb-1 d-block text-primary text-left text-nowrap"  onClick = { () => {setSideAnim(!sideAnim); orderTrack(item.order_id, item.order_status_id); setStatusRecheckOrderId([item.id]); setTempClass(item.id)}} style={{cursor:'pointer'}}>AWB - {item.awb_no}</span>
                              <span onClick={() => copied(item.awb_no)} className="ml-1 copy-btn" id={`copy${item.id}`}>
                                <Copy />
                                <UncontrolledTooltip placement="bottom" target={`copy${item.id}`}>
                                  Copy
                                </UncontrolledTooltip>
                              </span>
                            </div>
                            <div className="text-nowrap">Order no. -<Link target="_blank" to={`/order-detail/${item.order_id}`}>{item.order_no}</Link></div>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">{order_ndrs[item.ndr_status_id]} <br /> <span className="text-nowrap">NDR - {changeIntoDate(item.created_at)}</span></td>
                      <td className="align-middle">
                        {checkUndeNullBlank(item.merchant_action_id) ? item.merchant_action_id === 1 ? 
                          <Badge className="bg-white mb-2 d-flex cursor-pointer" style={{maxWidth:"197px"}}><span className="mr-2 text-success">REATTEMPT</span><span className="font-weight-normal">{changeIntoDate(item.merchant_action_reattempt_date)}</span></Badge>:
                          <Badge className="bg-white d-flex cursor-pointer" style={{maxWidth:"197px"}}><span className="mr-2 text-danger">RTO</span></Badge>:
                          "NA"
                        }
                        <span className='d-block'>Total Attempt : {item.total_attempt}</span>
                        {/* {item.merchant_action_id === 1 &&
                        <Badge className="custom-shadow bg-white mb-2 d-flex cursor-pointer" style={{maxWidth:"197px"}}><span className="mr-2 text-success">REATTEMPT</span><span className="font-weight-normal">{item.merchant_action_id === 1 ? changeIntoDate(item.merchant_action_reattempt_date): "NA"}</span></Badge>}
                        {item.merchant_action_id === 2 &&
                        <Badge className="custom-shadow bg-white d-flex cursor-pointer" style={{maxWidth:"197px"}}><span className="mr-2 text-danger">RTO</span><span className="font-weight-normal">{item.merchant_action_id === 2 ? changeIntoDate(item.merchant_action_reattempt_date): "NA"}</span></Badge>} */}
                      </td>
                      <td className="align-middle">{item.merchant_remarks}</td>
                      <td className="align-middle">
                        <div id={`MoreProduct${item.id}`}>{moreText(item.product_name)}</div>
                        <UncontrolledTooltip placement="top" target={`MoreProduct${item.id}`}>
                          {item.product_name}
                        </UncontrolledTooltip>
                        {checkUndeNullBlank(item.order_date) &&
                        <>
                        <br /><span className="text-nowrap"> Order - {changeIntoDate(item.order_date)}</span>
                        </>}
                      </td>
                    </tr>
                    )):
                      loadertable ? <tr><td colSpan="5" className="text-center"><LoaderDefault /></td></tr>
                    :<tr><td colSpan="5" className="text-center"><NodataFound /></td></tr>
                  }
                  </tbody>
                </Table>
                {checkData(liveNdrList) && checkData(liveNdrList.data) && checkUndeNullBlank(liveNdrList.TotalRows) ?
                <>
                {liveNdrList.TotalRows > dropFilterCount ?
               <Row className="m-0 table-footer">
               <Col xs="6">               
                <Pagination aria-label="Page navigation example align-items-center">
                    <PaginationItem className="prev">
                      <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                    </PaginationItem>
                    <PaginationItem className="next ml-3">
                      <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                    </PaginationItem>
                </Pagination>
                </Col>
                <Col xs="6">
                <Form>
                  <FormGroup>
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                          <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          </select>
                  </FormGroup>
                </Form>
                </Col>
               </Row>:''}
               </>
               :''}
              </CardBody>
            </Card>
        </Card>
      </Col>
      
      </Row>
      {checkData(orderTracking) && <TrackOrder orderTracking={orderTracking} toggleTrack = {sideAnim ? "show" : "hide"} closeTracker={(e) => { closeTracker(e) }} position="fixed" lastStatus={lastOrderStatus} statusRecheckOrderId={statusRecheckOrderId} user={props.user}  />}
    </div>
  );
}

function mapDispatchToProps(state){
  return {
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(AllNdr);