export const StateList = [
    {
      state_name: "Arunachal Pradesh",
      state_id: "62",
    },
    {
      state_name: "Assam",
      state_id: "63",
    },
    {
      state_name: "Manipur",
      state_id: "64",
    },
    {
      state_name: "Meghalaya",
      state_id: "65",
    },
    {
      state_name: "Sikkim",
      state_id: "66",
    },
    {
      state_name: "Nagaland",
      state_id: "68",
    },
    {
      state_name: "Tripura",
      state_id: "69",
    },
    {
      state_name: "Mizoram",
      state_id: "70",
    },
    {
      state_name: "Haryana",
      state_id: "72",
    },
    {
      state_name: "Punjab",
      state_id: "73",
    },
    {
      state_name: "Uttarakhand",
      state_id: "75",
    },
    {
      state_name: " Himachal Pradesh",
      state_id: "76",
    },
    {
      state_name: "Jammu and Kashmir",
      state_id: "77",
    },
    {
      state_name: " Chandigarh",
      state_id: "78",
    },
    {
      state_name: "Rajasthan",
      state_id: "79",
    },
    {
      state_name: "Kerala",
      state_id: "81",
    },
    {
      state_name: "Tamil Nadu",
      state_id: "82",
    },
    {
      state_name: "Telangana",
      state_id: "83",
    },
    {
      state_name: "Karnataka",
      state_id: "84",
    },
    {
      state_name: "Andhra Pradesh",
      state_id: "85",
    },
    {
      state_name: "Delhi",
      state_id: "86",
    },
    {
      state_name: "Madhya Pradesh",
      state_id: "87",
    },
    {
      state_name: "Uttar Pradesh",
      state_id: "88",
    },
    {
      state_name: "Chhattisgarh",
      state_id: "89",
    },
    {
      state_name: "Andaman and Nicobar",
      state_id: "90",
    },
    {
      state_name: "Lakshadweep",
      state_id: "91",
    },
    {
      state_name: "Bihar",
      state_id: "92",
    },
    {
      state_name: "Goa",
      state_id: "93",
    },
    {
      state_name: "Gujarat",
      state_id: "94",
    },
    {
      state_name: "Jharkhand",
      state_id: "95",
    },
    {
      state_name: "Orissa",
      state_id: "96",
    },
    {
      state_name: "West Bengal",
      state_id: "97",
    },
    {
      state_name: "Maharashtra",
      state_id: "98",
    },
    {
      state_name: "Uttaranchal",
      state_id: "114",
    },
    {
      state_name: "Pondicherry",
      state_id: "115",
    },
    {
      state_name: "ODISHA",
      state_id: "116",
    },
    {
      state_name: "KHARSIA",
      state_id: "117",
    },
    {
      state_name: "Dadra and Nagar Haveli",
      state_id: "118",
    },
    {
      state_name: "Daman and Diu",
      state_id: "119",
    },
  ];