import React,{useEffect, useState} from 'react';
import {Card, Label, FormGroup, CardHeader, CardTitle, CardBody, Form,Row, Col, Button } from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
import { DateRangePicker } from 'rsuite';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';
import { delivered_order_status, failed_order_status,overall_pending_order_status,pending_order_status, rto_order_status } from '../../../../config';
import { arrayValueSum, errorHandling,changeIntoDateYmd} from '../../../../utlis';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import { useForm } from "react-hook-form";
import moment from 'moment';
import {connect, useDispatch} from 'react-redux'
import { shipmentStatusM } from '../../../../actions';

const ShipmentStatus = (props) => {
    const [allCourier, setAllCourier] = useState([]);
    const [filterDate, setFilterDate] = useState([new Date(moment().subtract(31, 'days')),new Date()]);
    const [performanceOverviews, setPerformanceOverviews] = useState({});
    const [loadertable, setLoadertable] = useState(false);
    const [pradioVal, setPradioVal] = useState(1);
    const dispatch = useDispatch();

    const performanceRadio = (e) => {
      setPradioVal(e.target.value);
  }
  
    const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef =  dateRange => dateRangeRef = dateRange;
    const shipmentStatusData = () => {
        setLoadertable(true);
        var date = new Date();

        let mypost = {"merchant_id":props.user[0].id,"start_date":changeIntoDateYmd(date.setDate(date.getDate() - 31)),"end_date":changeIntoDateYmd(new Date())}
        let myget = {}
        dispatch(shipmentStatusM(mypost,myget)).then((response) => {
            if(response.error === false){
                setPerformanceOverviews(response.data);
            }
            setLoadertable(false)
          }).catch(function (response) {
            setLoadertable(false);
            errorHandling(response,"reload");
          });

    }
    useEffect(() => {
      if(checkData(props.state) && checkData(props.state.viewShipmentStatusM)&& checkData(props.state.viewShipmentStatusM.data)){
        setPerformanceOverviews(props.state.viewShipmentStatusM.data);
      }else{
        shipmentStatusData();
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  
    function findPendingCount(count,pending_order_status) {
      const pendingDatavalue = pending_order_status.filter((item) => item === count)[0];
      return pendingDatavalue
  }
    function findRtoCount(count) {
          const pendingDatavalue = rto_order_status.filter((item) => item === count)[0];
          return pendingDatavalue
      }
    function findDeliveredCount(count) {
          const pendingDatavalue = delivered_order_status.filter((item) => item === count)[0];
          return pendingDatavalue
      }
    function findFailedCount(count) {
          const pendingDatavalue = failed_order_status.filter((item) => item === count)[0];
          return pendingDatavalue
      }
        let shipmentDelivered= []
        let shipmentPending = []
        let shipmentRto= [];
        let shipmentFailed= [];
        let shipmentIntransit= [];
        checkData(performanceOverviews) && Object.values(performanceOverviews.reduce((osi, item) => {        
        let delivered = checkUndeNullBlank(findDeliveredCount(item.order_status_id)) ? item.total : 0;
        // let pending = checkUndeNullBlank(findPendingCount(item.order_status_id)) ? item.total : 0;
        let pending=0;
        if(parseInt(pradioVal) === 1){
          pending = checkUndeNullBlank(findPendingCount(item.order_status_id,pending_order_status)) ? item.total : 0;
        }else{
          pending = checkUndeNullBlank(findPendingCount(item.order_status_id,overall_pending_order_status)) ? item.total : 0;
        }
        let rto=checkUndeNullBlank(findRtoCount(item.order_status_id)) ? item.total : 0;
        let failed=checkUndeNullBlank(findFailedCount(item.order_status_id)) ? item.total : 0;
        let intransit=checkUndeNullBlank(item.order_status_id) && parseInt(item.order_status_id) === 6 ? item.total : 0;
        shipmentIntransit.push(intransit);
        shipmentDelivered.push(delivered);
        shipmentPending.push(pending);
        shipmentRto.push(rto);
        shipmentFailed.push(failed);
        return osi;
    }, {}));
       
    let totalDelivered = arrayValueSum(shipmentDelivered);
    let totalPending = arrayValueSum(shipmentPending);
    let totalRto = arrayValueSum(shipmentRto);
    let totalFailed = arrayValueSum(shipmentFailed);
    let totalShipment = totalDelivered+totalPending+totalRto+totalFailed;
    // let totalIntransit = eval(shipmentIntransit.join('+'));
    let totalDeliveredPercent=0;
    if(parseInt(pradioVal) === 1 ){
        totalDeliveredPercent = totalDelivered >0 ? (totalDelivered / (totalShipment - totalPending) ) * 100 : 0
    }else{
        totalDeliveredPercent = totalDelivered >0 ? (totalDelivered / totalShipment ) * 100 : 0      
    }
    let totalPendingPercent = totalPending >0 ? (totalPending / totalShipment ) * 100 : 0
    let totalRtoPercent = totalRto >0 ? (totalRto / totalShipment ) * 100 : 0
    let totalFailedPercent = totalFailed >0 ? (totalFailed / totalShipment ) * 100 : 0

    const allCourierMultiSelectSettings={
        "allItemsAreSelected": "All items are selected.",
        "clearSearch": "Clear Search",
        "noOptions": "No options",
        "search": "Search",
        "selectAll": "Select All",
        "selectSomeItems": "All Couriers"
      }
      // const opt_allCourier = [
      //   { label: "Fedex (Express - 0.5KG)", value: "Fedex (Express - 0.5KG)" },
      // ];

    var opt_allCourier = [];
    if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)){
        Object.keys(props.state.shipmentMerchantCourier.couriers).map(function(key){
        let tempObj = {label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }     
        return opt_allCourier.push(tempObj)
        });
    }
    
    const data = {
        labels: [`Delivered (${totalDelivered})`, `RTO (${totalRto})`, `Pending (${totalPending})`, `Delivered Failed (${totalFailed})`],
        datasets: [
          {
            label: 'Shipment (counts)',
            data: [totalDelivered,totalRto,totalPending,totalFailed],
            backgroundColor: [
            'rgb(57,218,138)',
            'rgb(255,91,92)',
            'rgb(255,215,0)',
            'rgb(253,172,65)',
            ],
            hoverOffset: 4
          },
          
        ],
       
        
      };

      const options = {
        plugins: {
          title: {
            display: false,
            text: ''
          },
          legend: {
            display: true,
            position:"right",
            labels: { usePointStyle: true }
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                  var label = context.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed !== null) {
                      if(context.label === `Delivered (${context.parsed})`){
                      label += context.formattedValue+" / "+totalDeliveredPercent.toFixed(2)+"%";
                    }else if(context.label === `Delivered Failed (${context.parsed})`){
                      label += context.formattedValue+" / "+totalFailedPercent.toFixed(2)+"%";
                    }else if(context.label === `RTO (${context.parsed})`){
                      label += context.formattedValue+" / "+totalRtoPercent.toFixed(2)+"%";
                    }else if(context.label === `Pending (${context.parsed})`){
                      label += context.formattedValue+" / "+totalPendingPercent.toFixed(2)+"%";
                    }
                  }
                  return label;
              }
            }
          }   
        },
        
        
      };
    const { register:filter, handleSubmit:filterPerFormance } = useForm();
    const onFilterPerformance = (data) => {
        setPerformanceOverviews({});
        setLoadertable(true);
        let byData = {}
        let courierId = [];
        if(checkData(allCourier)){
          allCourier.map((item) => (
              courierId.push(item.value)
          )); 
        } 
        if(checkUndeNullBlank(courierId)){
            byData.courier_id=courierId
          }
          let isAttempted = checkUndeNullBlank(pradioVal) ? pradioVal : 1

        byData={"is_attempted":isAttempted,"courier_id":checkUndeNullBlank(courierId)?courierId:'',"delivery_type_id":checkUndeNullBlank(data.delivery_type_id)?data.delivery_type_id:'',"start_date":checkData(filterDate) && filterDate.length > 0 ? moment(filterDate[0]).format('YYYY-MM-DD') : '',"end_date":checkData(filterDate) && filterDate.length > 0 ? moment(filterDate[1]).format('YYYY-MM-DD') : ''}
        if(props.user[0].user_type === "Super"){
            byData.admin_id=props.user[0].id
          }else{
            byData.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_DASHBOARD_MERCHANT_OVERALL_PERFORMANCE}`,
            data: byData,
          }).then((res) => {
            setLoadertable(false)
            if(res.data.error === false){
                setPerformanceOverviews(res.data.data);
            }
          }).catch((err) => {
            setLoadertable(false)
            errorHandling(err,"");
          });  
    };

    return (
        <div className="ShipmentStatus">
            <Card className="border-0 shadow">
                <CardHeader className="page-header d-flex flex-wrap align-items-center justify-content-between">
                    <CardTitle className="page-title">Shipments Status</CardTitle>
                </CardHeader>
                <Form  onSubmit={filterPerFormance(onFilterPerformance)} className="cardFilter">
                    <Row className="mx-0 pt-1 justify-content-start justify-content-md-center">
                        <Col sm={6}>
                            <FormGroup>
                                <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Upload Date" format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} ref={dateRangeRef} change={onDateChange} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <MultiSelect 
                                options={opt_allCourier}
                                value={allCourier}
                                onChange={(e) => setAllCourier(e)}
                                labelledBy="All Couriers"
                                overrideStrings={allCourierMultiSelectSettings}
                                className={checkData(allCourier) && "active"}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <div className="d-flex flex-wrap mb-2 mb-sm-0">
                                <FormGroup className="mr-sm-2 mb-0">
                                    <span className="form-check2 mr-2">
                                        <input {...filter("delivery_type_id")} type="radio" value='1' id="ship_COD" />
                                        <Label className="mx-2" for="ship_COD">COD</Label>
                                    </span>
                                </FormGroup>
                                <FormGroup className="mb-0">
                                    <span className="form-check2 mr-2">
                                        <input {...filter("delivery_type_id")} type="radio" value='3' id="ship_Prepaid" />
                                        <Label className="mx-2" for="ship_Prepaid">Prepaid</Label>
                                    </span>
                                </FormGroup>
                            </div>
                        </Col>
                        <Col md={6} className="pl-0">
                                <div className="d-flex flex-wrap">
                                    <FormGroup className="mb-md-0">
                                        <span className="form-check2 mr-2">
                                            <input {...filter("is_attempted")} type="radio" value='1' id="isattempted"  onChange={ (e) => performanceRadio(e)} defaultChecked/>
                                            <Label className="mx-2" for="isattempted">Attempted</Label>
                                        </span>
                                    </FormGroup>
                                    <FormGroup className="mr-sm-4 mb-0">
                                        <span className="form-check2 mr-2">
                                            <input {...filter("is_attempted")} type="radio" value='0' id="isoverall" onChange={ (e) => performanceRadio(e)} />
                                            <Label className="mx-2" for="isoverall">Overall</Label>
                                        </span>
                                    </FormGroup>
                                </div>
                            </Col>
                        <Col sm={12} className="text-center mt-2">
                            <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                        </Col>
                    </Row>
                </Form>
                <CardBody>                    
                    <div className="d-flex justify-content-center">
                        <div style={{maxWidth: "400px",width:"100%"}}>
                        {!loadertable ? 
                            <Pie data={data} options={options} />
                        :''}
                        </div>
                    </div>
                </CardBody>
            </Card>            
        </div>
    );
}

function mapDispatchToProps (state) {
  return {
    state:state.DataReducer
  }
}
export default connect(mapDispatchToProps)(ShipmentStatus);