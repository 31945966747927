import React, { useState, useEffect } from 'react';
import { Row, Col, Button, CardTitle, FormGroup, Form, Label, Spinner } from 'reactstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2';
import { errorHandling } from '../../utlis';
import { baseString } from '../../config';


const EmailNotification = (props) => {

  const [loader, setLoader] = useState(false);
  const { register: addEmailNotification, handleSubmit: merchantEmailSubmit, reset: emailNotiReset } = useForm();


  useEffect(() => {
    viewEmailNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const viewEmailNotification = () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_NOTIFICATION}/view`,
      data: { "merchant_id": props.merchantId },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        let myResponse = response.data[0];
        emailNotiReset(myResponse);
      })
      .catch(function (err) {
        errorHandling(err, "reload");
      });
  }

  const onEmailNotification = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("merchant_id", props.merchantId);
    formData.append("accounts_email", data.accounts_email);
    formData.append("ndr_email", data.ndr_email);
    formData.append("order_summary_email", data.order_summary_email);
    formData.append("weight_alert_email", data.weight_alert_email);

    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_NOTIFICATION,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', })
        viewEmailNotification();
      }
    }).catch((err) => {
      setLoader(false);
      errorHandling(err, "");
    });
  };



  return (
    <>
      {loader && <div className="formLoader"><Spinner /></div>}
      <Form onSubmit={merchantEmailSubmit(onEmailNotification)} >
        <div className="sub-page-header" >
          <CardTitle tag="h4" className="sub-page-title" > Email Notification </CardTitle>
        </div>
        <Row>
          <Col sm={6}>
            <FormGroup>
              <Label for="acont_mail" > Accounts Mail </Label>
              <input {...addEmailNotification("accounts_email")} className="form-control" placeholder="Accounts Mail" id="acont_mail" />
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="ndr_mail"> NDR Mail </Label>
              < input {...addEmailNotification("ndr_email")} className="form-control" placeholder="NDR Mail" id="ndr_mail" />
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="order_summary"> Order Summary </Label>
              <input {...addEmailNotification("order_summary_email")} className="form-control" placeholder="Order Summary" id="order_summary" />
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="weight_alert" > Weight Alert </Label>
              < input {...addEmailNotification("weight_alert_email")} className="form-control" placeholder="Weight Alert" id="weight_alert" />
            </FormGroup>
          </Col>
          <Col sm={12} className="mt-2 text-right">
            <Button className="ctm-btn btn-sm" color="primary" > Save changes </Button>
          </Col>
        </Row>
      </Form>

    </>
  );
}

export default EmailNotification;
