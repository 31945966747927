import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Spinner, Alert } from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
import { DateRangePicker } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowsAltH, faCartPlus, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useForm } from "react-hook-form";
import LoaderDefault from '../LoaderDefault';
import { baseString, delivery_type_id, download_delay } from '../../config';
import { moreText, copyToClipBoard, changeIntoDateTime, errorHandling, remExtraSpace } from '../../utlis';
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import EditBulkOrders from './EditBulkOrders';
import { Link, useHistory } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Copy } from 'react-feather';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import moment from 'moment';

const FailedOrders = (props) => {

  const [allCourier, setAllCourier] = useState([]);
  const [failedOrder, setFailedOrder] = useState({})
  const [loader, setLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [filterDate, setFilterDate] = useState([new Date(), new Date()]);
  const [editmodal, setEditmodal] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [merchants, setMerchants] = useState([]);
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment().format('YYYY-MM-DD'), });
  const [searchValue, setSearchValue] = useState(' ');
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkboxOrderId, setCheckboxOrderId] = useState([]);
  const [checked, setChecked] = useState(false);
  const [overallchecked, setOverallChecked] = useState(false);
  const [changeCourierModal, setChangeCourierModal] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const [courierList, setCourierList] = useState({});
  const history = useHistory();
  const dropFilterCount = 20

  let failedStartDate = props.ShipmentStartDate;
  let failedEndDate = props.ShipmentEndDate;

  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  const changeCourierToggle = () => {
    if (checkboxOrderId.length > 0) {
      setChangeCourierModal(!changeCourierModal);
    } else {
      Swal.fire({ icon: 'warning', title: 'Warning', text: 'Please Select The Order First' })
    }
  }
  const selectAll = (value) => {
    setOverallChecked(false);

    let abc = checkData(failedOrder.data) && failedOrder.data.map((key) => (
      key === false
    ));
    if (value) {
      // checkData(failedOrder.data)&&failedOrder.data.map((item) => (
      // setCheckboxOrderId(item)
      var opt_order_id = [];
      checkData(failedOrder.data) && Object.keys(failedOrder.data).map(function (key) {
        let tempObj = failedOrder.data[key].id
        opt_order_id.push(tempObj)

        return setCheckboxOrderId(opt_order_id)
      });
      // ));
    } else {
      setCheckboxOrderId([])
    }

    setChecked(abc);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };
  const selectOverAll = () => {
    setOverallChecked(true);
    // setCheckboxOrderId([]);
  }
  const toggleCheck = (utilityCheckbox, event, Id) => {
    setOverallChecked(false);

    var opt_order_id = checkboxOrderId;

    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_order_id.push(Id)
      setCheckboxOrderId(opt_order_id)
    } else {
      function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
          return ele !== value;
        });
      }
      var result = arrayRemove(checkboxOrderId, Id);
      setCheckboxOrderId(result)
    };
  }

  let dateRangeRef = dateRange => dateRangeRef = dateRange;
  const toggleEditClose = () => setEditmodal(false);
  const toggleEdit = (productId, merchantId) => {
    setLoader(true);
    setMerchantId(merchantId)
    let data = {}
    data = { "order_id": productId, "merchant_id": merchantId, "is_edit": 1 }
    setEditmodal(!editmodal);
    axios({
      method: "post",
      url: process.env.REACT_APP_ORDER_DETAILS,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          setOrderDetail(res.data);
        }
      }
    }).catch((err) => {
      setLoader(false);
      errorHandling(err, "")
    })
  }
  useEffect(() => {
    setTableLoader(true);
    faildOrderListing();
    couurierListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedStartDate, failedEndDate])
  const allCourierMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "All Couriers"
  }
  var opt_allCourier = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)) {
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function (key) {
      let tempObj = { label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }
      return opt_allCourier.push(tempObj)
    });
  }

  const [deliveryType, setDeliveryType] = useState([]);
  const deliveryTypeMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Delivery Type"
  }
  const opt_deliveryType = [];
  for (const property in delivery_type_id) {
    let tempObj = { label: `${delivery_type_id[property]}`, value: property };
    opt_deliveryType.push(tempObj);
  }


  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
      }
      return true
    });
  }
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }


  //listing faild Order
  const faildOrderListing = (alldatalist) => {
    setOverallChecked(false);
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    if (checkUndeNullBlank(failedStartDate) || checkUndeNullBlank(failedEndDate)) {
      setFilterDate([new Date(failedStartDate), new Date(failedEndDate)])
    } else {
      if (alldatalist !== '' && alldatalist === "alldatalist") {
        setFilterDate([]);
      } else {
        setFilterDate([new Date(), new Date()]);
      }
    }

    // let data = {"order_status_id":3, "start_date":changeIntoDateYmd(firstDayOftheMonth()),"end_date":changeIntoDateYmd(new Date())}
    // let data = {"order_status_id":3, "start_date":filterDate.length > 0 ? moment(filterDate[0]).format('YYYY-MM-DD') : '',"end_date":filterDate.length > 0 ? moment(filterDate[1]).format('YYYY-MM-DD') : ''}
    let data = { "order_status_id": 3 }

    if (checkUndeNullBlank(failedStartDate) && checkUndeNullBlank(failedEndDate)) {
      data.start_date = failedStartDate;
      data.end_date = failedEndDate;
    } else {
      if (alldatalist !== '' && alldatalist === "alldatalist") {
        data.start_date = "";
        data.end_date = "";
      } else {
        data.start_date = moment().format('YYYY-MM-DD');
        data.end_date = moment().format('YYYY-MM-DD');
      }
    }

    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setFilterInput({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment().format('YYYY-MM-DD'), })
    setCurrentCount(1)
    setSelectperpage(20)
    setPage(1)
    reset();
    setDeliveryType([]); setAllCourier([]); setMerchants([]); setSearchValue(' ');

    axios({
      method: "post",
      url: process.env.REACT_APP_ALL_ORDER,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setTableLoader(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          setFailedOrder(res.data);
        }
      }
    }).catch((err) => {
      setTableLoader(false)
      errorHandling(err, "reload")
    })
  }
  const exportFailedOrder = () => {
    if (checkData(filterInput)) {
      setLoader(true);
      let data = filterInput
      if (props.user[0].user_type === "Super") {
        data.admin_id = 1
      } else {
        data.merchant_id = props.user[0].id
      }
      data.order_status_id = 3
      data.is_export = 1
      axios({
        method: "post",
        url: process.env.REACT_APP_ALL_SHIPMENTS,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
          if (parseInt(res.data.errno) > 0) {
            setLoader(false);
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
            }
          } else {
            if (res.data.is_export === 1) {
              setTimeout(function () {
                setLoader(false);
                history.push("/my-downloads");
              }, download_delay);
            }
          }
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "")
      });
    } else {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Please Choose Filter' })
    }
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(failedOrder.data) && checkUndeNullBlank(failedOrder.totalPage)) {
      // &&checkUndeNullBlank(failedOrder.totalPage).length>page.length
      if (failedOrder.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(failedOrder.data).length)
        setPage(page + 1)
        setLoader(true);
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(failedOrder.data)
      if (currentCount > Object.entries(failedOrder.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }

  // filter Bulk Order
  const { register: filter, handleSubmit: filterFaildOrderSubmit, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onFilterFaildOrder = (data, pagePerpage) => {
    pagePerpage.page = 1
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkUndeNullBlank(data.api_msg) || checkData(merchants) || checkData(deliveryType) || checkData(allCourier) || checkData(filterDate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }

  };

  const callOnFilterOrder = () => {
    onFilterFaildOrder(filterInput, { "page": "" });
  }

  const onCommonFilter = (data, pagePerpage) => {
    setOverallChecked(false);
    setCheckedAll(false);
    setChecked(false);
    setCheckboxOrderId([]);
    setLoader(true)
    if (data === undefined) {
      data = filterInput
    }
    let byData = {}
    let tmpDeliveryType = []
    let courierId = [];
    let merchantsId = [];

    if (checkData(allCourier)) {
      allCourier.map((item) => (
        courierId.push(item.value)
      ));
    }

    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }

    deliveryType.map((item) => (
      tmpDeliveryType.push(item.value)
    ));

    byData = { "order_status_id": 3, "search_field": checkUndeNullBlank(data.search_field) ? data.search_field : '', "search_value": checkUndeNullBlank(data.search_value) ? remExtraSpace(data.search_value) : '', "api_msg": checkUndeNullBlank(data.api_msg) ? remExtraSpace(data.api_msg) : '', "delivery_type_id": checkData(tmpDeliveryType) ? tmpDeliveryType : '', "courier_id": checkUndeNullBlank(courierId) ? courierId : '', "merchant_ids": checkUndeNullBlank(merchantsId) ? merchantsId : '', "start_date": checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '', "end_date": checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '' }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_ORDER}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setFailedOrder(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "")
      setLoader(false);
    });
  };

  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }
  const couurierListing = () => {
    if (checkData(props.user) && props.user[0].user_type === "Merchant") {
      setLoader(true)
      axios({
        method: "post",
        url: process.env.REACT_APP_MERCHANT_COURIER_LIST,
        data: { "merchant_id": checkData(props.user) && props.user[0].id },
        headers: { "token": window.btoa(baseString) }
      }).then(function (response) {
        setLoader(false)
        if (response.data.error === false) {
          setCourierList(response.data.data.courierList)
        } else {
          Swal.fire({ title: 'Error', text: response.data.msg ? response.data.msg : 'Something went wrong', icon: 'error' });
        }

      }).catch(function (err) {
        setLoader(false);
        errorHandling(err, "reload")
      })
    }
  }
  const placeBulkOrder = () => {
    let data = { "order_ids": checkboxOrderId }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
      data.merchant_wallet_balance = props.user[0].wallet_balance
    }
    if (checkboxOrderId.length > 0) {
      setLoader(true);
      axios({
        method: "post",
        url: process.env.REACT_APP_PLACE_BULK_ORDER,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false);
        if (res.data.error !== true && res.data.fatal !== true) {
          faildOrderListing();
          Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success', allowOutsideClick: false, allowEscapeKey: false }).then(okay => {
            if (okay) {
              if (checkData(failedOrder.data)) {
                faildOrderListing();
              } else {
                history.push("/shipments");
              }
            }
          });
        } else {
          Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
        }
      }).catch((err) => {
        setLoader(false);
        errorHandling(err, "")
      })
    } else {
      Swal.fire({ icon: 'warning', title: 'Warning', text: 'Please Select The Order First' })
    }
  }
  const { register: changeCourier, handleSubmit: changeCourierSubmit, formState: { errors: errors4 }, reset: resetChangeCourier } = useForm();
  const onChangeCourierSubmit = data => {
    setLoader(true);
    data.order_ids = checkboxOrderId
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: process.env.REACT_APP_CHANGE_COURIER,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success' });
        setChangeCourierModal(false);
        if (checkData(filterInput)) {
          onCommonFilter();
        } else {
          faildOrderListing();
        }

        resetChangeCourier();
      }
      else {
        Swal.fire({ title: 'Error', text: res.data.msg, icon: 'error', })
      }
    }).catch((err) => {
      errorHandling(err, "")
      setLoader(false);
    });
  }


  return (
    // <div className="app-content content overflow-hidden">
    <div>
      {loader && <div className="formLoader"><Spinner /></div>}
      {checkData(orderDetail) &&
        <Modal isOpen={editmodal} toggle={editmodal} scrollable={true} size="xl">
          <ModalHeader toggle={toggleEditClose}>Edit Order</ModalHeader>
          <ModalBody>
            <EditBulkOrders orderDetails={orderDetail} setEditmodal={setEditmodal} merchantId={merchantId} setOrderDetail={setOrderDetail} user={props.user} faildOrderListing={faildOrderListing} callOnFilterOrder={callOnFilterOrder} />
          </ModalBody>
        </Modal>
      }
      <Row>
        <Col sm={12}>
          <Card className="p-0 failedOrder shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="page-title">Manage Failed Orders</CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  {/* {checkData(failedOrder) && <div className="btn-text btn-light" onClick={exportFailedOrder}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Failed Shipment</span>
                </div>} */}
                  {checkData(failedOrder.data) &&
                    <>
                      {props.user[0].user_type === "Merchant" && checkUndeNullBlank(props.user[0].ship_status) && parseInt(props.user[0].ship_status) === 1 &&
                        <>
                          <div className="btn-text btn-light btn-orange font-weight-bold text-white mx-2" onClick={placeBulkOrder}><span className="icon mr-2"><FontAwesomeIcon icon={faCartPlus} /></span><span>RESHIP</span></div>
                          <div className="btn-text btn-light" onClick={changeCourierToggle}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowsAltH} /></span><span>Change Courier</span></div>
                        </>
                      }
                      <div className="btn-text btn-light" onClick={exportFailedOrder}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download Failed Shipment</span></div>
                    </>
                  }
                </div>
              </CardHeader>
              <Modal isOpen={changeCourierModal} toggle={changeCourierModal} >
                <ModalHeader toggle={changeCourierToggle}>Change Courier</ModalHeader>
                <ModalBody>
                  <Form onSubmit={changeCourierSubmit(onChangeCourierSubmit)}>
                    <Row className="m-0 pb-1">
                      <Col sm={12}>
                        <FormGroup className='position-relative'>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select {...changeCourier("courier_id", { required: 'Please Choose Courier' })} className="form-control-sm form-control border-right-0">
                            <option value="">Select Courier</option>
                            {/* {checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers) && props.state.shipmentMerchantCourier.couriers.map((item) => ( */}
                            {checkData(courierList) && courierList.map((item) => (
                              item.status !== 0 &&
                              <option value={item.courier_id}>{item.courierName}</option>
                            ))}
                          </select>
                          {errors4.courier_id && <span className="text-danger d-block error">{errors4.courier_id.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col sm={12} className="text-right">
                        <Button className="btn ctm-btn btn-sm" color="primary">Submit</Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
              <Form onSubmit={filterFaildOrderSubmit(onFilterFaildOrder)} className="cardFilter">
                <Row className="mx-0 mb-3 justify-content-start justify-content-lg-center">
                  <Col sm={6} md={5} lg={5} xl={4}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control border-right-0" onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text) } else { setSearchValue('') } }}>
                              <option value="">Search By</option>
                              <option value="customer_name">Customer Name</option>
                              <option value="customer_name">Customer Pincode</option>
                              <option value="customer_mobile">Customer Mobile</option>
                              <option value="order_no">Order No</option>
                              <option value="product_name">Product Name</option>
                            </select>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <input {...filter("api_msg")} type="text" className="form-control-sm form-control" placeholder='Enter Failed Response' />
                    </FormGroup>
                  </Col>
                  {props.user[0].user_type === "Super" &&
                    <Col sm={6} md={3} lg={3} xl={2}>
                      <FormGroup>
                        <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={setMerchants}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                          className={checkData(merchants) && "active"}
                        />
                      </FormGroup>
                    </Col>}

                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <MultiSelect
                          options={opt_deliveryType}
                          value={deliveryType}
                          onChange={setDeliveryType}
                          labelledBy="Delivery Type"
                          overrideStrings={deliveryTypeMultiSelectSettings}
                          className={checkData(deliveryType) && "active"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <MultiSelect
                          options={opt_allCourier}
                          value={allCourier}
                          onChange={setAllCourier}
                          labelledBy="All Couriers"
                          overrideStrings={allCourierMultiSelectSettings}
                          className={checkData(allCourier) && "active"}
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Upload Date" format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} ref={dateRangeRef} change={onDateChange} />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={faildOrderListing}>Reset</Button>
                    <Button type="reset" color="primary" className="ctm-btn" onClick={() => faildOrderListing('alldatalist')}>Show All</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(failedOrder) && checkData(failedOrder.data) && checkUndeNullBlank(failedOrder.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(failedOrder.data).length)} of {failedOrder.TotalRows}</span> : ''}
                </div>
                {checkboxOrderId.length > 0 && !overallchecked &&
                  <Alert color="secondary" className='small total-select' fade={false}>
                    <span className='mx-1'>Total {checkboxOrderId.length} items on this page are selected.</span>{checkData(failedOrder.data) && checkUndeNullBlank(failedOrder.TotalRows) && failedOrder.TotalRows > selectperpage && checkedAll && <span onClick={selectOverAll} className='cursor-pointer text-primary d-none'>Select all <span className='font-weight-bold'>{checkData(failedOrder.data) && checkUndeNullBlank(failedOrder.TotalRows) && failedOrder.TotalRows}</span> items across all pages</span>}
                  </Alert>
                }
                {overallchecked &&
                  <Alert color="secondary" className='small total-select' fade={false}>
                    <span className='mx-1'>Total {checkData(failedOrder.data) && checkUndeNullBlank(failedOrder.TotalRows) && failedOrder.TotalRows} items are Selected.</span>
                  </Alert>
                }
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th><div className="form-check2"><input name="cust-type" type="checkbox" id="select_all_failed" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)} value="checkedall" /><label className="form-check-label" for="select_all_failed"></label></div></th>
                      {props.user[0].user_type === "Super" &&
                        <th className="text-nowrap">Merchant Name</th>}
                      <th className="text-nowrap">Order Number</th>
                      <th className="text-nowrap">Product Name</th>
                      <th className="text-nowrap">Courier</th>
                      <th className="text-nowrap">Uploaded</th>
                      <th className="text-nowrap">Last Updated</th>
                      <th className="text-nowrap">Failed response</th>
                      <th className="text-nowrap">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(failedOrder.data) ? failedOrder.data.map((item, index) => (
                      <tr>
                        <td><div className="form-check2"><input checked={checked !== undefined && checked[index]} name={'check' + index} type="checkbox" id={item.id} onChange={(event) => toggleCheck(index, event, item.id)} disabled={overallchecked} /><label className="form-check-label" for={item.id}></label></div></td>
                        {props.user[0].user_type === "Super" &&
                          <td>{item.merchantName}</td>}
                        <td><div className="text-nowrap"><Link target="_blank" to={`/order-detail/${item.id}`}>{item.order_no}</Link>
                          <span onClick={() => copied(item.order_no)} className="ml-1 copy-btn" id={`copyOdr${item.id}`}>
                            <Copy />
                            <UncontrolledTooltip placement="bottom" target={`copyOdr${item.id}`}>
                              Copy
                            </UncontrolledTooltip>
                          </span>
                        </div></td>
                        <td className="col-2">
                          <div id={`MoreProduct${item.id}`} className="w-product">{moreText(item.product_name)}</div>
                          <UncontrolledTooltip placement="top" target={`MoreProduct${item.id}`}>
                            {item.product_name}
                          </UncontrolledTooltip>
                        </td>
                        <td className="w-some">{item.courierName}</td>
                        <td className="text-nowrap">{(item.created_at !== undefined && item.created_at !== null ? changeIntoDateTime(item.created_at) : 'NA')}</td>
                        <td className="text-nowrap">{(item.updated_at !== undefined && item.updated_at !== null ? changeIntoDateTime(item.updated_at) : 'NA')}</td>
                        <td>
                          <div id={`msg${item.id}`} className="w-product">{moreText(item.api_msg)}</div>
                          <UncontrolledTooltip placement="top" target={`msg${item.id}`}>
                            {item.api_msg}
                          </UncontrolledTooltip>
                        </td>
                        <td className="text-nowrap"><Button className="mx-2 btn-sm" color="primary" outline onClick={() => toggleEdit(item.id, item.merchant_id)}>Edit</Button></td>
                      </tr>
                    )) :
                      tableLoader ? <tr>
                        <td colSpan="8"><LoaderDefault /></td>
                      </tr>
                        : <tr><td colSpan="8" className="text-center"><NodataFound /></td></tr>
                    }
                  </tbody>
                </Table>

                {checkData(failedOrder) && checkData(failedOrder.data) && checkUndeNullBlank(failedOrder.TotalRows) ?
                  <>
                    {failedOrder.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(FailedOrders);