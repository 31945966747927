import React, { useState} from 'react';
import { Nav, NavItem, CardImg,Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchMinus, faCalculator, faBalanceScale, faChevronDown, faChevronUp, faFile, faMapMarked, faMapPin, faStreetView, faWallet, faTimes, faListAlt } from '@fortawesome/free-solid-svg-icons';
import { faPlane, faAddressCard} from '@fortawesome/free-solid-svg-icons';
import LogoL from '../assets/img/logo-l.png';
// import LogoR from '../assets/img/logo-r.png';
import { faDropbox, faWpforms} from '@fortawesome/free-brands-svg-icons';
import { MessageSquare, Bell, BellOff, Home, Truck, Users, ShoppingCart, FileText, Package, RotateCw, FileMinus, Tool, List, User, Mail, CreditCard, ArrowRight, Book, Layers} from 'react-feather';
import { useLocation } from 'react-router';
import { checkData } from '../ObjectExist';

const AdminSideBar = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMerchant, setIsMerchant] = useState(false);
    const [isMaster, setIsMaster] = useState(false);
    const [isDebitNote, seIsDebitNote] = useState(false);
    const [isCreditNote, seIsCreditNote] = useState(false);
    const [isTool, seIsTool] = useState(false);
    const [isLedger, setIsLedger] = useState(false);
    const [isReport, seIsReport] = useState(false);
    const [isFrieght, setIsFreight] = useState(false);
    const [isRemittance, setIsRemittance] = useState(false);
    const [isCms, setIscms] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [isNDR, setIsNDR] = useState(false);
    const urlPath = useLocation();

    const toggle = () => setIsOpen(!isOpen);
    const toggleMerchant = () => setIsMerchant(!isMerchant);
    const toggleMaster = () => setIsMaster(!isMaster);
    const togglefreight = () => setIsFreight(!isFrieght);
    const toggleRemittance = () => setIsRemittance(!isRemittance);
    const toggleDebitNote = () => seIsDebitNote(!isDebitNote);
    const toggleCreditNote = () => seIsCreditNote(!isCreditNote);
    const toggleTool = () => seIsTool(!isTool);
    const toggleLedger = () => setIsLedger(!isLedger);
    const toggleReport = () => seIsReport(!isReport);
    const togglecms = () => setIscms(!isCms);
    const toggleNDR = () => setIsNDR(!isNDR);

    

  return (
        <div className={`main-menu menu-fixed menu-accordion menu-shadow menu-light ${!expanded ? "expanded": ""}`}  onMouseEnter={() => setExpanded(true)} onMouseLeave={() => setExpanded(false)}>
            <div className="navbar-header position-relative d-flex align-items-center">
            <Nav vertical>
                <NavItem>
                    <Link to="/" className="mx-auto d-flex align-items-center navbar-brand">
                        <CardImg top src={LogoL} alt="Card image cap mx-auto" style={{height:'35px',width:"auto"}} className="brand-logo" />
                        {/* <CardImg top src={`./logo-r${process.env.REACT_APP_ADMIN_ID}.png`} alt="Card image cap mx-auto" style={{height:'35px',width:"auto"}} className="brand-text" /> */}
                        <CardImg top src={process.env.PUBLIC_URL + "/logo-r" + process.env.REACT_APP_ADMIN_ID +".png"} alt="Card image cap mx-auto" style={{height:'35px',width:"auto"}} className="brand-text" />
                    </Link>
                </NavItem>
            </Nav>
            <span className="sideCollapse cursor-pointer" onClick={() => props.sideBarToggle()}>
                {/* <span className="d-none d-lg-block"><FontAwesomeIcon icon={faCircleNotch} /></span> */}
                <span className="for-open"><ArrowRight /></span>
                <span className="for-close"><FontAwesomeIcon icon={faTimes} /></span>
                {/* <span className="d-block d-lg-none for-close"><FontAwesomeIcon icon={faTimes} /></span> */}
            </span>
            </div>
            <div className="scrollbar-container main-menu-content">
                <Nav vertical className="navigation navigation-main">
                <NavItem className={`${urlPath.pathname ==='/' && 'active'}`}>
                    <Link to='/' className="d-flex align-items-center nav-link font-weight-500" active onClick={() => props.closeSideBar()}>
                    <span className="mr-2 menu-icon"><Home /></span>
                    <span className="menu-title">Dashboard</span>
                    </Link>
                </NavItem>
                <NavItem>
                    <Link to='#' className="d-flex align-items-center nav-link" onClick={toggleMaster}>
                        <span className="mr-2 menu-icon"><FontAwesomeIcon icon={faDropbox} /></span>
                        <span className="menu-title font-weight-500">Masters</span>
                        {!isMaster && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isMaster && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isMaster} className="collapse-css-transition">
                        <Nav vertical>
                            <NavItem className={`${urlPath.pathname ==='/city' && 'active'}`}>
                                <Link to='/city' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2 menu-icon"><FontAwesomeIcon icon={faMapMarked} /></span>
                                <span className="menu-title">City</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/state' && 'active'}`}>
                                <Link to='/state' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><FontAwesomeIcon icon={faStreetView} /></span>
                                <span>State</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/pincode' && 'active'}`}>
                                <Link to='/pincode' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><FontAwesomeIcon icon={faMapPin} /></span>
                                <span>Pincodes</span>
                                </Link>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </NavItem>
                <NavItem className={`${urlPath.pathname ==='/courier' && 'active'}`}>
                    <Link to='/courier' className="d-flex align-items-center nav-link font-weight-500" onClick={() => props.closeSideBar()}>
                    <span className="mr-2"><Truck /></span>
                   <span>Couriers</span>
                    </Link>
                </NavItem>
                <NavItem>
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={toggleMerchant}>
                        <span className="mr-2"><Users /></span>
                        <span>Merchants</span>
                        {!isMerchant && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isMerchant && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isMerchant} className="collapse-css-transition">
                        <Nav vertical>
                            <NavItem className={`${urlPath.pathname ==='/merchants' && 'active'}`}>
                                <Link to='/merchants' className="d-flex align-items-center nav-link font-weight-500" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><Users /></span>
                                <span>Registered Merchants</span>
                                </Link>
                            </NavItem>
                            {checkData(props.user) && checkData(props.user[0]) && props.user[0].id === 1 && 
                                <NavItem className={`${urlPath.pathname ==='/signup-merchants' && 'active'}`}>
                                    <Link to='/signup-merchants' className="d-flex align-items-center nav-link font-weight-500" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><Users /></span>
                                    <span>Signup Merchants</span>
                                    </Link>
                                </NavItem>
                            }
                        </Nav>
                    </Collapse>
                </NavItem>              
                <NavItem>
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={toggle}>
                        <span className="mr-2"><ShoppingCart /></span>
                        <span>Orders</span>
                        {!isOpen && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isOpen && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isOpen} className="collapse-css-transition">
                        <Nav vertical>                
                            <NavItem className={`${urlPath.pathname ==='/orders' && 'active'}`}>
                                <Link to='/orders' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><Package /></span>
                                <span>All Orders</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/channel-orders' && 'active'}`}>
                                <Link to='/channel-orders' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><FontAwesomeIcon icon={ faListAlt} /></span>
                                    <span>Channel Orders</span>
                                </Link>
                            </NavItem>                  
                            <NavItem className={`${urlPath.pathname ==='/shipments' && 'active'}`}>
                                <Link to='/shipments' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><Package /></span>
                                <span>All Shipments</span>
                                </Link>
                            </NavItem>                  
                            {/* <NavItem className={`${urlPath.pathname ==='/pickup-pending' && 'active'}`}>
                                <Link to='/pickup-pending' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><Watch /></span>
                                <span>Pickup Pending</span>
                                </Link>
                            </NavItem> */}
                            {/* <NavItem className={`${urlPath.pathname ==='/failed-orders' && 'active'}`}>
                                <Link to='/failed-orders' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><AlertTriangle /></span>
                                <span>Failed Orders</span>
                                </Link>
                            </NavItem> */}
                            <NavItem className={`${urlPath.pathname ==='/lost' && 'active'}`}>
                                <Link to='/lost' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><FileMinus /></span>
                                <span>Update Lost</span>
                                </Link>
                            </NavItem>
                            {/* <NavItem className={`${urlPath.pathname ==='/processing-orders' && 'active'}`}>
                                <Link to='/processing-orders' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><FileMinus /></span>
                                <span>Processing Order</span>
                                </Link>
                            </NavItem> */}
                        </Nav>
                    </Collapse>
                </NavItem> 
                <NavItem className={`${urlPath.pathname ==='/rto' && 'active'}`}>
                    <Link to='/rto' className="d-flex align-items-center nav-link font-weight-500" onClick={() => props.closeSideBar()}>
                    <span className="mr-2"><RotateCw /></span>
                    <span>RTO</span>
                    </Link>
                </NavItem>
                <NavItem>
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={toggleNDR}>
                        <span className="mr-2"><MessageSquare /></span>
                        <span>NDR</span>
                        {!isNDR && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isNDR && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isNDR} className="collapse-css-transition">
                        <Nav vertical>
                            <NavItem className={`${urlPath.pathname ==='/ndr' && 'active'}`}>
                                <Link to='/ndr' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><Bell /></span>
                                <span>Live NDR</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/old-ndr' && 'active'}`}>
                                <Link to='/old-ndr' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><BellOff /></span>
                                <span>All NDR</span>
                                </Link>
                            </NavItem>
                           </Nav>
                    </Collapse>
                </NavItem>   
                <NavItem className={`${urlPath.pathname ==='/weight-mismatch' && 'active'}`}>
                    <Link to='/weight-mismatch' className="d-flex align-items-center nav-link font-weight-500" onClick={() => props.closeSideBar()}>
                        <span className="mr-2"><FontAwesomeIcon icon={faBalanceScale} /></span>
                        <span>Weight Mismatch</span>
                    </Link>
                </NavItem>
                <NavItem>
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={toggleRemittance}>
                        <span className="mr-2"><FontAwesomeIcon icon={faWallet} /></span>
                        <span>Remittance</span>
                        {!isRemittance && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isRemittance && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isRemittance} className="collapse-css-transition">
                        <Nav vertical>    
                            <NavItem className={`${urlPath.pathname ==='/merchant-remittance' && 'active'}`}>
                                <Link to='/merchant-remittance' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><User /></span>
                                    <span>Merchant Remittance</span>
                                </Link>
                            </NavItem>                        
                             <NavItem className={`${urlPath.pathname ==='/initiated-remittance' && 'active'}`}>
                                <Link to='/initiated-remittance' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><List /></span>
                                    <span>Initiate Remittance</span>
                                </Link>
                            </NavItem>                            
                             <NavItem className={`${urlPath.pathname ==='/adjusted-remittance' && 'active'}`}>
                                <Link to='/adjusted-remittance' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><List /></span>
                                    <span>Adjusted Remittance</span>
                                </Link>
                            </NavItem>                            
                            <NavItem className={`${urlPath.pathname ==='/remittance-transactions' && 'active'}`}>
                                <Link to='/remittance-transactions' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><FontAwesomeIcon icon={faWallet} /></span>
                                    <span>Remittance Transactions</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/courier-remittance' && 'active'}`}>
                                <Link to='/courier-remittance' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><FontAwesomeIcon icon={faWallet} /></span>
                                    <span>Courier Remittance</span>
                                </Link>
                            </NavItem>
                           </Nav>
                    </Collapse>
                </NavItem>

                <NavItem>
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={togglefreight}>
                        <span className="mr-2"><FontAwesomeIcon icon={faPlane} /></span>
                        <span>Sales</span>
                        {!isFrieght && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isFrieght && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isFrieght} className="collapse-css-transition">
                        <Nav vertical>
                            <NavItem className={`${urlPath.pathname ==='/merchant-freight' && 'active'}`}>
                                <Link to='/merchant-freight' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><FileText /></span>
                                <span>Merchant Freight</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/merchant-invoices' && 'active'}`}>
                                <Link to='/merchant-invoices' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><FontAwesomeIcon icon={faWpforms} /></span>
                                    <span>Merchant Invoices</span>
                                </Link>
                            </NavItem>
                           </Nav>
                    </Collapse>
                </NavItem>
                <NavItem>
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={toggleCreditNote}>
                        <span className="mr-2"><CreditCard /></span>
                        <span>Credit Note</span>
                        {!isCreditNote && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isCreditNote && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isCreditNote} className="collapse-css-transition">
                        <Nav vertical>
                            <NavItem className={`${urlPath.pathname ==='/credit-note' && 'active'}`}>
                                <Link to='/credit-note' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><List /></span>
                                <span>Merchant Credit Note</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/courier-credit-note' && 'active'} d-none`}>
                                <Link to='/courier-credit-note' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><List /></span>
                                <span>Courier Credit Note</span>
                                </Link>
                            </NavItem>
                           </Nav>
                    </Collapse>
                </NavItem>
                <NavItem>
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={toggleDebitNote}>
                        <span className="mr-2"><CreditCard /></span>
                        <span>Debit Note</span>
                        {!isDebitNote && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isDebitNote && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isDebitNote} className="collapse-css-transition">
                        <Nav vertical>
                            <NavItem className={`${urlPath.pathname ==='/debit-note' && 'active'}`}>
                                <Link to='/debit-note' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><List /></span>
                                <span>Merchant Debit Note</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/courier-debit-note' && 'active'} d-none`}>
                                <Link to='/courier-debit-note' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><List /></span>
                                <span>Courier Debit Note</span>
                                </Link>
                            </NavItem>
                           </Nav>
                    </Collapse>
                </NavItem>                
                <NavItem>
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={toggleTool}>
                        <span className="mr-2"><Tool /></span>
                        <span>Tools</span>
                        {!isTool && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isTool && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isTool} className="collapse-css-transition">
                        <Nav vertical>
                            <NavItem className={`${urlPath.pathname ==='/utility-tool' && 'active'}`}>
                                <Link to='/utility-tool' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><Tool /></span>
                                    <span>Utility Tool</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/Serviceability' && 'active'}`}>
                                <Link to='/Serviceability' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><FontAwesomeIcon icon={faSearchMinus} /></span>
                                    <span>Serviceability</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/price-calculator' && 'active'}`}>
                                <Link to='/price-calculator' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><FontAwesomeIcon icon={faCalculator} /></span>
                                    <span>Price Calculaor</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/stock' && 'active'} d-none`}>
                                <Link to='/stock' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><Layers /></span>
                                    <span>Stock</span>
                                </Link>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </NavItem>
                <NavItem>
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={toggleLedger}>
                        <span className="mr-2"><Book /></span>
                        <span>Ledger</span>
                        {!isLedger && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isLedger && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isLedger} className="collapse-css-transition">
                        <Nav vertical>
                            <NavItem className={`${urlPath.pathname ==='/courier-ledger' && 'active'} d-none`}>
                                <Link to='/courier-ledger' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><Book /></span>
                                    <span>Courier Ledger</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/merchant-ledger' && 'active'}`}>
                                <Link to='/merchant-ledger' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><Book /></span>
                                    <span>Merchant Ledger</span>
                                </Link>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </NavItem>
                <NavItem className="d-none">
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={toggleReport}>
                        <span className="mr-2"><FontAwesomeIcon icon={faAddressCard} /></span>
                        <span>Reports</span>
                        {!isReport && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isReport && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isReport} className="collapse-css-transition">
                        <Nav vertical>
                            <NavItem className={`${urlPath.pathname ==='/report-shipment' && 'active'}`}>
                                <Link to='/report-shipment' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><Package /></span>
                                    <span>Shipment</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/report-ndr' && 'active'}`}>
                                <Link to='/report-ndr' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><Bell /></span>
                                    <span>NDR</span>
                                </Link>
                            </NavItem>
                            <NavItem className={`${urlPath.pathname ==='/report-remittance' && 'active'}`}>
                                <Link to='/report-remittance' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                    <span className="mr-2"><List /></span>
                                    <span>Remittance</span>
                                </Link>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </NavItem>
                <NavItem className="d-none">
                    <Link to='#' className="d-flex align-items-center nav-link font-weight-500" onClick={togglecms}>
                        <span className="mr-2"><FontAwesomeIcon icon={faFile} /></span>
                        <span>CMS</span>
                        {!isCms && <span className="du-icon"><FontAwesomeIcon icon={faChevronDown} /></span>}
                        {isCms && <span className="du-icon"><FontAwesomeIcon icon={faChevronUp} /></span>}
                    </Link>
                    <Collapse isOpen={isCms} className="collapse-css-transition">
                        <Nav vertical>
                            <NavItem>
                                <Link to='/email-content' className="d-flex align-items-center nav-link" onClick={() => props.closeSideBar()}>
                                <span className="mr-2"><Mail /></span>
                                <span>Email Template</span>
                                </Link>
                            </NavItem>
                           </Nav>
                    </Collapse>
                </NavItem>
                </Nav>
            </div>
        </div>
  );
}

export default AdminSideBar;