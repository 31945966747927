import React, {useEffect, useState} from 'react';
import {Card, CardBody, Row, Col, Spinner} from 'reactstrap';
import DatamapsIndia from 'react-datamaps-india'
import axios from 'axios';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import { errorHandling } from '../../../../utlis';
import { baseString } from '../../../../config';
const StateMap = (props) => {
    const [cityState, setCityState] = useState({});
    const [loader, setLoader] = useState(false);
    const listingSummary = () => {
        // setLoader(true);
        let data = {}
        if(props.user[0].user_type === "Super"){
            data.admin_id=props.user[0].id
          }else{
            data.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_DASHBOARD_MERCHANT_TOP_CITY_STATE}`,
            data: data,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false)
            if(res.data.error === false){
                setCityState(res.data);
            }
          }).catch((err) => {
            setLoader(false)
            errorHandling(err,"reload")
          });
    }
    useEffect(() => {
        listingSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let stateStats = {}
     if(checkData(cityState) && checkData(cityState.top_state)){
          cityState.top_state.map((item, index) => {
        let stateName = checkUndeNullBlank(item.customer_state) ? item.customer_state : 'NA'
        let totalCount = checkUndeNullBlank(item.totalState) ? item.totalState : 0
         stateStats[stateName] = {value:totalCount}
        return true
    })}

    return (
        <div className="StateWisePerfomance">
            {loader && <div className="formLoader"><Spinner /></div>}
            <Card className="border-0 shadow">
                <div className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <div className="page-title">State Wise Performance</div>  
                </div>
                <CardBody className="py-0">
                    <Row className="">
                        <Col sm={12} md={6}>
                            <div className="state-map">
                                <DatamapsIndia
                                    regionData={stateStats}
                                    hoverComponent={({ value }) => {
                                        return (
                                            <>
                                                <p>{value.name}</p>
                                                <p>{value.value}</p>
                                            </>
                                        )
                                    }}
                                    mapLayout={{
                                        // title: 'State Wise Performance',
                                        legendTitle: 'Total Performance',
                                        startColor: '#1b5a8f7d',
                                        endColor: '#1b5a8f',
                                        hoverTitle: 'Count',
                                        noDataColor: '#f5f5f5',
                                        borderColor: '#1b5a8f7d',
                                        hoverBorderColor: '#1b5a8f7d',
                                        hoverColor: '#012e54',
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>            
        </div>
    );
}

export default StateMap;