import React, {useState} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle, FormGroup,Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown,faChevronDown, faArrowUp} from '@fortawesome/free-solid-svg-icons';
import { ChevronLeft, ChevronRight} from 'react-feather';
import { MultiSelect, } from "react-multi-select-component";
import { DateRangePicker } from 'rsuite';
import {Link} from "react-router-dom";
import { connect } from 'react-redux';
import {firstDayOftheMonth} from '../../utlis';

const ReportShipment = (props) => {
  const [filterDate, setFilterDate] = useState([firstDayOftheMonth(),new Date()]);
  const [merchants, setMerchants] = useState([]);

  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef =  dateRange => dateRangeRef = dateRange;

  var opt_merchants = [];
  const merchantsMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  const [allCourier, setAllCourier] = useState([]);
  var opt_allCourier = [];
  const allCourierMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "All Couriers"
  }

  return (
    <div className="app-content content overflow-hidden">
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 nonAttempted shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between">
              <CardTitle className="page-title">Non Attempted Task</CardTitle>              
            </CardHeader>  
              <CardBody className="p-0">                
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Zoing</th>
                      <th className="text-nowrap">0 Day</th>
                      <th className="text-nowrap">1 Day</th>
                      <th className="text-nowrap">2 Day</th>
                      <th className="text-nowrap">3 Day</th>
                      <th className="text-nowrap">4 Day</th>
                      <th className="text-nowrap">5 Day</th>
                      <th className="text-nowrap">6 Day</th>
                      <th className="text-nowrap">7 Day</th>
                      <th className="text-nowrap">8 Day</th>
                      <th className="text-nowrap">9 Day</th>
                      <th className="text-nowrap">10 Day</th>
                      <th className="text-nowrap">10+ Days</th>
                      <th className="text-nowrap">Total</th>
                    </tr>
                   </thead>
                  <tbody>
                    <tr>
                      <td className="text-nowrap">Within City</td>
                      <td>0</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td><Link to="#">1</Link></td>
                    </tr>
                  </tbody>
                </Table>
                <Row className="m-0 table-footer">
                  <Col xs="6">
                    <Pagination>
                        <PaginationItem className="prev">
                          <PaginationLink previous to="#" className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                        </PaginationItem>
                        <PaginationItem className="next ml-3">
                          <PaginationLink next to="#" className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                        </PaginationItem>
                    </Pagination>
                    </Col>
                    <Col xs="6">                
                    <Form>
                      <FormGroup>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select type="select" name="select" id="exampleSelect" className="form-control-sm form-control">
                              <option disabled className="border-bottom">20</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              </select>
                        </FormGroup>
                    </Form>
                  </Col>
               </Row>
              </CardBody>
            </Card>
        </Card>
      </Col>
      <Col sm={12}>
        <Card className="p-0 my-3 pendingSummary shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between">
              <CardTitle className="mb-0 page-title">Pending Summary</CardTitle>              
            </CardHeader>  
              <Form className="cardFilter">
                <Row className="mx-0 justify-content-start justify-content-lg-center">  
                  <Col sm={6} md={3} lg={3} xl={2}>
                      <FormGroup>
                        <div className="position-relative p-0">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select className="form-control-sm form-control">
                            <option value="ALL">All Shipments</option>
                            <option value="1">Pending</option>
                            <option value="2">RTO Pending</option>
                          </select>
                        </div>
                      </FormGroup>
                  </Col>                  
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline>Reset</Button>
                  </Col>
                </Row>
              </Form> 
              <CardBody className="p-0">                
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap w-some">Couriers</th>
                      <th className="text-nowrap">Week 1</th>
                      <th className="text-nowrap">Week 2</th>
                      <th className="text-nowrap">Week 3</th>
                      <th className="text-nowrap">Week 4</th>
                      <th className="text-nowrap">Week 4 +</th>
                      <th className="text-nowrap">Total</th>
                    </tr>
                   </thead>
                  <tbody>
                    <tr>
                      <th>Total</th>
                      <th>697</th>
                      <th>204</th>
                      <th>405</th>
                      <th>112</th>
                      <th>98</th>
                      <th>1516</th>
                    </tr>
                    <tr>
                      <td>XpressBees (Express - 0.5KG)</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td><Link to="#">4</Link></td>
                      <td><Link to="#">4</Link></td>
                    </tr>
                  </tbody>
                </Table>
                <Row className="m-0 table-footer">
                  <Col xs="6">
                    <Pagination>
                        <PaginationItem className="prev">
                          <PaginationLink previous to="#" className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                        </PaginationItem>
                        <PaginationItem className="next ml-3">
                          <PaginationLink next to="#" className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                        </PaginationItem>
                    </Pagination>
                    </Col>
                    <Col xs="6">                
                    <Form>
                      <FormGroup>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select type="select" name="select" id="exampleSelect" className="form-control-sm form-control">
                              <option disabled className="border-bottom">20</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              </select>
                        </FormGroup>
                    </Form>
                  </Col>
               </Row>
              </CardBody>
            </Card>
        </Card>
      </Col>
      <Col sm={12}>
        <Card className="p-0 my-3 pickupPending shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex flex-wrap justify-content-between">
              <CardTitle className="page-title">Pickup Pending</CardTitle>              
            </CardHeader>  
            <Form className="cardFilter">
                <Row className="mx-0 justify-content-start justify-content-lg-center">  
                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={setMerchants}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                        />
                    </FormGroup>
                  </Col>                  
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline>Reset</Button>
                  </Col>
                </Row>
              </Form> 
              <CardBody className="p-0">                
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap w-some">Couriers</th>
                      <th className="text-nowrap">0 Day</th>
                      <th className="text-nowrap">1 Day</th>
                      <th className="text-nowrap">2 Days</th>
                      <th className="text-nowrap">3 Days</th>
                      <th className="text-nowrap">4 Days</th>
                      <th className="text-nowrap">5 Days</th>
                      <th className="text-nowrap">6 Days</th>
                      <th className="text-nowrap">7 Days</th>
                      <th className="text-nowrap">7+ Days</th>
                      <th className="text-nowrap">Total</th>
                    </tr>
                   </thead>
                  <tbody>
                    <tr>
                      <th>Total</th>
                      <th><Link to="#">187</Link></th>
                      <th><Link to="#">17</Link></th>
                      <th><Link to="#">10</Link></th>
                      <th><Link to="#">9</Link></th>
                      <th>0</th>
                      <th><Link to="#">6</Link></th>
                      <th><Link to="#">34</Link></th>
                      <th>0</th>
                      <th>0</th>
                      <th><Link to="#">263</Link></th>
                    </tr>
                    <tr>
                      <td>SS_Delhivery (Surface - 1KG)</td>
                      <td>0</td>
                      <td>0</td>
                      <td><Link to="#">5</Link></td>
                      <td><Link to="#">2</Link></td>
                      <td>0</td>
                      <td>0</td>
                      <td><Link to="#">6</Link></td>
                      <td>0</td>
                      <td>0</td>
                      <td><Link to="#">13</Link></td>
                    </tr>
                  </tbody>
                </Table>
                <Row className="m-0 table-footer">
                  <Col xs="6">
                    <Pagination>
                        <PaginationItem className="prev">
                          <PaginationLink previous to="#" className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                        </PaginationItem>
                        <PaginationItem className="next ml-3">
                          <PaginationLink next to="#" className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                        </PaginationItem>
                    </Pagination>
                    </Col>
                    <Col xs="6">                
                    <Form>
                      <FormGroup>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select type="select" name="select" id="exampleSelect" className="form-control-sm form-control">
                              <option disabled className="border-bottom">20</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              </select>
                        </FormGroup>
                    </Form>
                  </Col>
               </Row>
              </CardBody>
            </Card>
        </Card>
      </Col>
      <Col sm={12}>
        <Card className="p-0 my-3 performanceReport shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between">
              <CardTitle className="page-title">Performance Report</CardTitle>              
            </CardHeader> 
              <Form className="cardFilter">
                <Row className="mx-0 justify-content-start justify-content-lg-center">                 
                  <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                        <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placeholder="Choose Upload Date" format="dd-MM-yyyy" ref={dateRangeRef} change={onDateChange} />
                      </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2}>
                      <FormGroup>
                        <MultiSelect
                            options={opt_merchants}
                            value={merchants}
                            onChange={setMerchants}
                            labelledBy="Select Merchant"
                            overrideStrings={merchantsMultiSelectSettings}
                          />
                      </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                      <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <MultiSelect 
                            options={opt_allCourier}
                            value={allCourier}
                            onChange={(e) => setAllCourier(e)}
                            labelledBy="All Couriers"
                            overrideStrings={allCourierMultiSelectSettings}
                          />
                        </div>
                      </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline>Reset</Button>
                  </Col>
                </Row>
              </Form> 
              <CardBody className="p-0">                
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Month Till Date</th>
                      <th className="text-nowrap">Shipments Booked (Total)</th>
                      <th className="text-nowrap">Delivered</th>
                      <th className="text-nowrap">Pending</th>
                      <th className="text-nowrap">Return to Origin</th>
                    </tr>
                   </thead>
                  <tbody>
                    <tr>
                      <th>Total</th>
                      <th>6502</th>
                      <th>2733</th>
                      <th>952</th>
                      <th>2817</th>
                    </tr>
                    <tr>
                      <td>06-10-2021</td>
                      <td>0</td>
                      <td>
                          <div className="d-flex justify-content-around">
                              <span className="d-flex flex-column w-50">
                                  <span>0</span>
                              </span>
                              <span className="w-50 text-nowrap"><small className="text-success mx-1"><FontAwesomeIcon icon={faArrowUp} /></small>37</span>
                          </div>
                      </td>
                      <td>0</td>
                      <td>
                          <div className="d-flex justify-content-around">
                              <span className="w-50">0</span>
                              <span className="w-50 text-nowrap"><small className="text-danger mx-1"><FontAwesomeIcon icon={faArrowDown} /></small>222</span>
                          </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Row className="m-0 table-footer">
                  <Col xs="6">
                    <Pagination>
                        <PaginationItem className="prev">
                          <PaginationLink previous to="#" className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                        </PaginationItem>
                        <PaginationItem className="next ml-3">
                          <PaginationLink next to="#" className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                        </PaginationItem>
                    </Pagination>
                    </Col>
                    <Col xs="6">                
                    <Form>
                      <FormGroup>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select type="select" name="select" id="exampleSelect" className="form-control-sm form-control">
                              <option disabled className="border-bottom">20</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              </select>
                        </FormGroup>
                    </Form>
                  </Col>
               </Row>
              </CardBody>
            </Card>
        </Card>
      </Col>
      <Col sm={12}>
        <Card className="p-0 my-3 pendingReport shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between">
              <CardTitle className="mb-0 page-title">Pending Report</CardTitle>              
            </CardHeader>  
              <CardBody className="p-0">                
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Zoing</th>
                      <th className="text-nowrap">0 Day</th>
                      <th className="text-nowrap">1 Day</th>
                      <th className="text-nowrap">2 Day</th>
                      <th className="text-nowrap">3 Day</th>
                      <th className="text-nowrap">4 Day</th>
                      <th className="text-nowrap">5 Day</th>
                      <th className="text-nowrap">6 Day</th>
                      <th className="text-nowrap">7 Day</th>
                      <th className="text-nowrap">8 Day</th>
                      <th className="text-nowrap">9 Day</th>
                      <th className="text-nowrap">10 Day</th>
                      <th className="text-nowrap">10+ Days</th>
                      <th className="text-nowrap">Total</th>
                    </tr>
                   </thead>
                  <tbody>
                    <tr>
                      <td>Within City</td>
                      <td>0</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td><Link to="#">1</Link></td>
                    </tr>
                  </tbody>
                </Table>
                <Row className="m-0 table-footer">
                  <Col xs="6">
                    <Pagination>
                        <PaginationItem className="prev">
                          <PaginationLink previous to="#" className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                        </PaginationItem>
                        <PaginationItem className="next ml-3">
                          <PaginationLink next to="#" className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                        </PaginationItem>
                    </Pagination>
                    </Col>
                    <Col xs="6">                
                    <Form>
                      <FormGroup>
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select type="select" name="select" id="exampleSelect" className="form-control-sm form-control">
                              <option disabled className="border-bottom">20</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              </select>
                        </FormGroup>
                    </Form>
                  </Col>
               </Row>
              </CardBody>
            </Card>
        </Card>
      </Col>
      </Row>
       </div>
  );
}

function mapStateToProps (state) {
  return {
    state:state.DataReducer
  }
}
export default connect(mapStateToProps)(ReportShipment);