import React, { useState } from 'react';
import { Row, Col, Label, Form, FormGroup, CardSubtitle, CardTitle, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import axios from 'axios';
import { checkData, checkUndeNullBlank } from '../../../ObjectExist';
import { errorHandling } from '../../../utlis';
import { encryptStorageLogin } from '../../../EncStorage';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { baseString } from '../../../config';
// import { encryptStorageLogin } from '../../../EncStorage';

const Profile = (props) => {
    const [logo, setLogo] = useState('');
    const [removeImg, setRemoveImg] = useState("");
    let history = useHistory();

    const handleFileInput = (e) => {
        setLogo(e.target.files[0])
    }
    const removeConfirm = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1b5a8f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setRemoveImg("d-none");
            }
        })
    }
    const { register: merchant_profile, handleSubmit: merchantDetailsSubmit, formState: { errors }, reset } = useForm();
    const onUpdateProfile = (data) => {
        //   data.logo = logo
        //   data.old_logo = props.user[0].logo
        //   data.merchant_id = props.user[0].id
        let formData = new FormData();
        formData.append("logo", logo !== undefined && logo !== '' ? logo : props.user[0].logo);
        formData.append("old_logo", removeImg !== undefined && removeImg !== "" ? "deleted" : checkUndeNullBlank(props.user[0].logo) ? props.user[0].logo : '');
        formData.append("merchant_id", props.user[0].id);
        props.setLoader(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_GET_MERCHANT}/update/${props.user[0].id}`,
            data: formData,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            props.setLoader(false);
            if (res.data.error === false) {
                reset();
                // encryptStorageLogin.setItem('userenc', JSON.stringify(res.data));
                Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', })
                let data = props.user
                data[0].logo = res.data.data.logo;
                encryptStorageLogin.setItem('userenc', JSON.stringify(data));
                history.push("/settings/1");
            }
        })
            .catch((err) => {
                props.setLoader(false);
                errorHandling(err, "")
            });
    };
    return (
        <Form onSubmit={merchantDetailsSubmit(onUpdateProfile)}>
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">Profile</CardTitle>
            </div>
            <Row className="pt-1">
                <Col sm={6}>
                    <FormGroup>
                        <CardSubtitle tag="h6" className="mb-2">Full Name</CardSubtitle>
                        <Label for="full_name">{props.user[0].name}</Label>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <CardSubtitle tag="h6" className="mb-2">Email</CardSubtitle>
                        <Label for="full_name">{props.user[0].email || 'NAN'}</Label>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <CardSubtitle tag="h6" className="mb-2">Mobile Number</CardSubtitle>
                        <Label for="full_name">{props.user[0].mobile || 'NAN'}</Label>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="logo">Upload logo</Label>
                        <input {...merchant_profile("logos")} type="file" accept=".jpg, .jpeg, .png" onChange={handleFileInput} className="form-control-sm form-control" id="logo" />
                        {checkUndeNullBlank(props.user[0].logo) && <div className={`edit-brand-logo download-img-con mt-2 ${removeImg}`}>
                            {checkData(props.user[0]) && checkUndeNullBlank(props.user[0].logo) ? <div className="edit-brand-logo mt-2"><img src={process.env.REACT_APP_IMAGE_MERCHANT + props.user[0].logo} alt="logo" style={{ width: "120px" }} /></div> : ''}
                            <span className="remove-img" onClick={() => removeConfirm()}><FontAwesomeIcon icon={faTimes} /></span>
                        </div>}
                        {errors.logo && <span className="text-danger d-block mb-2">{errors.logo.message}</span>}
                    </FormGroup>
                </Col>
                <Col sm={12} className="mt-2">
                    <Button className="btn-sm ctm-btn" color="primary">Save changes</Button>
                </Col>
            </Row>

        </Form>
    );
}

export default Profile;