import React, {useEffect, useState} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle,Spinner, FormGroup,Form, CardHeader, Table,Pagination, PaginationItem, PaginationLink,Modal, ModalHeader, ModalBody} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import {ChevronLeft,ChevronRight} from 'react-feather';
import { useForm } from "react-hook-form";
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { changeIntoDate, remExtraSpace, errorHandling } from '../../utlis';
import EditCity from './EditCity';
import AddCity from './AddCity';
import {AllCity} from '../../actions';
import { connect, useDispatch} from "react-redux";
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { baseString } from '../../config';


const City = (props) => {
  const [city, setCity] = useState({})
  const [page, setPage] = useState(1)
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [addmodal, setAddModal] = useState(false);
  const [editmodal, seteditModal] = useState(false);
  const [editCity, setEditCity] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const [filterInput, setFilterInput] = useState(false);
  const dropFilterCount = 20
  // const cityRedux = useSelector(state => state);
  const dispatch = useDispatch();
  const toggleAdd = (e) => setAddModal(!addmodal);
    function handleSelectChange(event) {
        setSelectperpage(event.target.value);
        setLoader(true)
        setPage(1)
        setCurrentCount(1)
        let pagePerpage = {"page":1, "per_page":event.target.value};
        onpaginationFilter(filterInput,pagePerpage)
    }
    function nextPageData() {
      if(checkData(city.data)&&checkUndeNullBlank(city.totalPage)){
        // &&checkUndeNullBlank(city.totalPage).length>page.length
          if(city.totalPage > page){
            setCurrentCount(currentCount+Object.entries(city.data).length)
        setPage(page+1)
        setLoader(true)
          let pagePerpage = {"page":page+1, "per_page":selectperpage};
          onpaginationFilter(filterInput,pagePerpage);
        }
      }
    }
    function previousPageData() {
      if(page >1){
      setPage(page-1)
      checkData(city.data)
      if(currentCount>Object.entries(city.data).length){
        setCurrentCount(currentCount-selectperpage)
      }else{
        setCurrentCount(1)
      }
      let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
        onpaginationFilter(filterInput,pagePerpage);
    }
    }
    const toggleEditclose = () => seteditModal(!editmodal);
  const toggleEdit = (i) => {
    fetch(`${process.env.REACT_APP_GET_CITY}/view/${i}`,{
      method:'POST',
      headers:{
        "Content-Type":'application/json',
        "Accept":'application/json',
        "token": window.btoa(baseString)
      },
    }).then((resp)=>{
      resp.json().then((result)=>{
        setEditCity(result)
        seteditModal(!editmodal)
      });
    }).catch(err => {
      errorHandling(err,"");
    });
  };
  const cityListing = async () =>{
    setLoadertable(true);
    setFilterInput('')
    setCurrentCount(1)
    setPage(1)
    let mypost = {}
      let myget = {"page":page, "per_page":selectperpage};
      dispatch(AllCity(mypost,myget)).then((response) => {
        setCity(response)
        setLoadertable(false);
      }).catch((err) =>{
        setLoadertable(false);
        errorHandling(err,"reload");
      });
  }
  useEffect(() => {
    if(checkData(props.state) && checkData(props.state.viewAllCity)){
      setCity(props.state.viewAllCity);
    }else{
    cityListing();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectperpage, page])
  const { register, handleSubmit } = useForm();
  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
    
  };
  const onSubmitFilter = (data,pagePerpage) => {
    pagePerpage.page=1
    if(checkUndeNullBlank(data.state) || checkUndeNullBlank(data.city)){
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data,pagePerpage)
    }else{
      Swal.fire({title: 'info',text: 'Please Choose Filter',icon: 'info',})
    }
  };
  const onCommonFilter = (data,pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
      fetch(`${process.env.REACT_APP_GET_CITY}?state=${checkUndeNullBlank(data.state)?remExtraSpace(data.state):''}&city=${checkUndeNullBlank(data.city)?remExtraSpace(data.city):''}&page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,{
        method:'POST',
        headers:{
          "Content-Type":'application/json',
          "Accept":'application/json',
          "token": window.btoa(baseString)
        },
      }).then((resp)=>{
        resp.json().then((result)=>{
          if(result.fatal !== true){
            setLoader(false)
            setCity(result)
          }
        });
      }).catch((err) => {
        setLoader(false);
        errorHandling(err,"");
      });
  };
  const RemoveCity = (id) => {
    Swal.fire({
      icon: 'warning',
      title: '<p class="confirm-msg">Do you want to delete this record?</p>',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
    setLoader(true)
    fetch(`${process.env.REACT_APP_REMOVE_CITY}/${id}`,{
      method:'POST',
      headers:{
        "Content-Type":'application/json',
        "Accept":'application/json',
        "token": window.btoa(baseString)
      },
    }).then((resp)=>{
    setLoader(false);
    resp.json().then((result)=>{
        cityListing();
      Swal.fire({title: 'Success', text: result.msg, icon: 'success'})
      });
    }).catch((err) =>{
    setLoader(false);
    errorHandling(err,"");
    });
  } 
})
  }
  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 manageCity shadow content-wrapper border-0">
          <Card className="border-0">
            <CardHeader className="page-header d-flex justify-content-between flex-wrap p-4">
              <CardTitle className="mb-0 page-title pb-2 font-weight-bold border-short">Manage City</CardTitle>
              <div className="btn-text btn-light" onClick={toggleAdd}><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add City</span>
              </div>
              <Modal isOpen={addmodal} toggle={addmodal} scrollable={true}>
                <ModalHeader toggle={toggleAdd}>Add City</ModalHeader>
                <ModalBody>
                  <AddCity cityListing={cityListing} setAddModal={setAddModal} setLoader={setLoader} />
                 </ModalBody>
              </Modal>
                {checkData(editCity) &&
              <Modal isOpen={editmodal} toggle={editmodal} scrollable={true}>
                <ModalHeader toggle={toggleEditclose}>Edit City</ModalHeader>
                <ModalBody>
                  <EditCity cityListing={cityListing} cityDate={editCity} seteditModal={seteditModal} setLoader={setLoader} />
                </ModalBody>
              </Modal>
              }
            </CardHeader>
                <Form onSubmit={handleSubmit(onSubmitFilter)} className="cardFilter">
                  <Row className="mx-0 pt-1 justify-content-start justify-content-md-center">
                    <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                          <input {...register("state")}  className="form-control-sm form-control" placeholder="Enter State" />
                        </FormGroup>
                      
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={3}>
                        <FormGroup>
                          <input {...register("city")}  className="form-control-sm form-control" placeholder="Enter City" />
                        </FormGroup>
                    </Col>
                    <Col sm={3} className="btn-container">
                      <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={cityListing}>Reset</Button>
                    </Col>
                  </Row>
                </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(city) && checkData(city.data) && checkUndeNullBlank(city.TotalRows) ?
                  <span class="small">Showing {currentCount} to {(currentCount-1)+(Object.entries(city.data).length)} of {city.TotalRows}</span>: ''}   
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th>State</th>
                      <th>City</th>
                      <th>Created</th>
                      <th>Actions</th>
                    </tr>
                   </thead>
                  <tbody>
                  {checkData(city.data) ? city.data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.state}</td>
                      <td>{item.name}</td>
                      <td className="text-nowrap">{(item.created_at !== undefined && item.created_at !== null?changeIntoDate(item.created_at):'NA')}</td>
                      <td className="text-nowrap">
                        <span><Button className="ctm-btn btn-sm ml-2" color="info" outline  onClick={() => toggleEdit(item.id)}>Edit</Button></span>
                        <span><Button outline className="ctm-btn btn-sm light ml-2" color="secondary" onClick={() => RemoveCity(item.id)}>Delete </Button></span>
                      </td>
                    </tr>
                  )) : loadertable ? <tr><td colSpan="4"><LoaderDefault /></td></tr> :
                    <tr><td colSpan="4"><NodataFound /></td></tr> }
                  </tbody>
                </Table>
                {checkData(city) && checkData(city.data) && checkUndeNullBlank(city.TotalRows) ?
                <>
                {city.TotalRows > dropFilterCount ?
               <Row className="m-0 table-footer">
               <Col xs="6">               
                <Pagination aria-label="Page navigation example align-items-center">
                    <PaginationItem className="prev">
                      <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                    </PaginationItem>
                    <PaginationItem className="next ml-3">
                      <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                    </PaginationItem>
                </Pagination>
                </Col>
                <Col xs="6">
                <Form>
                  <FormGroup>
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                          <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          </select>
                  </FormGroup>
                </Form>
                </Col>
               </Row>:''}
               </>
               :''}
              </CardBody>
            </Card>
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}
function mapDispatchToProps (state) {
  return{
    state:state.DataReducer
  }
}
export default connect(mapDispatchToProps)(City);