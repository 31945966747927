import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardHeader, CardImg, UncontrolledCollapse, Input, Modal, ModalHeader, ModalBody, FormGroup, Label, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowLeft } from 'react-feather';
import axios from 'axios';
import { useParams } from 'react-router-dom'
import { faArrowsAlt, faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import NodataFound from '../NodataFound';
import PricingTable from '../PricingTable';
import Swal from 'sweetalert2'
// import LoaderDefault from '../LoaderDefault';
import CourierPriority from './CourierPriority';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { errorHandling, changeIntoDateTime } from '../../utlis';
import { baseString } from '../../config';

const configData = require("../../config");

const MerchantPricing = (props) => {
  let merchantName = (new URLSearchParams(window.location.search)).get("merchantName")
  const [serviceType, setServiceType] = useState(1);
  const [movementId, setMovementId] = useState('');
  const [addmodal, setAddmodal] = useState(false);
  const [courierAdd, setCourierAdd] = useState(false);
  const [courierId, setCourierId] = useState(false);
  const [freeCourier, setFreeCourier] = useState();
  const [courierList, setCourierList] = useState({});
  const [courierPricing, setCourierPricing] = useState({});
  const { merchantId } = useParams();
  const [loader, setLoader] = useState(false)
  const getCourierStatus = (courier_id, e) => {
    setLoader(true)
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_COURIER_STATUS,
      data: { "merchant_id": merchantId, "status_id": e.target.checked ? e.target.value : '0', "courier_id": courier_id },
      headers: { "token": window.btoa(baseString) }
    }).then(function (response) {
      if (response.data.error === false) {
        setLoader(false)
        Swal.fire({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
        })
      }
      couurierListing()
    }).catch(function (err) {
      setLoader(false);
      errorHandling(err, "")
    })

  };
  const getDisplayStatus = (courier_id, e) => {
    setLoader(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_COURIER_DISPLAY_STATUS,
      data: { "merchant_id": merchantId, "merchant_display_status": e.target.checked ? e.target.value : '0', "courier_id": courier_id },
      headers: { "token": window.btoa(baseString) }
    }).then(function (response) {
      if (response.data.error === false) {
        setLoader(false)
        Swal.fire({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
        })
      }
      couurierListing()
    }).catch(function (err) {
      setLoader(false);
      errorHandling(err, "")
    })

  };
  const couurierListing = () => {
    setLoader(true)
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_COURIER_LIST,
      data: { "merchant_id": merchantId, "admin_id":props.user[0].id },
      headers: { "token": window.btoa(baseString) }
    }).then(function (response) {
      setLoader(false)
      if (response.data.error === false) {
        setCourierList(response.data.data)
        courierPriceing();
      } else {
        Swal.fire({ title: 'Error', text: response.data.msg ? response.data.msg : 'Something went wrong', icon: 'error' });
      }

    }).catch(function (err) {
      setLoader(false);
      errorHandling(err, "reload");
    })
  }
  const courierPriceing = () => {
    setLoader(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_MERCHANT_COURIER_PRICING,
      data: { "merchant_id": merchantId, "admin_id":props.user[0].id },
      headers: { "token": window.btoa(baseString) }
    }).then(function (response) {
      setLoader(false);
      if (response.data.fatal !== true) {
        setCourierPricing(response.data)
      }
    }).catch(function (err) {
      setLoader(false);
      errorHandling(err, "")
    })
  }

  useEffect(() => {
    couurierListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantId])

  const toggleAdd = () => {
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_FREE_COURIER}`,
      data: { "merchant_id": merchantId },
      headers: { "token": window.btoa(baseString) }
    })
      .then(function (response) {
        setLoader(false);
        setFreeCourier(response.data.courier)
      })
      .catch(function (err) {
        setLoader(false);
        errorHandling(err, "")
      });
    setAddmodal(!addmodal)
  };
  const courierShort = () => setCourierAdd(!courierAdd);
  const handleCourierChoose = (e) => {
    let index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index];
    var my_movement_type_id = optionElement.getAttribute('data-id');
    setMovementId(my_movement_type_id)

    setCourierId(e.target.value)
  }
  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 merchantPricing shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="page-title">Manage {merchantName} Pricing</CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  <Link to='/merchants' className="btn-text btn-light mx-1"><span className="icon"><ArrowLeft /></span><span>Back to Merchant List</span></Link>
                  <div className="btn-text btn-light mx-2" onClick={courierShort}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowsAlt} /></span><span>Priority</span></div>
                  <div className="btn-text btn-light mx-2" onClick={toggleAdd}><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add New Courier</span></div>
                </div>
                <Modal isOpen={addmodal} toggle={addmodal} className="modal-xl" scrollable={true}>
                  <ModalHeader toggle={toggleAdd}>Add Courier Pricing</ModalHeader>
                  <ModalBody>
                    {checkData(freeCourier) ?
                      <Row className="mx-0 justify-content-center addModal">
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="selectCourier">Select Courier</Label>
                            <div className="position-relative">
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select className="form-control-sm form-control" onChange={handleCourierChoose}>
                                <option>Select Courier</option>
                                {freeCourier !== undefined && Object.entries(freeCourier).length > 0 ? freeCourier.map((item, index) => (
                                  <option data-id={item.movement_type_id} value={item.id}>{item.full_name}</option>
                                ))
                                  : ''}
                              </select>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          {movementId !== '' &&
                            <PricingTable setAddmodal={setAddmodal} movement_type_id={movementId} courierId={courierId} merchantId={merchantId} priceApiUrl={process.env.REACT_APP_MERCHANT_PRICING_ADD} couurierListing={couurierListing} courierPriceing={courierPriceing} user={props.user} fType={checkData(courierList.merchantFreightType) && checkUndeNullBlank(courierList.merchantFreightType[0].freight_type) ? courierList.merchantFreightType[0].freight_type : ''} />
                          }
                        </Col>
                      </Row>
                      : <Col sm={12} className="d-flex justify-content-center"><Spinner /></Col>
                    }
                  </ModalBody>
                </Modal>
                <Modal isOpen={courierAdd} toggle={courierAdd} className="modal-lg" scrollable>
                  <ModalHeader toggle={courierShort}>Courier Priority</ModalHeader>
                  <ModalBody>
                    <div className="d-flex justify-content-center mt-3 py-2">
                      <FormGroup check className="mr-4">
                        <Label check>
                          <Input type="radio" name="courier_type" onClick={() => setServiceType(1)} defaultChecked />
                          Express
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mr-4">
                        <Label check>
                          <Input type="radio" name="courier_type" onClick={() => setServiceType(7)} />
                          Surface
                        </Label>
                      </FormGroup>
                    </div>
                    <CourierPriority merchantId={merchantId} serviceType={serviceType} shipMethod={1} setCourierAdd={setCourierAdd} />
                  </ModalBody>
                </Modal>
              </CardHeader>
              {checkData(courierList.courierList) ? courierList.courierList.map((item, index) => (
                <>
                  <Card className="m-1 px-0">
                    <CardBody>
                      <div className="d-flex flex-wrap flex-sm-nowrap justify-content-sm-between align-items-center mycourierlist">
                        <div id={`couriertoggler${item.courier_id}`} className="courierToggler mr-2" style={{ cursor: 'pointer' }}><FontAwesomeIcon icon={faChevronDown} /></div>
                        <div id={`couriertoggler${item.courier_id}`} className="courierId text-nowrap mx-2">Courier ID - <span className="font-weight-500">{item.courier_id}</span></div>
                        <div className="courierLogo mx-2" id={`couriertoggler${item.courier_id}`} style={{ cursor: 'pointer' }}> <CardImg top src={`${process.env.REACT_APP_BASE_URL + '/' + courierList.path + '/' + item.logo}`} alt="logo" style={{ maxWidth: '60px' }} /></div>
                        <div className="courierName mx-2" id={`couriertoggler${item.courier_id}`} style={{ cursor: 'pointer' }}>{item.courierName}
                          <br /><span>Added On: </span><span>{changeIntoDateTime(item.created_at)}</span>
                        </div>
                        <div className="form-check form-switch courierSwitch">
                          <input className="form-check-input" onClick={(e) => getCourierStatus(item.courier_id, e)} value="1" type="checkbox" style={{ cursor: 'pointer' }} defaultChecked={item.status === 1} />
                          <label className="form-check-label" for="flexSwitchCheckDefault" style={{ cursor: 'pointer' }}>{configData.status[item.status]}</label>
                        </div>
                        <div className="form-check form-switch courierSwitch">
                          <input className="form-check-input" onClick={(e) => getDisplayStatus(item.courier_id, e)} value="1" type="checkbox" style={{ cursor: 'pointer' }} defaultChecked={item.merchant_display_status === 1} />
                          <label className="form-check-label" for="flexSwitchCheckDisplay" style={{ cursor: 'pointer' }}>{configData.display[item.merchant_display_status]}</label>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <UncontrolledCollapse toggler={`couriertoggler${item.courier_id}`}>
                    <CardBody className={`px-0 ${item.courier_id}`}>
                      {/* {checkData(courierPricing) ? */}
                      <PricingTable movement_type_id={item.movement_type_id} pricingList={courierPricing[item.courier_id !== undefined && item.courier_id]} courierId={item.courier_id} merchantId={merchantId} priceApiUrl={process.env.REACT_APP_MERCHANT_PRICING_ADD} user={props.user} fType={checkData(courierList.merchantFreightType) && checkUndeNullBlank(courierList.merchantFreightType[0].freight_type) ? courierList.merchantFreightType[0].freight_type : ''} />
                      {/* :<LoaderDefault />
              } */}
                    </CardBody>
                  </UncontrolledCollapse>
                </>
              )) : <Col sm={12} className="text-center"><NodataFound /></Col>}
            </Card>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default MerchantPricing;